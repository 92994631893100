import DateDisplay from "../../../components/DateDisplay";
import { Button, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectSearchResults, startRequoteAsync } from "./../wipListSlice";
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import CodeValue from "./../../../components/CodeValue";
import CONSTANTS from "./../../../constants";

const SearchResults = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const searchResults = useSelector(selectSearchResults);

  const handleStartRequote = (quoteRefId: string) => {
    dispatch(
      startRequoteAsync(quoteRefId, (wip) => {
        history.push(`/quote-request/${wip.quoteRequestWipId}/quote-details`);
      })
    );
  };

  if (!searchResults) {
    return <></>;
  }

  if (searchResults.length == 0) {
    return <p className="lead">No match found</p>;
  }

  return (
    <div className="mb-3">
      <h3>Search Results</h3>
      <Table striped hover className="mb-0 border shadow">
        <thead>
          <tr>
            <th>Quote Reference ID</th>
            <th>Effective Date</th>
            <th>Vendor</th>
            <th>Quote Type</th>
            <th>Broker Name</th>
            <th>Broker Number</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {searchResults.map((item) => (
            <tr key={item.quoteRefId}>
              <td>{item.quoteRefId}</td>
              <td>
                <DateDisplay value={item.termEffectiveDate} />
              </td>
              <td>{item.vendorName}</td>
              <td>
                <CodeValue
                  categoryName={CONSTANTS.REFDATA.TRANSACTION_TYPE}
                  value={item?.transactionTypeCode}
                />
              </td>
              <td>{item.brokerName}</td>
              <td>{item.brokerNumber}</td>
              <td>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="StartRequote">Start Requote</Tooltip>}>
                  <Button
                    variant="primary"
                    data-testid="StartRequote"
                    title="Start Requote"
                    onClick={() => handleStartRequote(item.quoteRequestId)}>
                    Start Requote
                    <ArrowRightCircleFill className="ml-2"></ArrowRightCircleFill>{" "}
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default SearchResults;
