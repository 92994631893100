import { useDispatch, useSelector } from "react-redux";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import QuoteInquiryLinkComponent from "../../components/QuoteInquiryLinkComponent";
import "./QuoteDetails.scss";
import QuoteDetailsEditor from "./QuoteDetailsEditor";
import { selectTermEffectiveDateEnabled } from "../../../../appRootSlice";
import { setQuoteCompare } from "../../../wip-list/wipListSlice";
const QuoteDetails = () => {
  const dispatch = useDispatch();
  const quoteRequest = useSelector(selectQuoteRequest);
  const requestHeader = quoteRequest?.requestHeader;
  const sourceQuoteRequest = quoteRequest?.sourceQuoteRequest;
  const originalQuoteRequest = quoteRequest?.originalQuoteRequest;
  const isTermEffectiveDateEnabled = useSelector(selectTermEffectiveDateEnabled);
  if (sourceQuoteRequest?.quoteRefId && originalQuoteRequest?.quoteRefId) {
      dispatch(setQuoteCompare(
        {
          displayCompareLink: true, 
          reQuoteId: sourceQuoteRequest?.quoteRefId, 
          orgQuoteId: originalQuoteRequest?.quoteRefId
        }));
    }
  return (
    <div className="quote-request-details">
      <div className="row mb-4 zIndex-2">
        <div className="col-6 d-flex flex-column align-self-start">
          <div className="col-6 font-weight-bold">Prior Quote</div>
          <div className="col-6">
            <QuoteInquiryLinkComponent
              quoteRefId={sourceQuoteRequest?.quoteRefId ?? ""}
              requestId={sourceQuoteRequest?.requestId ?? ""} />
          </div>
        </div>

        <div className="col-6 d-flex flex-column align-self-start">
          <div className="col-6 font-weight-bold">Original Quote</div>
          <div className="col-6">
            <QuoteInquiryLinkComponent
              quoteRefId={originalQuoteRequest?.quoteRefId ?? ""}
              requestId={originalQuoteRequest?.requestId ?? ""} />
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-6 d-flex flex-column align-self-start">
          <div className="col-6 font-weight-bold">Broker Name</div>
          <div className="col-6">{requestHeader?.brokerName ?? "-"}</div>
        </div>

        <div className="col-6 d-flex flex-column align-self-start">
          <div className="col-6 font-weight-bold">Broker Number</div>
          <div className="col-6">{requestHeader?.brokerNumber ?? "-"}</div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-6 d-flex flex-column align-self-start">
          <div className="col-6 font-weight-bold">Vendor</div>
          <div className="col-6">{requestHeader?.vendor}</div>
        </div>

        {isTermEffectiveDateEnabled && (
          <div className="col-6 d-flex flex-column align-self-start">
            <div className="col-6 font-weight-bold">Term Effective Date</div>
            <div className="col-6">
              <QuoteDetailsEditor requestHeaderModel={requestHeader ?? {}} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuoteDetails;
