import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { LicenseInfoModel, LicenseClassMetadata } from "../../../../service/RequoteApiTypes";
import { ReactDatePicker } from "../../../../components/ReactDatePicker";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import {
  selectSelectedLicense,
  selectSelectedProvince,
  cancelEditing,
  saveLicense,
  selectLicenseProvinces,
  selectLicenseClasses,
  setSelectedProvince,
} from "./licenseSlice";
import "./Editor.css";
import {
  convertUtcDateToDisplayString,
  convertDateToString,
} from "../../../../utils/helpers/dateUtils";
import { RootState } from "../../../../app/store";
import { selectCountryTypeCodes, selectQuoteRenewalEnabled } from "../../../../appRootSlice";
import { updateValidation } from "../../../../rootValidationSlice";
import { PolicyHistoryModel } from "../../../../service/RequoteApiTypes";
import {
  selectPolicyHistory,
  selectPolicyHistoryWithRenewal,
} from "../policyHistory/policyHistorySlice";

const Editor: React.FC = () => {
  const dispatch = useDispatch();
  const selectedQuoteRequest = useSelector(selectQuoteRequest);
  const selectedProvince = useSelector(selectSelectedProvince);
  const countryTypeCodes = useSelector(selectCountryTypeCodes).filter(
    (f) => f.codeValue === "ca" || f.codeValue === "us"
  );
  const selectedLicense = useSelector(selectSelectedLicense);

  const provinces = useSelector(selectLicenseProvinces);
  const classes = useSelector((state: RootState) =>
    selectLicenseClasses(state).filter(
      (c: LicenseClassMetadata) => c.provinceCode == selectedProvince?.provinceCode
    )
  );

  const data: LicenseInfoModel = {
    entityId: "",
    licenseId: "",
    ...selectedLicense,
    dateLicensed: convertUtcDateToDisplayString(selectedLicense?.dateLicensed),
    country: selectedLicense?.country ?? "ca",
  };

  const isQuoteRenewalFeatureFlagEnabled = useSelector(selectQuoteRenewalEnabled);
  const policyHistories: PolicyHistoryModel[] = useSelector(selectPolicyHistory).filter(
    (e) => e.entityId === data.entityId
  );
  const policyHistoriesWithRenewals: PolicyHistoryModel[] = useSelector(
    selectPolicyHistoryWithRenewal
  ).filter((e) => e.entityId === data.entityId);
  const activePolicyHistories = isQuoteRenewalFeatureFlagEnabled
    ? policyHistories
    : policyHistoriesWithRenewals;
  let firstInsurance = activePolicyHistories.find((f) => f.lineOfBusiness === "auto");
  const validateDate = (d: any): boolean => {
    if (firstInsurance?.inceptionDate && d) {
      let d1 = new Date(firstInsurance?.inceptionDate.replace(/-/g, "/"));
      let d2 = new Date(d);
      if (d2.getTime() > d1.getTime()) return false;
    }
    return true;
  };

  const validationSchema = Yup.object().shape({
    dateLicensed: Yup.date()
      .nullable()
      .required("*Date licensed is required")
      .test("dateLicensed", "License date cannot be greater than 1st insurance date", (value) =>
        validateDate(value)
      ),
    province: Yup.string().when("country", {
      is: (val: string) => val === "ca",
      then: Yup.string().nullable().required("*Licence province is required"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    licenseClassTypeCode: Yup.string().when("country", {
      is: (val: string) => val === "ca",
      then: Yup.string().nullable().required("*Licence class is required"),
      otherwise: Yup.string().nullable().notRequired(),
    }),
  });

  const rootValidRef = useRef(null) as any;
  const validate = () => {
    dispatch(
      updateValidation({
        formName: "LicenseEditor",
        validationFail: Object.keys(rootValidRef.current.errors).length > 0 ? true : false,
      })
    );
  };
  const onCancel = () => {
    dispatch(updateValidation({ formName: "LicenseEditor", validationFail: false }));
    dispatch(cancelEditing());
  };

  const onSubmit = (values: LicenseInfoModel, helpers: FormikHelpers<LicenseInfoModel>) => {
    const postData = {
      ...data,
      dateLicensed: convertDateToString(values.dateLicensed),
      province: values.province,
      licenseClassTypeCode: values.licenseClassTypeCode,
      number: values.number,
      country: values.country,
    };
    dispatch(saveLicense(selectedQuoteRequest?.quoteRequestWipId as string, postData));
  };
  return (
    <Modal
      show={selectedLicense !== undefined}
      onHide={onCancel}
      className="license-editor"
      keyboard={false}
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {data.licenseId ? "Edit Licence Information" : "Add Licence Information"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          innerRef={rootValidRef}
          validate={validate}
          onSubmit={onSubmit}>
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Form.Group>
                <Form.Label htmlFor="country">Licence Country</Form.Label>
                <Form.Control
                  className="custom-select"
                  as="select"
                  id="country"
                  onChange={(e) => {
                    if (e.target.value !== "ca") {
                      setFieldValue("province", null);
                      setFieldValue("licenseClassTypeCode", null);
                    }

                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.country ?? ""}
                  isInvalid={errors.country ? true : false}>
                  <option key="other" value="other">
                    Other
                  </option>
                  {countryTypeCodes &&
                    countryTypeCodes.map((p) => (
                      <option key={p.codeValue} value={p.codeValue ?? ""}>
                        {p.description}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.province}</Form.Control.Feedback>
              </Form.Group>

              {values.country === "ca" && (
                <>
                  <Form.Group>
                    <Form.Label htmlFor="province">Licence Province</Form.Label>
                    <Form.Control
                      className="custom-select"
                      as="select"
                      id="province"
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(setSelectedProvince(e.target.value));
                      }}
                      onBlur={handleBlur}
                      value={values.province ?? ""}
                      isInvalid={errors.province ? true : false}>
                      <option key="" value="" />
                      {provinces &&
                        provinces.map((p) => (
                          <option key={p.provinceCode} value={p.provinceCode ?? ""}>
                            {p.provinceName}
                          </option>
                        ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">{errors.province}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label htmlFor="licenseClassTypeCode">Licence Class</Form.Label>
                    <Form.Control
                      className="custom-select"
                      as="select"
                      id="licenseClassTypeCode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.licenseClassTypeCode ?? ""}
                      isInvalid={errors.licenseClassTypeCode ? true : false}>
                      <option key="" value="" />
                      {classes &&
                        classes.map((c) => (
                          <option key={c.licenseClassTypeCode} value={c.licenseClassTypeCode ?? ""}>
                            {c.licenseClassTypeName}
                          </option>
                        ))}
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.licenseClassTypeCode}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
              <Form.Group>
                <Form.Label htmlFor="number">
                  Licence Number -{" "}
                  <small>
                    <i>optional</i>
                  </small>
                </Form.Label>
                <Form.Control
                  as="input"
                  name="number"
                  id="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.number ?? ""}
                  isInvalid={errors.number ? true : false}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="dateLicensed">Date Licensed</Form.Label>
                <br />
                <div className={touched.dateLicensed && errors.dateLicensed ? "is-invalid" : ""}>
                  <ReactDatePicker
                    name="dateLicensed"
                    className={
                      touched.dateLicensed && errors.dateLicensed
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={handleChange}
                  />
                </div>
                <Form.Control.Feedback type="invalid">{errors.dateLicensed}</Form.Control.Feedback>
              </Form.Group>

              <Form.Row className="modal-footer">
                <Button variant="secondary" type="button" onClick={onCancel}>
                  Cancel
                </Button>

                <Button variant="primary" type="submit" className="ml-2">
                  Submit
                </Button>
              </Form.Row>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default Editor;
