import { SuspensionInfoModel } from "./RequoteApiTypes";
import axios, { AxiosResponse } from "axios";
import CONSTANTS from "../constants";

export async function saveSuspension(
  quoteRequestWipId: string,
  request: SuspensionInfoModel
): Promise<SuspensionInfoModel> {
  const url =
    window.REACT_APP_API_BASEURL +
    CONSTANTS.API.SUSPENSION.SAVE(quoteRequestWipId, request.entityId);

  const response = await axios.post<SuspensionInfoModel, AxiosResponse<SuspensionInfoModel>>(
    url,
    request
  );

  return response.data;
}

export async function deleteSuspension(
  quoteRequestWipId: string,
  entityId: string,
  id: string
): Promise<void> {
  const url =
    window.REACT_APP_API_BASEURL + CONSTANTS.API.SUSPENSION.DELETE(quoteRequestWipId, entityId, id);

  const response = await axios.delete(url);

  return response.data;
}
