import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HTTP, HttpAction } from "./../../../../app/middlewares/http/types";
import { AppThunk, RootState } from "./../../../../app/store";
import { resumeWorkInProgress } from "../../../wip-list/wipListSlice";
import * as vehicleService from "./../../../../service/vehicleService";
import { AssetCovergeModel, AssetCoverage, VehicleModel, CoverageListType } from "../../../../service/RequoteApiTypes";
import CONSTANTS from "./../../../../constants";

interface VehicleState {
  vehicles: VehicleModel[];
  isLoading: boolean;
  selectedVehicleCoverage?: AssetCovergeModel;
  selectedCoverageListType?: CoverageListType;
}

const initialState: VehicleState = {
  vehicles: [],
  isLoading: false,
  selectedVehicleCoverage: undefined,
  selectedCoverageListType: undefined,
};

export const vehicleSlice = createSlice({
  name: "vehicleSlice",
  reducers: {
    addAction: (state, action: PayloadAction<AssetCovergeModel>) => {
      state.selectedVehicleCoverage = action.payload;
    },
    editAction: (state, action: PayloadAction<AssetCovergeModel>) => {      
      state.selectedVehicleCoverage = action.payload;
    },
    cancelEditAction: (state) => {
      state.selectedVehicleCoverage = undefined;
    },
    saveAction: (state, action: PayloadAction<AssetCoverage>) => {
      const payload = action.payload;
      state.vehicles.forEach((v, i) => {
        const coverageIndex = v.coverage?.findIndex((c) => c.coverageId == payload.coverageId);
        if(coverageIndex && coverageIndex > -1) {
          if(v.coverage && v.coverage[coverageIndex]) {
            v.coverage[coverageIndex].deductible = payload.deductible;
          }
        }
      });
      state.selectedVehicleCoverage = undefined;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resumeWorkInProgress, (state, action) => {
      state.vehicles = action.payload?.vehicles ?? [];
      state.selectedVehicleCoverage = undefined;
    });
  },
});

export const selectVehicles = (state: RootState): VehicleModel[] => state.vehicle.vehicles ?? [];

export const addCoverage = (model: AssetCovergeModel): AppThunk<void> => async (dispatch) => { dispatch(addAction(model)); };

export const editCoverage = (model: AssetCovergeModel): AppThunk<void> => async (dispatch) => {
  dispatch(editAction(model));
};

export const cancelEditing = (): AppThunk<void> => async (dispatch) => { dispatch(cancelEditAction()); };

export const selectSelectedCoverage = (rootState: RootState) => rootState.vehicle.selectedVehicleCoverage;

export const saveCoverage = (
  quoteRequestWipId: string,
  model: AssetCovergeModel
): AppThunk<void> => async (dispatch) => {
  const action: HttpAction<AssetCovergeModel> = {
    type: HTTP,
    meta: {
      promise: () => vehicleService.saveCoverage(quoteRequestWipId, model),
      resolve: (response: AssetCoverage) => {
        dispatch(saveAction(response));
      }
    },
  };

  return dispatch(action);
};

export const getCoverageLists = (rooState: RootState, coverageType?: string): CoverageListType => {
  let _coverageTypelists = CONSTANTS.COVERAGE_LIST.filter(p => p.COVERAGE == coverageType)[0];
  let _deductible = [] as string[];
  let _limit = [] as string[];
  let _month = [] as string[];
  if(_coverageTypelists && _coverageTypelists.DEDUCTIBLE) {
    switch(_coverageTypelists.DEDUCTIBLE)
    {
      case "DEDUCTIBLE_D1":
        _deductible = CONSTANTS.COVERAGE_LIST_TYPE.DEDUCTIBLE_D1.map(String);
        break;
      case "DEDUCTIBLE_D2":
        _deductible = CONSTANTS.COVERAGE_LIST_TYPE.DEDUCTIBLE_D2.map(String);
        break;
    }
  }

  if(_coverageTypelists && _coverageTypelists.LIMIT) {
    switch(_coverageTypelists.LIMIT)
    {
      case "LIMIT_L1":
        _limit = CONSTANTS.COVERAGE_LIST_TYPE.LIMIT_L1.map(String);
        break;
      case "LIMIT_L2":
        _limit = CONSTANTS.COVERAGE_LIST_TYPE.LIMIT_L2.map(String);
        break;
      case "LIMIT_L3":
        _limit = CONSTANTS.COVERAGE_LIST_TYPE.LIMIT_L3.map(String);
        break;
      case "LIMIT_L4":
        _limit = CONSTANTS.COVERAGE_LIST_TYPE.LIMIT_L4.map(String);
        break;
    }
  }

  if(_coverageTypelists && _coverageTypelists.MONTH) {
    switch(_coverageTypelists.MONTH) {
      case "MONTH_M1":
        _month = CONSTANTS.COVERAGE_LIST_TYPE.MONTH_M1.map(String);
        break;
    }
  }

  let _coverageListType  = {
    coverageTypeCode: coverageType, 
    limit: _limit,
    deductible: _deductible,
    month: _month
  } as CoverageListType;
  
  return _coverageListType;
}

export const {
  addAction,
  editAction,
  cancelEditAction,
  saveAction,
} = vehicleSlice.actions;

export default vehicleSlice.reducer;
