import {useRef} from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import { InsuranceEventModel } from "../../../../service/RequoteApiTypes";
import { ReactDatePicker } from "../../../../components/ReactDatePicker";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import { updateValidation } from "../../../../rootValidationSlice";
import {
  selectSelectedInsuranceEvent,
  saveInsuranceEvent,
  cancelEditing,
} from "./insuranceEventSlice";
import { firstLicense } from "../license/licenseSlice";
import { useDispatch, useSelector } from "react-redux";
import "./Editor.css";
import * as Yup from "yup";
import { convertUtcDateToDisplayString } from "./../../../../utils/helpers/dateUtils";
import { selectInsuranceEventTypeCodes } from "./../../../../appRootSlice";
import "react-bootstrap-typeahead/css/Typeahead.css";

const Editor: React.FC = () => {
  const dispatch = useDispatch();
  const insuranceEventTypeCodes = useSelector(selectInsuranceEventTypeCodes);
  const selectedQuoteRequest = useSelector(selectQuoteRequest);
  const selectedEvent = useSelector(selectSelectedInsuranceEvent);
  const selectFirstLicense = useSelector(firstLicense);
  const firstLicenseDate = selectFirstLicense ? Date.parse(selectFirstLicense.dateLicensed ?? "") : "";
  
  const validationSchema = Yup.object().shape({
    typeCode: Yup.string().nullable().required("*Description is required"),
    startDate: Yup.date().nullable().required("*Start Date is required"),
    reinstatementDate: Yup.date().nullable().required("*Reinstatement Date is required"),
  }).test("validate-form", (value, context) => {
    let startDate = Date.parse(convertUtcDateToDisplayString(value.startDate));
    if (startDate < firstLicenseDate) {
      return context.createError({
        path: "startDate",
        message: "*Start date cannot be prior to the first licence date.",
      });
    }
    return true;
  });
  
  const data: InsuranceEventModel = {
    entityId: "",
    insuranceEventId: "",
    ...selectedEvent,
    typeCode: selectedEvent?.typeCode,
    startDate: convertUtcDateToDisplayString(selectedEvent?.startDate),
    reinstatementDate: convertUtcDateToDisplayString(selectedEvent?.reinstatementDate),
  };

  const rootValidRef = useRef(null) as any;
  const validate = () =>
  {
    dispatch(updateValidation({formName: "InsuranceEventEditor", validationFail: Object.keys(rootValidRef.current.errors).length > 0 ? true : false}));
  };

  const onCancel = () => {
    dispatch(updateValidation({formName: "InsuranceEventEditor", validationFail: false}));
    dispatch(cancelEditing())
  };

  const onSubmit = (
    values: InsuranceEventModel,
    helpers: FormikHelpers<InsuranceEventModel>
  ) => {
    const postData = {
      ...data,
      typeCode: values.typeCode,
      startDate: values.startDate,
      reinstatementDate: values.reinstatementDate,
    };

    dispatch(saveInsuranceEvent(selectedQuoteRequest.quoteRequestWipId as string, postData));
  };

  return (
    <Modal show={selectedEvent != undefined} className="event-editor" onHide={onCancel} keyboard={false} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{data.insuranceEventId ? "Edit Event" : "Add New Event"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={data} validationSchema={validationSchema} innerRef={rootValidRef} validate={validate} onSubmit={onSubmit}>
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <div
                  className={
                    touched.typeCode && errors.typeCode ? "is-invalid" : ""
                  }>
                  <Form.Control
                      className="custom-select"
                      as="select"
                      id="descriptions-list"
                      name="typeCode"
                      onChange={(e) => {
                          handleChange(e)
                        }
                      }
                      onBlur={handleBlur}
                      value={values.typeCode ?? ""}
                      isInvalid={
                        touched.typeCode && errors.typeCode ? true : false
                      }>
                      <option key="" value="" />
                      {insuranceEventTypeCodes &&
                        insuranceEventTypeCodes.map((t) => (
                        <option key={t.codeValue} value={t.codeValue ?? ""}>
                          {t.description}
                        </option>
                        ))}
                    </Form.Control>
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.typeCode}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="startDate">Start Date</Form.Label>
                <br />
                <div className={touched.startDate && errors.startDate ? "is-invalid" : ""}>
                  <ReactDatePicker
                    name="startDate"
                    className={
                      touched.startDate && errors.startDate
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={handleChange}
                  />
                </div>
                <Form.Control.Feedback type="invalid">{errors.startDate}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="reinstatementDate">Reinstatement Date</Form.Label>
                <br />
                <div
                  className={
                    touched.reinstatementDate && errors.reinstatementDate ? "is-invalid" : ""
                  }>
                  <ReactDatePicker
                    name="reinstatementDate"
                    className={
                      touched.reinstatementDate && errors.reinstatementDate
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={handleChange}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.reinstatementDate}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Row className="modal-footer">
                <Button variant="secondary" data-testid="btnCancel" type="button" onClick={onCancel}>
                  Cancel
                </Button>

                <Button variant="primary" type="submit" className="ml-2">
                  Submit
                </Button>
              </Form.Row>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default Editor;
