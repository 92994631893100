import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "./app/store";
import { ReferenceData } from "./service/RequoteApiTypes";
import * as configurationService from "./service/configurationService";
import * as referenceDataService from "./service/referenceDataService";
import CONSTANTS from "./constants";
import { HTTP, HttpAction, HttpError } from "./app/middlewares/http/types";

interface AppRootState {
  isLoading: boolean;
  error: string | null;
  featureFlagConfig: {
    loaded: boolean;
    data: string[];
  };
  referenceData: { [categoryName: string]: ReferenceData[] };
}

const initialState: AppRootState = {
  isLoading: false,
  error: null,
  featureFlagConfig: {
    loaded: false,
    data: [],
  },
  referenceData: {},
};

export const appRootSlice = createSlice({
  name: "app-root",
  initialState,
  reducers: {
    loading: (state) => {
      state.isLoading = true;
    },
    loadingComplete: (state) => {
      state.isLoading = false;
    },
    setFeatureFlags: (state, action: PayloadAction<string[]>) => {
      state.featureFlagConfig = {
        loaded: true,
        data: action.payload,
      };
    },
    setReferenceDataCategory: (
      state,
      action: PayloadAction<{ categoryName: string; data: ReferenceData[] }>
    ) => {
      state.referenceData[action.payload.categoryName] = action.payload.data;
    },
    loadingFailed: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loading,
  setFeatureFlags,
  setReferenceDataCategory,
  loadingFailed,
  loadingComplete,
} = appRootSlice.actions;

export const loadReferenceDataCategory = (categoryName: string): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    dispatch(loading());

    const action: HttpAction<ReferenceData[]> = {
      type: HTTP,
      meta: {
        promise: () => referenceDataService.getReferenceDataCategory(categoryName),
        resolve: (data?: ReferenceData[]) => {
          dispatch(setReferenceDataCategory({ categoryName, data: data || [] }));
          dispatch(loadingComplete);
        },
        reject: (error: HttpError) => {
          dispatch(loadingFailed(error.message));
          dispatch(loadingComplete);
        },
        cacheKey: `ref-data-${categoryName}-cache`,
        showDefaultLoader: false,
      },
    };

    dispatch(action);
  };
};

export const getReferenceData = (): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    Object.values(CONSTANTS.REFDATA).forEach((category) => {
      setTimeout(() => dispatch(loadReferenceDataCategory(category)), 200);
    });
  };
};

export const getActiveFeatureFlags = (): AppThunk<void> => (dispatch) => {
  const action: HttpAction<string[]> = {
    type: HTTP,
    meta: {
      promise: () => configurationService.getActiveFeatureFlagsAsync(),
      resolve: (data?: string[]) => {
        dispatch(setFeatureFlags(data ?? []));
      },
    },
  };

  dispatch(action);
};

export const selectIsAppReady = (state: RootState) => {
  return (
    state.appRoot.featureFlagConfig.loaded &&
    Object.values(CONSTANTS.REFDATA).every((category) => category in state.appRoot.referenceData)
  );
};

export const selectLicenseInfoEnabled = (state: RootState): boolean => {
  return (
    state.appRoot.featureFlagConfig.data.findIndex(
      (f) => f === CONSTANTS.EnableLicenseInfoFeatureFlag
    ) > -1
  );
};

export const selectSuspensionInfoEnabled = (state: RootState): boolean => {
  return (
    state.appRoot.featureFlagConfig.data.findIndex(
      (f) => f === CONSTANTS.EnableSuspensionInfoFeatureFlag
    ) > -1
  );
};

export const selectIsGridStepOverrideEnabled = (state: RootState): boolean => {
  return (
    state.appRoot.featureFlagConfig.data.findIndex(
      (f) => f === CONSTANTS.EnableGridStepOverrideFeatureFlag
    ) > -1
  );
};

export const selectQuoteRenewalEnabled = (state: RootState): boolean => {
  return (
    state.appRoot.featureFlagConfig.data.findIndex(
      (f) => f === CONSTANTS.EnableQuoteRenewalFeatureFlag
    ) > -1
  );
};

export const selectTermEffectiveDateEnabled = (state: RootState): boolean => {
  return (
    state.appRoot.featureFlagConfig.data.findIndex(
      (f) => f === CONSTANTS.EnableTermEffectiveDateFlag
    ) > -1
  );
};

export const selectMultiLineEnabled = (state: RootState): boolean => {
  return (
    state.appRoot.featureFlagConfig.data.findIndex(
      (f) => f === CONSTANTS.EnableMultiLineFeatureFlag
    ) > -1
  );
};

export const selectDisableSubmitButtonEnabled = (state: RootState): boolean => {
  return (
    state.appRoot.featureFlagConfig.data.findIndex(
      (f) => f === CONSTANTS.EnableSubmitButtonDisableFeatureFlag
    ) > -1
  );
};

export const selectClaimsEnabled = (state: RootState): boolean => {
  return (
    state.appRoot.featureFlagConfig.data.findIndex((f) => f === CONSTANTS.EnableClaimsFeatureFlag) >
    -1
  );
};

export const selectVehiclesEnabled = (state: RootState): boolean => {
  return (
    state.appRoot.featureFlagConfig.data.findIndex((f) => f === CONSTANTS.EnableVehiclesFeatureFlag) >
    -1
  );
};

export const splitMisrepresentationFraudEnabled = (state: RootState): boolean => {
  return (
    state.appRoot.featureFlagConfig.data.findIndex(
      (f) => f === CONSTANTS.EnableSplitMisrepresentationFraudFeatureFlag
    ) > -1
  );
};

export const vehicleDeductibleEditorFeatureFlagEnabled = (state: RootState): boolean => {
  return (
    state.appRoot.featureFlagConfig.data.findIndex((f) => f === CONSTANTS.EnableVehicleDeductibleEditorFeatureFlag) >
    -1
  );
};

export const thirdPartyClaimFeatureFlagEnabled = (state: RootState): boolean => {
  return (
    state.appRoot.featureFlagConfig.data.findIndex((f) => f === CONSTANTS.EnableThirdPartyClaimFeatureFlag) >
    -1
  );
};

export const selectCompareButtonFeatureFlagEnabled = (state: RootState): boolean => {
  return (
    state.appRoot.featureFlagConfig.data.findIndex((f) => f === CONSTANTS.EnableCompareButtonFeatureFlag) >
    -1
  );
};

export const selectReferenceDataCategory = (state: RootState, categoryName: string) =>
  state.appRoot.referenceData[categoryName] || [];

export const selectInsuranceEventTypeCodes = (state: RootState) => {
  let insurance_event_suspension_codes: string[];
  if (splitMisrepresentationFraudEnabled(state))
    insurance_event_suspension_codes = ["cm", "np", "ot", "ur", "cs", "dw", "nv", "cf"];
  else insurance_event_suspension_codes = ["cm", "np", "ot", "ur", "cs", "dw", "nv"];
  return selectReferenceDataCategory(state, CONSTANTS.REFDATA.INSURANCE_EVENT_TYPE_CODE).filter(
    (w) => insurance_event_suspension_codes.includes(w.codeValue ?? "")
  );
};

export const selectPolicyHistoryCancellationReasonTypes = (state: RootState) =>
  selectReferenceDataCategory(
    state,
    CONSTANTS.REFDATA.POLICY_HISTORY_CANCELLATION_REASON_TYPE_CODE
  );

export const selectPolicyHistoryLineOfBusinessTypes = (state: RootState) =>
  selectReferenceDataCategory(state, CONSTANTS.REFDATA.POLICY_HISTORY_LINE_OF_BUSINESS_TYPE_CODE);

export const selectInsuranceCompanyTypes = (state: RootState) =>
  selectReferenceDataCategory(state, CONSTANTS.REFDATA.INSURANCE_COMPANY_TYPE_CODE);

export const selectConvictionTypeCodes = (state: RootState): ReferenceData[] =>
  selectReferenceDataCategory(state, CONSTANTS.REFDATA.CONVICTION_TYPE_CODE);

export const selectConvictionSupplementTypeCodes = (state: RootState): ReferenceData[] =>
  selectReferenceDataCategory(state, CONSTANTS.REFDATA.CONVICTION_SUPPLEMENT_TYPE_CODE);

export const selectMaritalStatusTypeCodes = (state: RootState): ReferenceData[] =>
  selectReferenceDataCategory(state, CONSTANTS.REFDATA.MARITAL_STATUS_TYPE_CODE);

export const selectGenderTypeCodes = (state: RootState): ReferenceData[] =>
  selectReferenceDataCategory(state, CONSTANTS.REFDATA.GENDER_TYPE_CODE);

export const selectOccupationTypeCodes = (state: RootState): ReferenceData[] =>
  selectReferenceDataCategory(state, CONSTANTS.REFDATA.OCCUPATION_TYPE_CODE);

export const selectSuspensionTypeCodes = (state: RootState): ReferenceData[] =>
  selectReferenceDataCategory(state, CONSTANTS.REFDATA.SUSPENSION_TYPE_CODE);

export const selectCountryTypeCodes = (state: RootState): ReferenceData[] =>
  selectReferenceDataCategory(state, CONSTANTS.REFDATA.COUNTRY_TYPE_CODE);

export const selectClaimCauseOfLossPerilTypeCodes = (state: RootState): ReferenceData[] =>
  selectReferenceDataCategory(state, CONSTANTS.REFDATA.CLAIM_CAUSE_OF_LOSS_PERIL_TYPE_CODE);

export const selectClaimCauseOfLossCircumstanceTypeCodes = (state: RootState): ReferenceData[] =>
  selectReferenceDataCategory(state, CONSTANTS.REFDATA.CLAIM_CAUSE_OF_LOSS_CIRCUMSTANCE_TYPE_CODE);

export const selectClaimCauseOfLossCircumstanceTypeCodesAndPerilCodes = (
  state: RootState
): ReferenceData[] => {
  const circumstance = selectReferenceDataCategory(
    state,
    CONSTANTS.REFDATA.CLAIM_CAUSE_OF_LOSS_CIRCUMSTANCE_TYPE_CODE
  );
  const peril = selectReferenceDataCategory(
    state,
    CONSTANTS.REFDATA.CLAIM_CAUSE_OF_LOSS_PERIL_TYPE_CODE
  );

  return circumstance
    .concat(peril)
    .sort((a, b) => (a.description ?? "").localeCompare(b.description ?? ""));
};

export const selectPayoutAmountCoverageTypeCodes = (state: RootState): ReferenceData[] => {
  const payout_amount_coverage_codes = ["cmp", "col", "tpbi", "tppd", "tpdc", "ab", "ua"];
  return selectReferenceDataCategory(state, CONSTANTS.REFDATA.COVERAGE_TYPE_CODE).filter((f) =>
    payout_amount_coverage_codes.includes(f.codeValue ?? "")
  );
};

export const selectCoverageTypeCodes = (state: RootState): ReferenceData[] =>
  selectReferenceDataCategory(state, CONSTANTS.REFDATA.COVERAGE_TYPE_CODE);

export default appRootSlice.reducer;
