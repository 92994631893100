import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HTTP, HttpAction } from "./../../../../app/middlewares/http/types";
import { AppThunk, RootState } from "app/store";
import { ClaimModel, ClaimantCoverageModel, RelationshipModel, ThirdPartyClaimModel } from "../../../../service/RequoteApiTypes";
import { resumeWorkInProgress } from "../../../wip-list/wipListSlice";
import * as claimService from "../../../../service/claimService";
import { v4 as uuidv4 } from "uuid";

interface ClaimState {
  error: string | null;
  claims: ClaimModel[];
  selectedClaim?: ClaimModel;
  thirdParty?: ThirdPartyClaimModel[];
}

const initialState: ClaimState = {
  error: null,
  claims: [],
};

export const ClaimSlice = createSlice({
  name: "claim",
  initialState: initialState,
  reducers: {
    addAction: (state, action: PayloadAction<ClaimModel>) => {
      state.selectedClaim = action.payload;
    },
    cancelEditAction: (state) => {
      state.selectedClaim = undefined;
      state.thirdParty = undefined;
    },
    editAction: (state, action: PayloadAction<ClaimModel>) => {
      state.selectedClaim = action.payload;
      state.thirdParty = action.payload.thirdPartyClaim ?? [];
    },
    saveAction: (state, action: PayloadAction<ClaimModel>) => {
      const payload = action.payload;

      const claimIndex = state.claims.findIndex((e) => e.claimId === payload.claimId);
      if (claimIndex > -1) {
        state.claims[claimIndex] = payload;
      } else {
        state.claims.push(payload);
      }

      state.selectedClaim = undefined;
      state.thirdParty = undefined;
    },
    deleteAction: (state, action: PayloadAction<ClaimModel>) => {
      state.claims.splice(
        state.claims.findIndex((l) => l.claimId === action.payload.claimId),
        1
      );
    },
    setThirdParty: (state, action: PayloadAction<ThirdPartyClaimModel[]>) => {
      state.thirdParty = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resumeWorkInProgress, (state, action) => {
      state.claims = action.payload?.claims ?? [];
    });
  },
});

export const saveClaim =
  (quoteRequestWipId: string, claim: ClaimModel, commandType: string): AppThunk<void> =>
  async (dispatch) => {
    if(commandType === "add") {
      claim.claimId = (claim.claimId ?? "").length === 0 ? uuidv4() : claim.claimId;
      claim.claimant?.map((m) => {
        m.claimantId = (m.claimantId ?? "").length === 0 ? claim.claimId : m.claimantId;
        m.claimCoverageId = (m.claimCoverageId ?? "").length === 0 ? uuidv4() : m.claimCoverageId;
        return m;
      });
      
      const action: HttpAction<ClaimModel> = {
        type: HTTP,
        meta: {
          promise: () => claimService.saveClaim(quoteRequestWipId, claim),
          resolve: (response: ClaimModel) => {
            dispatch(saveAction(response));
          },
        },
      };
      return dispatch(action);
      
    } else {
      let _claim  = {} as ClaimModel;
      let _relationship = [] as RelationshipModel[];
      let _claimantCoverage = [] as ClaimantCoverageModel[];

      _claim.claimId = (claim.claimId ?? "").length === 0 ? uuidv4() : claim.claimId;
      _claim.claimId = claim.claimId;
      _claim.claimNumber = claim.claimNumber;
      _claim.claimSourceCode = claim.claimSourceCode;
      _claim.accidentResponsiblePercentage = claim.accidentResponsiblePercentage;
      _claim.chargeableClaimIndicator = claim.chargeableClaimIndicator;
      _claim.causeOfLossPerilCode = claim.causeOfLossPerilCode;
      _claim.causeOfLossCircumstanceCode = claim.causeOfLossCircumstanceCode;
      _claim.lossDate = claim.lossDate;
      _claim.underwritingUseCode = claim.underwritingUseCode;
      _claim.gridUseCode = claim.gridUseCode;
      _claim.addedDate = claim.addedDate;
      
      claim.relationship?.forEach((c) => {
        _relationship.push(c);
      });
      _claim.relationship = _relationship;

      claim.claimant?.forEach((m) => {
            let _claimantId = (m.claimantId ?? "").length === 0 ? claim.claimId : m.claimantId;
            let _claimCoverageId = (m.claimCoverageId ?? "").length === 0 ? uuidv4() : m.claimCoverageId;
            let _claimant = {
              claimantId: _claimantId, 
              claimCoverageId: _claimCoverageId,
              coverageTypeCode: m.coverageTypeCode,
              amount: m.amount,
              accountTypeCode: m.accountTypeCode,
            } as ClaimantCoverageModel;
            _claimantCoverage.push(_claimant);
      });
      _claim.claimant = _claimantCoverage;
      _claim.thirdPartyClaim = claim.thirdPartyClaim;
      const action: HttpAction<ClaimModel> = {
        type: HTTP,
        meta: {
          promise: () => claimService.saveClaim(quoteRequestWipId, _claim),
          resolve: (response: ClaimModel) => {
            dispatch(saveAction(response));
          },
        },
      };
      return dispatch(action);
    }
  };

export const editClaim =
  (eventModel: ClaimModel): AppThunk<void> =>
  async (dispatch) => {
    dispatch(editAction(eventModel));
  };

export const cancelEditClaim = (): AppThunk<void> => async (dispatch) => {
  dispatch(cancelEditAction());
};

export const addClaim =
  (claim: ClaimModel): AppThunk<void> =>
  async (dispatch) => {
    dispatch(addAction(claim));
  };

export const deleteClaim =
  (quoteRequestWipId: string, claimModel: ClaimModel): AppThunk<void> =>
  async (dispatch) => {
    const action: HttpAction<void> = {
      type: HTTP,
      meta: {
        promise: () => claimService.deleteClaim(quoteRequestWipId, claimModel.claimId),
        resolve: () => {
          dispatch(deleteAction(claimModel));
        },
      },
    };

    return dispatch(action);
  };

export const selectClaims = (rootState: RootState) =>
  rootState.claim.claims
    .slice()
    .sort(
      (a, b) =>
        (a.relationship != null ? a.relationship[0].entity_id ?? "" : "").localeCompare(
          b.relationship != null ? b.relationship[0].entity_id ?? "" : ""
        ) || new Date(b.lossDate ?? "").valueOf() - new Date(a.lossDate ?? "").valueOf()
    );

export const selectSelectedClaim = (rootState: RootState) => rootState.claim.selectedClaim;

export const selectThirdParty = (rootState: RootState) => rootState.claim.thirdParty;

export const { editAction, cancelEditAction, saveAction, addAction, deleteAction, setThirdParty } =
  ClaimSlice.actions;

export default ClaimSlice.reducer;
