import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { TrashFill, PencilSquare } from "react-bootstrap-icons";
import { InsuranceEventModel } from "../../../../service/RequoteApiTypes";
import DisplayDate from "./../../../../components/DateDisplay";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import { editInsuranceEvent, deleteInsuranceEvent } from "./insuranceEventSlice";
import helpers from "./../../../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import CONSTANTS from "./../../../../constants";
import CodeValue from "./../../../../components/CodeValue";

interface Props {
  data: InsuranceEventModel;
}

const ListItemView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const selectedQuoteRequest = useSelector(selectQuoteRequest);

  const onEdit = (data: InsuranceEventModel) => {
    dispatch(editInsuranceEvent(data));
  };

  const onDelete = (data: InsuranceEventModel) => {
    helpers.confirm({
      header: "Confirm Delete",
      body: "Are you sure you want to delete it?",
      onConfirm: () => {
        dispatch(deleteInsuranceEvent(selectedQuoteRequest.quoteRequestWipId as string, data));
      },
    });
  };

  return (
    <tr>
      <td className="date-column">
        <DisplayDate value={props.data.startDate} />
      </td>
      <td className="date-column-lg">
        <DisplayDate value={props.data.reinstatementDate} />
      </td>
      <td>
        <CodeValue
          categoryName={CONSTANTS.REFDATA.INSURANCE_EVENT_TYPE_CODE}
          value={props.data.typeCode?.toString()}
        />
      </td>
      <td>
        {props.data.typeCode?.toString() !== "npi" && (
          <div>
            <OverlayTrigger placement="top" overlay={<Tooltip id="EditEvent">Edit Event</Tooltip>}>
              <Button
                variant="secondary"
                size="sm"
                title="Edit"
                onClick={() => onEdit(props.data)}
                data-testid="EditEvent">
                <PencilSquare></PencilSquare>
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="DeleteEvent">Delete Event</Tooltip>}>
              <Button
                variant="danger"
                data-testid="DeleteEvent"
                size="sm"
                className="ml-2"
                title="Delete"
                onClick={() => onDelete(props.data)}>
                <TrashFill></TrashFill>
              </Button>
            </OverlayTrigger>
          </div>
        )}
      </td>
    </tr>
  );
};

export default ListItemView;
