import React from "react";
import { NavLink, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BoxArrowUpRight } from "react-bootstrap-icons";

interface Props {
  quoteRefId: string | null;
  requestId: string | null;
  displayMode?: "anchor" | "text";
}

interface PresentationProps {
  quoteInquiryUrl: string;
  quoteRefId: string | null;
  requestId: string | null;
  displayMode: "anchor" | "text";
}

const QuoteInquiryLink: React.FC<PresentationProps> = ({
  quoteInquiryUrl,
  quoteRefId,
  displayMode,
}) => {
  return (
    <>
      {quoteRefId ? (
        <>
          <span className="d-inline-flex">{quoteRefId}</span>

          {displayMode === "anchor" ? (
            <NavLink
              href={quoteInquiryUrl}
              target="_blank"
              rel="noreferrer"
              className="d-inline-flex pl-2">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="QuoteInquiryLink">Open in Quote Inquiry</Tooltip>}>
                <BoxArrowUpRight></BoxArrowUpRight>
              </OverlayTrigger>
            </NavLink>
          ) : null}
        </>
      ) : (
        "-"
      )}
    </>
  );
};

const QuoteInquiryLinkComponent: React.FC<Props> = ({ quoteRefId, requestId, displayMode = "anchor" }) => {
  const quoteInquiryUrl = (window.REACT_APP_QUOTE_INQUIRY_URL ?? "") + (requestId ?? "");

  return (
    <QuoteInquiryLink
      quoteRefId={quoteRefId}
      requestId={requestId}
      quoteInquiryUrl={quoteInquiryUrl}
      displayMode={displayMode}
    />
  );
};

export default QuoteInquiryLinkComponent;
