import { Form, InputGroup } from "react-bootstrap";
import { useFormikContext, useField, getIn } from "formik";
import CONSTANTS from "./../../../../../constants";
import CodeValue from "./../../../../../components/CodeValue";

const SingleSupplementEditor = ({ ...props }) => {
  const { touched, errors, handleBlur, handleChange } = useFormikContext();
  const [field] = useField(props as any);

  return (
    <Form.Group>
      <Form.Label>
        <CodeValue
          categoryName={CONSTANTS.REFDATA.CONVICTION_SUPPLEMENT_TYPE_CODE}
          value={props.data.typeCode}
        />
      </Form.Label>
      <InputGroup>
        <Form.Control
          as="input"
          name={`${field.name}.value`}
          defaultValue={props.data.value ?? ""}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={
            getIn(touched, `${props.listObject}.value`) &&
            getIn(errors, `${props.listObject}.value`)
              ? true
              : false
          }
        />
        <InputGroup.Append>
          <InputGroup.Text id="basic-addon2">km/h over limit</InputGroup.Text>
        </InputGroup.Append>

        <Form.Control.Feedback type="invalid">
          {getIn(errors, `${props.listObject}.value`)}
        </Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
};

export default SingleSupplementEditor;
