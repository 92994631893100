export function set<T extends any>(key: string, data: T): void {
    typeof data == "string" 
        ? sessionStorage.setItem(key, data)
        : sessionStorage.setItem(key, JSON.stringify(data));
}

export function get<T extends any>(key: string): T {
  return JSON.parse(sessionStorage.getItem(key) || "{}") as T;
}

export function remove(key: string): void {
  sessionStorage.removeItem(key);
}

export function exists(key: string): boolean {
  return sessionStorage.getItem(key) !== null;
}
