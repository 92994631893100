import React from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { QuoteRequestWipSummaryModel } from "../../../service/RequoteApiTypes";
import { deleteWipAsync } from "./../wipListSlice";
import { TrashFill } from "react-bootstrap-icons";
import helpers from "./../../../utils/helpers";

const WipListTableRow: React.FC<{ data: QuoteRequestWipSummaryModel }> = ({ data }) => {
  const dispatch = useDispatch();

  const handleDelete = (model: QuoteRequestWipSummaryModel) => {
    helpers.confirm({
      header: "Confirm Delete",
      body: "Are you sure you want to delete it?",
      onConfirm: () => {
        if (model.quoteRequestWipId) {
          dispatch(deleteWipAsync(model.quoteRequestWipId));
        }
      },
    });
  };

  return (
    <tr key={data.quoteRequestWipId}>
      <td>
        <NavLink to={`/quote-request/${data.quoteRequestWipId}/quote-details`}>
          {data.sourceQuoteRequest?.quoteRefId}
        </NavLink>
      </td>
      <td>{data.sourceQuoteRequest?.vendorName}</td>
      <td>{data.sourceQuoteRequest?.brokerName}</td>
      <td>{data.sourceQuoteRequest?.brokerNumber}</td>
      <td>
        <OverlayTrigger placement="top" overlay={<Tooltip id="DeleteWip">Delete</Tooltip>}>
          <Button
            data-testid="DeleteWip"
            variant="danger"
            size="sm"
            title="Delete"
            onClick={() => handleDelete(data)}>
            <TrashFill></TrashFill>
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default WipListTableRow;
