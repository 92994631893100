import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationViewModel } from "./model";
import { AppThunk, RootState } from "../store";

interface NotificationState {
    list: NotificationViewModel[]
}

export const notificationSlice = createSlice({
    name: "notification-slice",
    initialState: {
        list: [] as NotificationViewModel[]
    },
    reducers: {
        add: (state: NotificationState, action: PayloadAction<Partial<NotificationViewModel>>) => {

            const notification: NotificationViewModel = {
                id: Math.floor(Math.random() * 10000),
                code: action.payload.code ?? 200,
                message: action.payload.message ?? "",
                body: action.payload.body,
                type: action.payload.type ?? "info",
                requestUrl: action.payload.requestUrl,
                traceIdentifier: action.payload.traceIdentifier
            };

            state.list.push(notification);
        },
        remove: (state: NotificationState, action: PayloadAction<number>) => {
            state.list.splice(state.list.findIndex(n => n.id === action.payload), 1);
        },
        clear: ((state: NotificationState) => {
            state.list = [];
        }),
    }
});

export const {
    add,
    remove,
    clear
} = notificationSlice.actions;

export const addError = (code: number, message: string, body?: string, requestUrl?: string, traceIdentifier?: string): AppThunk<void> => (dispatch) => {
    dispatch(add({
        code,
        message,
        body,
        type: "error",
        requestUrl,
        traceIdentifier
    }));
}

export const addSuccess = (message: string, body?: string): AppThunk<void> => (dispatch) => {
    dispatch(add({
        code: 200,
        message,
        body,
        type: "success"
    }));
}

export const addInfo = (message: string, body?: string): AppThunk<void> => (dispatch) => {
    dispatch(add({
        code: 200,
        message,
        body,
        type: "info"
    }));
}

export const addWarning = (message: string, body?: string): AppThunk<void> => (dispatch) => {
    dispatch(add({
        code: 200,
        message,
        body,
        type: "warning"
    }));
}

export const removeNotification = (id: number): AppThunk<void> => (dispatch) => {
    dispatch(remove(id));
}

export const clearAllNotifications = (): AppThunk<void> => (dispatch) => {
    dispatch(clear());
}

export const selectNotifications = (state: RootState): NotificationViewModel[] => state.notification.list

export default notificationSlice.reducer;