import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "./../../../../app/store";
import { InsuranceEventModel } from "../../../../service/RequoteApiTypes";
import * as insuranceEventService from "./../../../../service/insuranceEventService";
import { resumeWorkInProgress } from "../../../wip-list/wipListSlice";
import { listPolicyHistory } from "../policyHistory/policyHistorySlice";
import { v4 as uuidv4 } from "uuid";
import { HTTP, HttpAction } from "./../../../../app/middlewares/http/types";

interface InsuranceEventState {
  error: string | null;
  insuranceEvents: InsuranceEventModel[];
  selectedEvent?: InsuranceEventModel;
}

const initialState: InsuranceEventState = {
  error: null,
  insuranceEvents: [],
};

export const insuranceEventSlice = createSlice({
  name: "insuranceEvent",
  initialState: initialState,
  reducers: {
    loadAction: (state, action: PayloadAction<InsuranceEventModel[]>) => {
      const payload = action.payload;

      payload.forEach((item) => {
        const insuranceEventIndex = state.insuranceEvents.findIndex(
          (e) => e.insuranceEventId === item.insuranceEventId
        );
        if (insuranceEventIndex > -1) {
          state.insuranceEvents[insuranceEventIndex] = item;
        } else {
          state.insuranceEvents.push(item);
        }
      });

      //remove delete records from state
      state.insuranceEvents.forEach((item) => {
        const insuranceEventIndex = payload.findIndex(
          (e) => e.insuranceEventId === item.insuranceEventId
        );

        if (insuranceEventIndex === -1) {
          state.insuranceEvents.splice(
            state.insuranceEvents.findIndex((e) => e.insuranceEventId === item.insuranceEventId),
            1
          );
        }
      });

      state.selectedEvent = undefined;
    },
    addEvent: (state, action: PayloadAction<InsuranceEventModel>) => {
      state.selectedEvent = action.payload;
    },
    editEvent: (state, action: PayloadAction<InsuranceEventModel>) => {
      state.selectedEvent = action.payload;
    },
    cancelEditEvent: (state) => {
      state.selectedEvent = undefined;
    },
    saveEvent: (state, action: PayloadAction<InsuranceEventModel>) => {
      const payload = action.payload;

      const eventIndex = state.insuranceEvents.findIndex(
        (e) => e.insuranceEventId === payload.insuranceEventId
      );
      if (eventIndex > -1) {
        state.insuranceEvents[eventIndex] = payload;
      } else {
        state.insuranceEvents.push(payload);
      }

      state.selectedEvent = undefined;
    },
    deleteEvent: (state, action: PayloadAction<InsuranceEventModel>) => {
      state.insuranceEvents.splice(
        state.insuranceEvents.findIndex(
          (e) => e.insuranceEventId === action.payload.insuranceEventId
        ),
        1
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resumeWorkInProgress, (state, action) => {
      state.insuranceEvents = action.payload?.insuranceEvents ?? [];
    });
  },
});

export const addInsuranceEvent =
  (eventModel: InsuranceEventModel): AppThunk<void> =>
  async (dispatch) => {
    dispatch(addEvent(eventModel));
  };

export const editInsuranceEvent =
  (eventModel: InsuranceEventModel): AppThunk<void> =>
  async (dispatch) => {
    dispatch(editEvent(eventModel));
  };

export const cancelEditing = (): AppThunk<void> => async (dispatch) => {
  dispatch(cancelEditEvent());
};

export const listInsuranceEvents =
  (quoteRequestWipId: string, entityId: string): AppThunk<void> =>
  async (dispatch) => {
    const action: HttpAction<InsuranceEventModel[]> = {
      type: HTTP,
      meta: {
        promise: () => insuranceEventService.listInsuranceEvents(quoteRequestWipId, entityId),
        resolve: (response: InsuranceEventModel[]) => {
          dispatch(loadAction(response));
        },
      },
    };

    return dispatch(action);
  };

export const saveInsuranceEvent =
  (quoteRequestWipId: string, eventModel: InsuranceEventModel): AppThunk<void> =>
  async (dispatch) => {
    eventModel.insuranceEventId =
      (eventModel.insuranceEventId ?? "").length == 0 ? uuidv4() : eventModel.insuranceEventId;

    const action: HttpAction<InsuranceEventModel> = {
      type: HTTP,
      meta: {
        promise: () => insuranceEventService.saveInsuranceEvent(quoteRequestWipId, eventModel),
        resolve: (response: InsuranceEventModel) => {
          dispatch(saveEvent(response));
          dispatch(listPolicyHistory(quoteRequestWipId, eventModel.entityId));
        },
      },
    };

    return dispatch(action);
  };

export const deleteInsuranceEvent =
  (quoteRequestWipId: string, eventModel: InsuranceEventModel): AppThunk<void> =>
  async (dispatch) => {
    const action: HttpAction<void> = {
      type: HTTP,
      meta: {
        promise: () =>
          insuranceEventService.deleteInsuranceEvent(
            quoteRequestWipId,
            eventModel.entityId,
            eventModel.insuranceEventId
          ),
        resolve: () => {
          dispatch(deleteEvent(eventModel));
          dispatch(listPolicyHistory(quoteRequestWipId, eventModel.entityId));
        },
      },
    };

    return dispatch(action);
  };

export const selectInsuranceEvents = (rootState: RootState) =>
  rootState.insuranceEvent.insuranceEvents
    .slice()
    .sort((a, b) => new Date(b.startDate ?? "").valueOf() - new Date(a.startDate ?? "").valueOf());

export const selectSelectedInsuranceEvent = (rootState: RootState) =>
  rootState.insuranceEvent.selectedEvent;

const { addEvent, editEvent, cancelEditEvent, saveEvent, deleteEvent, loadAction } =
  insuranceEventSlice.actions;

export default insuranceEventSlice.reducer;
