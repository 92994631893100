import { PolicyHistoryModel } from "./RequoteApiTypes";
import axios, { AxiosResponse } from "axios";
import CONSTANTS from "../constants";

export async function savePolicyHistory(
  quoteRequestWipId: string,
  request: PolicyHistoryModel
): Promise<PolicyHistoryModel> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.POLICY_HISTORY.SAVE(quoteRequestWipId, request.entityId);

  const response = await axios.post<PolicyHistoryModel, AxiosResponse<PolicyHistoryModel>>(
    url,
    request
  );

  return response.data;
}

export async function deletePolicyHistory(
  quoteRequestWipId: string,
  entityId: string,
  id: string
): Promise<void> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.POLICY_HISTORY.DELETE(quoteRequestWipId, entityId, id);

  const response = await axios.delete(url);

  return response.data;
}

export async function listPolicyHistory(
  quoteRequestWipId: string,
  entityId: string
): Promise<PolicyHistoryModel[]> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.POLICY_HISTORY.LIST(quoteRequestWipId, entityId);

  const response = await axios.get<PolicyHistoryModel[]>(url);

  return response.data;
}
