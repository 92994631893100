import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HTTP, HttpAction, HttpError } from "../../app/middlewares/http/types";
import { addSuccess, addError } from "../../app/notification/notificationSlice";
import { AppThunk, RootState } from "app/store";
import {
  QuoteReferenceModel,
  RequestHeaderModel,
  SourceQuoteRequestModel,
} from "../../service/RequoteApiTypes";
import { resumeWorkInProgress, wipSubmitted } from "../wip-list/wipListSlice";
import * as quoteRequestWipService from "../../service/quoteRequestWipService";
import * as quoteRequestService from "../../service/quoteRequestService";
interface QuoteRequestSliceState {
  quoteRequestWipEtag: string | null;
  isLoading: boolean;
  error: string | null;
  quoteRequestWipId: string | null;
  requestHeader: RequestHeaderModel | null;
  originalQuoteRequest?: SourceQuoteRequestModel | null;
  sourceQuoteRequest?: SourceQuoteRequestModel | null;
}

const initialState: QuoteRequestSliceState = {
  quoteRequestWipEtag: null,
  isLoading: false,
  error: null,
  quoteRequestWipId: null,
  requestHeader: null,
  originalQuoteRequest: null,
  sourceQuoteRequest: null,
};

export const quoteRequestSlice = createSlice({
  name: "quoteRequestSlice",
  initialState: initialState,
  reducers: {
    loading: (state) => {
      state.isLoading = true;
    },

    loadingComplete: (state) => {
      state.isLoading = false;
    },
    saveAction: (state, action: PayloadAction<RequestHeaderModel>) => {
      const payload = action.payload;
      state.requestHeader = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resumeWorkInProgress, (state, action) => {
      state.quoteRequestWipEtag = action.payload?.concurrencyStamp ?? null;
      state.quoteRequestWipId = action.payload?.quoteRequestWipId ?? null;
      state.requestHeader = action.payload?.requestHeader ?? null;
      state.originalQuoteRequest = action.payload?.originalQuoteRequest ?? null;
      state.sourceQuoteRequest = action.payload?.sourceQuoteRequest ?? null;
    });
  },
});

export const submitWipAsync =
  (quoteRequestWipId: string, callback?: (success: boolean) => void): AppThunk<void> =>
  (dispatch) => {
    dispatch(loading());

    const action: HttpAction<QuoteReferenceModel> = {
      type: HTTP,
      meta: {
        promise: () => quoteRequestWipService.submit(quoteRequestWipId),
        resolve: (data) => {
          dispatch(wipSubmitted({ quoteRequestWipId, quoteReferenceModel: data }));

          dispatch(loadingComplete());

          if (data.success == undefined || data.success == false) {
            dispatch(addError(200, "Requote Failed"));
          }

          if (data.success === true) {
            dispatch(
              addSuccess("Requote Submitted", `New quote reference id is ${data.quoteRefId}.`)
            );
          }

          callback && callback(data.success || false);
        },
        reject: (error: HttpError) => {
          dispatch(loadingComplete());
          callback && callback(false);
        },
      },
    };

    dispatch(action);
  };

export const selectQuoteRequest = (rootState: RootState) => {
  return rootState.quoteRequest;
};

export const selectLoadingState = (rootState: RootState) => {
  return {
    isLoading: rootState.quoteRequest.isLoading,
    error: rootState.quoteRequest.error,
  };
};

export const selectQuoteRequestWipEtag = (rootState: RootState) => {
  return rootState.quoteRequest.quoteRequestWipEtag;
};

export const saveQuoteDetails =
  (quoteRequestWipId: string, requestHeader: RequestHeaderModel): AppThunk<void> =>
  async (dispatch) => {
    const action: HttpAction<RequestHeaderModel> = {
      type: HTTP,
      meta: {
        promise: () => quoteRequestService.saveRequestDetails(quoteRequestWipId, requestHeader),
        resolve: (response: RequestHeaderModel) => {
          dispatch(saveAction(response));
        },
      },
    };

    return dispatch(action);
  };

export const { loading, loadingComplete, saveAction } = quoteRequestSlice.actions;

export default quoteRequestSlice.reducer;
