import { Button, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { TrashFill, PencilSquare } from "react-bootstrap-icons";
import { ConvictionViewModel } from "../../../../service/RequoteApiTypes";
import DisplayDate from "./../../../../components/DateDisplay";
import CONSTANTS from "./../../../../constants";
import CodeValue from "./../../../../components/CodeValue";
import { useDispatch, useSelector } from "react-redux";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import { editConviction, deleteConviction } from "./convictionSlice";
import helpers from "./../../../../utils/helpers";

interface Props {
  data: ConvictionViewModel;
}

const ListItemView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const selectedQuoteRequest = useSelector(selectQuoteRequest);

  const onDelete = (data: ConvictionViewModel) => {
    helpers.confirm({
      header: "Confirm Delete",
      body: "Are you sure you want to delete it?",
      onConfirm: () => {
        dispatch(deleteConviction(selectedQuoteRequest.quoteRequestWipId as string, data));
      },
    });
  };

  return (
    <tr>
      <td className="date-column">
        <DisplayDate value={props.data.convictionDate} />
      </td>
      <td className="description-column">
        <CodeValue
          categoryName={CONSTANTS.REFDATA.CONVICTION_TYPE_CODE}
          value={props.data.typeCode?.toString()}
        />
      </td>
      <td>
        {props.data.supplements &&
          props.data.supplements
            .filter((s) => s.typeCode == "excess speed")
            .filter((s) => s.value)
            .map((s, index) => (
              <span className="font-weight-normal" key={index}>
                <CodeValue
                  categoryName={CONSTANTS.REFDATA.CONVICTION_SUPPLEMENT_TYPE_CODE}
                  value={s.typeCode}
                />
                :{" "}
                <Badge variant="secondary" className="font-weight-normal">
                  {s.value}
                </Badge>{" "}
                <span className="text-muted font-italic">km/h over limit</span>
              </span>
            ))}
      </td>
      <td>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="EditConviction">Edit Conviction</Tooltip>}>
          <Button
            variant="secondary"
            data-testid="EditConviction"
            size="sm"
            title="Edit"
            onClick={() => dispatch(editConviction(props.data))}>
            <PencilSquare></PencilSquare>
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="DeleteConviction">Delete Conviction</Tooltip>}>
          <Button
            variant="danger"
            data-testid="DeleteConviction"
            size="sm"
            className="ml-2"
            title="Delete"
            onClick={() => onDelete(props.data)}>
            <TrashFill></TrashFill>
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default ListItemView;
