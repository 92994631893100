import { useSelector } from "react-redux";
import DateDisplay from "../../../../components/DateDisplay";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import CodeValue from "../../../../components/CodeValue";
import CONSTANTS from "../../../../constants";
import "./QuoteHeader.scss";
import { PolicyHistoryModel } from "../../../../service/RequoteApiTypes";
import {
  selectNonAutoPolicyHistory,
  selectPolicyHistory,
} from "../policyHistory/policyHistorySlice";
import { selectQuoteRenewalEnabled } from "../../../../appRootSlice";
import { selectEntities } from "./../entity/entitySlice";

const QuoteHeader = () => {
  const quoteRequest = useSelector(selectQuoteRequest);
  const requestHeader = quoteRequest?.requestHeader;
  const sourceQuoteRequest = quoteRequest?.sourceQuoteRequest;

  const policyHistories: PolicyHistoryModel[] = useSelector(selectPolicyHistory);
  const isQuoteRenewalFeatureFlagEnabled = useSelector(selectQuoteRenewalEnabled);

  const entityIds = useSelector(selectEntities)
    ?.filter((f) => f.roles?.includes(CONSTANTS.ENTITY_ROLES.APPLICANT))
    .map((m) => m.entityId);

  const getTransactionType = () => {
    return isQuoteRenewalFeatureFlagEnabled
      ? policyHistories.find((a) => a.transferReason === "2" && entityIds.includes(a.entityId)) ===
        undefined
        ? "quote-new"
        : "quote-renewal"
      : requestHeader?.transactionTypeCode;
  };

  const policyHistoriesNonAuto: PolicyHistoryModel[] = useSelector(selectNonAutoPolicyHistory);

  const getMultiLine = () => {
    return policyHistoriesNonAuto.filter(
      (e) =>
        (e.lineOfBusiness === "farm" ||
          (e.lineOfBusiness === "habl" && (e.detailedLineOfBusiness === "home" || e.detailedLineOfBusiness === "" || e.detailedLineOfBusiness === null))) &&
        e.insuranceCompanyCode === "sgi"
    ).length > 0
      ? "Yes"
      : "No";
  };

  return (
    <div className="quote-request-header d-flex p-3">
      <div className="d-flex flex-column flex-grow-1 align-self-center">
        <h1 className="d-block">
          Requote of {sourceQuoteRequest && sourceQuoteRequest.quoteRefId}
        </h1>
      </div>

      <div className="d-flex flex-column align-self-center">
        <div className="col font-weight-normal">Transaction Type</div>
        <div className="col font-weight-bold">
          <CodeValue
            categoryName={CONSTANTS.REFDATA.TRANSACTION_TYPE}
            value={getTransactionType()}
          />
        </div>
      </div>

      <div className="d-flex flex-column align-self-center">
        <div className="col font-weight-normal">Term Effective Date</div>
        <div className="col font-weight-bold">
          <DateDisplay value={requestHeader?.termEffectiveDate} />
        </div>
      </div>

      <div className="d-flex flex-column align-self-center">
        <div className="col font-weight-normal">Term Expiry Date</div>
        <div className="col font-weight-bold">
          <DateDisplay value={requestHeader?.termExpiryDate} />
        </div>
      </div>
      <div className="d-flex flex-column align-self-center">
        <div className="col font-weight-normal">Multi-Line Client</div>
        <div className="col font-weight-bold">{getMultiLine()}</div>
      </div>
    </div>
  );
};

export default QuoteHeader;
