import * as React from "react";
import { Container } from "react-bootstrap";
import NavMenu from "./NavMenu";

const Layout: React.FC<{}> = (props: React.PropsWithChildren<{}>) => {
  return (
    <React.Fragment>
      <NavMenu />
      <Container fluid className="app-content">
        {props.children}
      </Container>
    </React.Fragment>
  );
};

export default Layout;
