import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "./app/store";


export interface RootValidationModel {
    formName: string;
    validationFail: boolean;    
}

interface RootValidationState {
    list: RootValidationModel[]
}

export const rootValidationSlice = createSlice({
    name: "root-validation-slice",
    initialState: {
        list: [] as RootValidationModel[]
    },
    reducers: {
        updateValidation: (state: RootValidationState, action: PayloadAction<RootValidationModel>) => {
            var findObj = state.list.find(n => n.formName === action.payload.formName)
            if(findObj) findObj.validationFail = action.payload.validationFail;
            else state.list.push(action.payload)
        }
    }
});

export const {
    updateValidation    
} = rootValidationSlice.actions;

export const isRootValid = (state: RootState): boolean => state.rootValidation.list.find(p => p.validationFail === true) ? false : true;
export const isValidationPending = (frmName: string) => (state: RootState): boolean => state.rootValidation.list.find(p => p.formName === frmName && p.validationFail === true) ? true : false;
export const getValidationPending = (state: RootState): RootValidationModel[] => state.rootValidation.list.filter(p => p.validationFail === true);

export default rootValidationSlice.reducer;