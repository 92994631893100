import { msalInstance } from "./initializeMsal";

export async function getAccessToken() {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        return;
    }
    const authResult = await msalInstance.acquireTokenSilent({
        scopes: (window as any).REACT_APP_AD_AUTH_SCOPES,
        account: account,
    })

    return authResult.accessToken;
}
