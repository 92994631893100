import { Action, AnyAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

export const HTTP = 'HTTP';

export type HttpError = {
    code: number,
    message: string,
    traceIdentifier?: string,
    detail?: AxiosError
}

export type HttpAction<R> = {
    meta: {
        promise: () => Promise<R>,
        pending?: () => AnyAction,
        resolve: (data: R) => void,
        reject?: (error: HttpError) => void,
        cacheKey?: string,
        skipAuthorization?: boolean,
        showDefaultErrors?: boolean,
        showDefaultLoader?: boolean,
    }
} & Action<typeof HTTP>;
