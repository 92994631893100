import React from "react";
import { useSelector } from "react-redux";
import { selectLicenseClasses } from "./../licenseSlice";

interface Props {
  province?: string | null;
  value?: string | null;
}

const LicenseClass: React.FC<Props> = (props: Props) => {
  const classes = useSelector(selectLicenseClasses);
  const licenseClass = classes.find(
    (c) => c.provinceCode === props.province && c.licenseClassTypeCode == props.value
  );
  return <>{licenseClass ? licenseClass.licenseClassTypeName : "-"}</>;
};

export default LicenseClass;
