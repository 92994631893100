import React from "react";
import {
  Form,
  Button,
  InputGroup,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { TrashFill } from "react-bootstrap-icons";
import {ThirdPartyClaimModel} from "../../../../service/RequoteApiTypes";


interface Props {
    data?: ThirdPartyClaimModel[];
    deleteTp: () => void;
}
const ThirdPartyEditor: React.FC<Props> = (props) => {
    return (
            <div>
                {(props.data && props.data.length ? (props.data.map((tp) => {
                    return (<Row className="mb-2" key={tp.coverageTypeCode}>
                        <Col xs={8}>
                            <Form.Control
                                className="custom-select"
                                as="select"
                                name={`tpdc`}
                                defaultValue={tp.coverageTypeCode ?? "tpdc"}>
                                <option key="tpdc" value="tpdc">TP DCPD</option>
                            </Form.Control>
                        </Col>
                        <Col xs={3}>
                            <InputGroup>
                                <InputGroup.Append>
                                <InputGroup.Text>$</InputGroup.Text>
                                </InputGroup.Append>
                                <Form.Control
                                as="input"
                                name={`amount`}
                                type="number"
                                defaultValue={String(tp.account?.amount)}
                                disabled>
                                </Form.Control>
                            </InputGroup>
                        </Col>
                        <Col className="mt-1">
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="DeleteThirdParty">Delete Third Party</Tooltip>}>
                                <Button
                                variant="danger"
                                data-testid="DeleteThirdParty"
                                size="sm"
                                title="Delete"
                                onClick={() => props.deleteTp() }>
                                <TrashFill></TrashFill>
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>)
                })
                ) : <div></div> )}
            </div>
        )
 };

 export default ThirdPartyEditor;