import {useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  cancelEditInsuranceInformation,
  savePersonalInformation,
  selectSelectedPersonalInformation,
} from "./personalInformationSlice";
import { PersonalInformationViewModel } from "../../../../service/RequoteApiTypes";
import { ReactDatePicker } from "../../../../components/ReactDatePicker";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import {
  selectMaritalStatusTypeCodes,
  selectGenderTypeCodes,
  selectOccupationTypeCodes,
} from "../../../../appRootSlice";
import { convertUtcDateToDisplayString } from "../../../../utils/helpers/dateUtils";
import { updateValidation } from "../../../../rootValidationSlice";

const validationSchema = Yup.object().shape({
  birthdate: Yup.date().nullable().required("*Birth date is required"),
  maritalStatus: Yup.string().nullable().required("*Marital status is required"),
  gender: Yup.string().nullable().required("*Gender is required"),
});

const Editor: React.FC = () => {
  const dispatch = useDispatch();

  const selectedPersonalInformation = useSelector(selectSelectedPersonalInformation);
  const selectedQuoteRequest = useSelector(selectQuoteRequest);
  const maritalStatusTypeCodes = useSelector(selectMaritalStatusTypeCodes);
  const genderTypeCodes = useSelector(selectGenderTypeCodes);
  const occupationTypeCodes = useSelector(selectOccupationTypeCodes);

  const data: PersonalInformationViewModel = {
    entityId: selectedPersonalInformation ? selectedPersonalInformation.entityId : "",
    birthdate: convertUtcDateToDisplayString(selectedPersonalInformation?.birthdate),
    gender: selectedPersonalInformation?.gender,
    occupation: selectedPersonalInformation?.occupation,
    maritalStatus: selectedPersonalInformation?.maritalStatus,
  };

  const rootValidRef = useRef(null) as any;
  const validate = () =>
  {
    dispatch(updateValidation({formName: "PersonalInfoEditor", validationFail: Object.keys(rootValidRef.current.errors).length > 0 ? true : false}));
  };
  const onCancel = () => {
    dispatch(updateValidation({formName: "PersonalInfoEditor", validationFail: false}));
    dispatch(cancelEditInsuranceInformation());
  }

  const onSubmit = (
    values: PersonalInformationViewModel,
    helpers: FormikHelpers<PersonalInformationViewModel>
  ) => {
    const postData = {
      ...data,
      entityId: values.entityId,
      birthdate: values.birthdate,
      gender: values.gender,
      maritalStatus: values.maritalStatus,
      occupation: values.occupation,
    };

    dispatch(savePersonalInformation(selectedQuoteRequest.quoteRequestWipId as string, postData));
  };

  return (
    <Modal
      show={selectedPersonalInformation != undefined}
      onHide={onCancel}
      className="personal-information-editor"
      keyboard={false} 
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title> Edit Personal Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={data} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={rootValidRef} validate={validate}>
          {({
            touched,
            errors,
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Form.Group>
                <Form.Label htmlFor="birthdate">Birth Date</Form.Label>
                <div className={touched.birthdate && errors.birthdate ? "is-invalid" : ""}>
                  <ReactDatePicker
                    name="birthdate"
                    className={
                      touched.birthdate && errors.birthdate
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={handleChange}
                  />
                </div>
                <Form.Control.Feedback type="invalid">{errors.birthdate}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="gender">Gender</Form.Label>
                <Form.Control
                  className="custom-select"
                  as="select"
                  id="gender"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gender ?? ""}
                  isInvalid={touched.gender && errors.gender ? true : false}>
                  <option key="" value="" />
                  {genderTypeCodes &&
                    genderTypeCodes.map((t) => (
                      <option key={t.codeValue} value={t.codeValue ?? ""}>
                        {t.description}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.gender}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="maritalStatus">Marital Status</Form.Label>
                <Form.Control
                  className="custom-select"
                  as="select"
                  id="maritalStatus"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.maritalStatus ?? ""}
                  isInvalid={touched.maritalStatus && errors.maritalStatus ? true : false}>
                  <option key="" value="" />
                  {maritalStatusTypeCodes &&
                    maritalStatusTypeCodes.map((t) => (
                      <option key={t.codeValue} value={t.codeValue ?? ""}>
                        {t.description}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">{errors.maritalStatus}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="occupation">Occupation - <small><i>optional</i></small></Form.Label>
                <Form.Control
                  className="custom-select"
                  as="select"
                  id="occupation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.occupation ?? ""}
                  isInvalid={touched.occupation && errors.occupation ? true : false}>
                  <option key="" value="" />
                  {occupationTypeCodes &&
                    occupationTypeCodes.map((t) => (
                      <option key={t.codeValue} value={t.codeValue ?? ""}>
                        {t.description}
                      </option>
                    ))}
                </Form.Control>
              </Form.Group>
              <Form.Row className="modal-footer">
                <Button variant="secondary" type="button" onClick={onCancel}>
                  Cancel
                </Button>

                <Button variant="primary" type="submit" className="ml-2">
                  Submit
                </Button>
              </Form.Row>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default Editor;
