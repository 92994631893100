import { Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ConvictionViewModel } from "../../../../service/RequoteApiTypes";
import { selectConvictions, addConviction } from "./convictionSlice";
import ListItemView from "./ListItemView";
import { PlusSquareFill } from "react-bootstrap-icons";
import "./ListView.css";

interface Props {
  entityId: string;
}

const ListView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const convictions: ConvictionViewModel[] = useSelector(selectConvictions).filter(
    (e) => e.entityId == props.entityId
  );

  const onAddNew = () => {
    const newConviction = {
      entityId: props.entityId,
    } as ConvictionViewModel;

    dispatch(addConviction(newConviction));
  };

  return (
    <div className="convictions list-view">
      <Button variant="primary" size="sm" className="add-new-item" onClick={onAddNew}>
        <PlusSquareFill className="mr-2"></PlusSquareFill> Add New
      </Button>
      {convictions.length > 0 ? (
        <>
          <Table striped>
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Additional Details</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody>
              {convictions.map((c, index) => (
                <ListItemView key={c.convictionId} data={c} />
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <div className="no-data">No convictions</div>
      )}
    </div>
  );
};

export default ListView;
