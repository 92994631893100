import { Dispatch, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { HTTP, HttpAction, HttpError } from "./types";
import * as cacheService from "../../../service/cacheService";
import axios, { AxiosError } from "axios";
import { addError } from "../../notification/notificationSlice";
import { getAccessToken } from "../../../utils/helpers/apiUtils";
import helpers from "./../../../utils/helpers";

export const http: Middleware = ({ dispatch, getState }: MiddlewareAPI) =>
    (next: Dispatch) => async action => {
        const nextAction = next(action);

        if (action.type !== HTTP) {
            return nextAction;
        }

        const { promise, pending, resolve, reject, cacheKey, skipAuthorization, showDefaultErrors, showDefaultLoader } = (action as HttpAction<any>).meta;

        // A Default Loader can be set here
        if (showDefaultLoader === undefined || showDefaultLoader === true) {
            helpers.loader.show();
        }

        // TODO: Validate Auth Token If expired?
        // Use selector to check the token expiration
        //  re-authenticate If token is expired
        //  return nextAction;

        // Verify Cache
        if (cacheKey && cacheKey.length > 0 && cacheService.exists(cacheKey)) {
            resolve(cacheService.get(cacheKey));
            helpers.loader.hide();
            return nextAction;
        }

        // Attach default HTTP Headers like, Content-Type and Authorization etc.
        axios.defaults.headers.common["Content-Type"] = "application/json";

        if (skipAuthorization === undefined || skipAuthorization === false) {
            let token = await getAccessToken();
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        }

        pending && dispatch(pending());

        // Make HTTP call here
        promise().then(data => {
            // Hide Default Loader

            // Refresh/Update Cache
            if (cacheKey && cacheKey.length > 0) {
                cacheService.set(cacheKey, data);
            }

            resolve(data);
        }).catch((err: AxiosError) => {

            let error: HttpError = {
                code: 500,
                message: err.message,
                detail: err
            };

            if (err.response && typeof (err.response) !== "string") {
                error.code = (err.response.data || {}).status || (err.response.data || {}).code || err.response.status;
                error.message = (err.response.data || {}).detail || (err.response.data || {}).message || err.response.statusText || error.message;
                error.traceIdentifier = (err.response.data || {}).traceIdentifier
            } else if (err.request && err.request.status > 0) {
                error.code = err.request.status;
                error.message = err.request.statusText || error.message;
            }

            // Hide Default Loader

            // TODO: Call authenticate action If code was 401?
            if (error.code === 401) {
                // Re-authenticate
                return nextAction;
            }

            console.error("HTTP Middleware REPORTED ERROR", JSON.stringify(error));

            // Display Error
            if (showDefaultErrors !== false) {
                addError(error.code, error.message, err.response?.data?.stack || err.stack, err.config?.url ?? "", error.traceIdentifier)(dispatch, getState, {});
            }

            reject && reject(error);
        }).finally(() => {
            helpers.loader.hide();
        });

        return nextAction;
    }