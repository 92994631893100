import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HTTP, HttpAction, HttpError } from "../../app/middlewares/http/types";
import { AppThunk, RootState } from "../../app/store";
import * as referenceDataService from "../../service/referenceDataService";
import * as insuranceScoreService from "../../service/insuranceScoreService";
import {
  BrokerMetadata,
  InsuranceScoreModel,
} from "../../service/RequoteApiTypes";

interface InsuranceQuoteState {
  isLoading: boolean;
  isLoaded: boolean;
  error: string | null;
  insuranceScoreResponse: InsuranceScoreModel;
  brokers: BrokerMetadata[];
}

const initialState: InsuranceQuoteState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  insuranceScoreResponse: {
    birthDate: "",
    brokerNumber: "",
    firstName: "",
    lastName: "",
    officeNumber: "",
    policyNumber: "",
    postalCode: "",
    isCreditScoreHit: null,
    creditScoreMessage: "",
    uniqueId: null,
  },
  brokers: [],
};

export const insuranceScoreSlice = createSlice({
  name: "insurance-score",
  initialState,
  reducers: {
    loading: (state) => {
      state.isLoading = true;
      state.isLoaded = false;
    },
    loadingComplete: (state) => {
      state.isLoading = false;
      state.isLoaded = true;
    },
    brokersLoaded: (state, action: PayloadAction<BrokerMetadata[]>) => {
      state.brokers = action.payload;
    },

    insuranceScoreRequestCompleted: (
      state,
      action: PayloadAction<InsuranceScoreModel>
    ) => {
      state.insuranceScoreResponse = action.payload;
    },
    resetState: (state) => {
      state.isLoading = false;
      state.isLoaded = false;
    },
  },
});

export const {
  loading,
  loadingComplete,
  brokersLoaded,
  insuranceScoreRequestCompleted,
  resetState,
} = insuranceScoreSlice.actions;

// export const loadBrokers = createAsyncThunk<BrokerMetadata[]>("brokersLoaded", async (arg, thunkAPI) => {
//   const response = await brokerService.getBrokers();
//   return response;
// })

export const loadBrokers = (): AppThunk<void> => (dispatch) => {
  const action: HttpAction<BrokerMetadata[]> = {
    type: HTTP,
    meta: {
      promise: () => referenceDataService.getBrokers(),
      resolve: (data: BrokerMetadata[]) => {
        dispatch(brokersLoaded(data));
      },
      cacheKey: "brokers-metadata-cache"
    }
  };

  dispatch(action);
};

export const requestInsuranceScoreAsync = (
  insuranceScoreModel: InsuranceScoreModel,
  callback?: (model: InsuranceScoreModel) => void
): AppThunk<void> => async (dispatch) => {
  dispatch(loading());

  const action: HttpAction<InsuranceScoreModel> = {
    type: HTTP,
    meta: {
      promise: () => insuranceScoreService.submitInsuranceScoreRequest(insuranceScoreModel),
      resolve: (response: InsuranceScoreModel) => {
        const data = {
          firstName: response.firstName,
          lastName: response.lastName,
          birthDate: response.birthDate,
          policyNumber: response.policyNumber,
          postalCode: response.postalCode,
          brokerNumber: response.brokerNumber,
          officeNumber: response.officeNumber,
          isCreditScoreHit: response.isCreditScoreHit,
          creditScoreMessage: response.creditScoreMessage,
          uniqueId: response.uniqueId,
        } as InsuranceScoreModel;

        dispatch(insuranceScoreRequestCompleted(data));

        callback && callback(data);

        dispatch(loadingComplete());
      },
      reject: (error: HttpError) => {
        callback && callback(insuranceScoreModel);
        dispatch(loadingComplete());
      }
    }
  };

  return dispatch(action);
};

export const selectLoadingState = (state: RootState) => {
  return {
    isLoading: state.insuranceScore.isLoading,
    isLoaded: state.insuranceScore.isLoaded,
    error: state.insuranceScore.error,
  };
};

export const selectInsuranceScore = (state: RootState): InsuranceScoreModel =>
  state.insuranceScore.insuranceScoreResponse;
export const selectBrokers = (state: RootState): BrokerMetadata[] =>
  state.insuranceScore.brokers;

export default insuranceScoreSlice.reducer;
