import { Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { SuspensionInfoModel } from "../../../../service/RequoteApiTypes";
import { selectSuspensions, addSuspension } from "./suspensionSlice";
import ListItemView from "./ListItemView";
import { PlusSquareFill } from "react-bootstrap-icons";

interface Props {
  entityId: string;
}

const ListView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const suspensions: SuspensionInfoModel[] = useSelector(selectSuspensions).filter(
    (e) => e.entityId === props.entityId
  );

  const onAddNew = () => {
    const newSuspension = {
      entityId: props.entityId,
    } as SuspensionInfoModel;

    dispatch(addSuspension(newSuspension));
  };

  return (
    <div className="suspensions list-view">
      <Button variant="primary" size="sm" className="add-new-item" onClick={onAddNew}>
        <PlusSquareFill className="mr-2"></PlusSquareFill> Add New
      </Button>
      {suspensions.length > 0 ? (
        <>
          <Table striped>
            <thead>
              <tr>
                <th>Start Date</th>
                <th>Reinstatement Date</th>
                <th>Description</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody>
              {suspensions.map((l) => (
                <ListItemView key={l.suspensionId} data={l} />
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <div className="no-data">No suspension information</div>
      )}
    </div>
  );
};

export default ListView;
