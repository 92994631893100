import * as React from "react";
import { Spinner } from "react-bootstrap";
import ReactDOM from "react-dom";

const LOADER_IDENTIFIER = "APP_LOADER";
export function show(): void {
  const container = document.createElement("div");
  container.id = LOADER_IDENTIFIER;
  container.className = "modal-backdrop show loader-wrapper";
  document.body.appendChild(container);

  const spinner = React.createElement(Spinner, { animation: "grow", className: "loader-icon", variant: "primary" });
  ReactDOM.render(spinner, container);
}


export function hide(): void {
  const container = document.getElementById(LOADER_IDENTIFIER);

  if (!container) return;

  ReactDOM.unmountComponentAtNode(container);
  container.parentNode && container.parentNode.removeChild(container)
}