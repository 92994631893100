import { ConvictionViewModel } from "./RequoteApiTypes";
import axios, { AxiosResponse } from "axios";
import CONSTANTS from "../constants";

export async function saveConviction(
  quoteRequestWipId: string,
  request: ConvictionViewModel
): Promise<ConvictionViewModel> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.CONVICTION.SAVE(quoteRequestWipId, request.entityId);

  const response = await axios.post<ConvictionViewModel, AxiosResponse<ConvictionViewModel>>(
    url,
    request
  );

  return response.data;
}

export async function deleteConviction(
  quoteRequestWipId: string,
  entityId: string,
  id: string
): Promise<void> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.CONVICTION.DELETE(quoteRequestWipId, entityId, id);

  const response = await axios.delete(url);

  return response.data;
}
