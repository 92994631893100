import { InsuranceScoreModel } from "./RequoteApiTypes";
import axios, { AxiosResponse } from "axios";
import CONSTANTS from "../constants";

export async function submitInsuranceScoreRequest(request: InsuranceScoreModel): Promise<InsuranceScoreModel> {

    var response = await axios.post<InsuranceScoreModel, AxiosResponse<InsuranceScoreModel>>(
        window.REACT_APP_API_BASEURL + CONSTANTS.API.INSURANCE_SCORE.SUBMIT_INSURANCE_SCORE_REQUEST,
        request
    );

    return response.data;
}
