import { GridStepOverrideModel } from "./RequoteApiTypes";
import axios, { AxiosResponse } from "axios";
import CONSTANTS from "../constants";

export async function saveGridStepOverride(
  quoteRequestWipId: string,
  request: GridStepOverrideModel
): Promise<GridStepOverrideModel> {
  const url =
    window.REACT_APP_API_BASEURL +
    CONSTANTS.API.GRID_STEP_OVERRIDE.SAVE(quoteRequestWipId, request.entityId);

  const response = await axios.post<GridStepOverrideModel, AxiosResponse<GridStepOverrideModel>>(
    url,
    request
  );

  return response.data;
}
