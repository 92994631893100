import React from "react";
import { Nav } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectCompareButtonFeatureFlagEnabled } from "../../../appRootSlice";
interface LinkProps {
  reQuoteId: string | null;
  orgQuoteId: string | null;
  displayMode: string | null;
}

interface PresentationProps {
  quoteInquiryUrl: string;
  reQuoteId: string | null;
  displayMode: string | null;
  orgQuoteId: string | null;
}

const QuoteInquiryLink: React.FC<PresentationProps> = ({
  quoteInquiryUrl,
  displayMode
}) => {
  const isCompareButtonEnabled = useSelector(selectCompareButtonFeatureFlagEnabled);
  const launchCompare =() => {
    window.open(quoteInquiryUrl, "_blank");
  }
  return (
    <>
    {
     isCompareButtonEnabled ? (displayMode == "button" ? 
      <Button
        variant="primary"
        title="Compare with previous quote"
        onClick={() => launchCompare()}>
          Compare
      </Button> :
      <Nav className="ml-auto">
        <Nav.Link onClick={() => launchCompare()}>Compare</Nav.Link>
      </Nav>) : ""
    }
    </>
  );
};

const QuoteInquiryCompareLinkComponent: React.FC<LinkProps> = ({ reQuoteId, orgQuoteId, displayMode }) => {
  const quoteInquiryUrl = (window.REACT_APP_QUOTE_INQUIRY_COMPARE_URL ?? "") + `Compare?quoteRefId1=${orgQuoteId}&quoteRefId2=${reQuoteId}`;
  return (
    <QuoteInquiryLink
      reQuoteId={reQuoteId}
      orgQuoteId={orgQuoteId}
      displayMode={displayMode}
      quoteInquiryUrl={quoteInquiryUrl}
    />
  );
};

export default QuoteInquiryCompareLinkComponent;
