import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
import { store } from "./app/store";
import { Provider } from "react-redux";
import ErrorBoundary from "./components/ErrorBoundary";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ErrorNotification from "./app/notification/ErrorNotification";
import { initializeMsalInstance } from "./utils/helpers/initializeMsal";
import CONSTANTS from "./constants";

declare global {
  interface Window {
    REACT_APP_API_BASEURL: string;
    REACT_APP_APPINSIGHTS_INSTR_KEY: string;
    REACT_APP_ENVIRONMENT: string;
    REACT_APP_QUOTE_INQUIRY_URL: string;
    REACT_APP_QUOTE_INQUIRY_COMPARE_URL: string;
    REACT_APP_AD_CLIENT_ID: string;
    REACT_APP_AD_AUTHORITY: string;
    REACT_APP_AD_AUTH_SCOPES: string[];
    REACT_APP_AD_REDIRECT_URL: string;
    SPA_CONFIG_LOADED: boolean;
  }
}

fetch(CONSTANTS.API.CONFIGURATION.GET_CONFIGURATIONS)
  .then((response) => {
    return response.json();
  })
  .then((data) => {
    window.REACT_APP_API_BASEURL = data.REACT_APP_API_BASEURL ?? "/v1/requoteui/";
    window.REACT_APP_APPINSIGHTS_INSTR_KEY = data.REACT_APP_APPINSIGHTS_INSTR_KEY;
    window.REACT_APP_ENVIRONMENT = data.REACT_APP_ENVIRONMENT;
    window.REACT_APP_QUOTE_INQUIRY_URL = data.REACT_APP_QUOTE_INQUIRY_URL;
    window.REACT_APP_QUOTE_INQUIRY_COMPARE_URL = data.REACT_APP_QUOTE_INQUIRY_COMPARE_URL;
    window.REACT_APP_AD_CLIENT_ID = data.REACT_APP_AD_CLIENT_ID;
    window.REACT_APP_AD_AUTHORITY = data.REACT_APP_AD_AUTHORITY;
    window.REACT_APP_AD_AUTH_SCOPES = data.REACT_APP_AD_AUTH_SCOPES ?? [];
    window.REACT_APP_AD_REDIRECT_URL = data.REACT_APP_AD_REDIRECT_URL;

    window.SPA_CONFIG_LOADED = true;

    console.log("Config loaded...");

    initializeMsalInstance();

    ReactDOM.render(
      <React.StrictMode>
        <ErrorBoundary>
          <Provider store={store}>
            <BrowserRouter>
              <ErrorNotification />
              <App />
            </BrowserRouter>
          </Provider>
        </ErrorBoundary>
      </React.StrictMode>,
      document.getElementById("root")
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
