import { ClaimModel, EntityModel, VehicleModel } from "../../../../service/RequoteApiTypes";
import DisplayDate from "./../../../../components/DateDisplay";
import {
  selectClaimCauseOfLossPerilTypeCodes,
  selectClaimCauseOfLossCircumstanceTypeCodes,
  selectPayoutAmountCoverageTypeCodes,
  selectCoverageTypeCodes
} from "../../../../appRootSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectEntities } from "../entity/entitySlice";
import { selectVehicles } from "../vehicle/vehicleSlice";
import { deleteClaim, editClaim } from "./claimSlice";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { PencilSquare, TrashFill } from "react-bootstrap-icons";
import helpers from "../../../../utils/helpers";
import { selectQuoteRequest } from "../../quoteRequestSlice";

interface Props {
  data: ClaimModel;
}

const ListItemView: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const selectedQuoteRequest = useSelector(selectQuoteRequest);

  const onEdit = (data: ClaimModel) => {
    dispatch(editClaim(data));
  };

  const onDelete = (data: ClaimModel) => {
    helpers.confirm({
      header: "Confirm Delete",
      body: "Are you sure you want to delete it?",
      onConfirm: () => {
        dispatch(deleteClaim(selectedQuoteRequest.quoteRequestWipId as string, data));
      },
    });
  };

  const claimCauseOfLossPerilTypeCodes = useSelector(selectClaimCauseOfLossPerilTypeCodes);
  const claimCauseOfLossCircumstanceTypeCodes = useSelector(
    selectClaimCauseOfLossCircumstanceTypeCodes
  );

  const coverageTypeCodes = useSelector(selectPayoutAmountCoverageTypeCodes);
  const allCoverageTypeCodes = useSelector(selectCoverageTypeCodes);
  const relationship = props.data.relationship;

  const entity: EntityModel | undefined = useSelector(selectEntities).find(
    (e) => e.entityId === (relationship != null ? relationship[0].entity_id : "")
  );

  const vehicle: VehicleModel | undefined = useSelector(selectVehicles).find(
    (e) => e.assetId === (relationship != null ? relationship[0].asset_id : "")
  );

  const claimCauseOfLossPerilTypeCode = claimCauseOfLossPerilTypeCodes.find(
    (f) => f.codeValue === props.data.causeOfLossPerilCode?.toString()
  )?.description;

  const claimCauseOfLossCircumstanceTypeCode = claimCauseOfLossCircumstanceTypeCodes.find(
    (f) => f.codeValue === props.data.causeOfLossCircumstanceCode?.toString()
  );

  const currencyFormat = (num: number | null | undefined) => {
    // return "$" + num ? num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") : "0";
    return "$" + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  return (
    <tr>
      <td>
        <DisplayDate value={props.data.lossDate} />
      </td>
      <td>
        {claimCauseOfLossCircumstanceTypeCode
          ? claimCauseOfLossCircumstanceTypeCode.codeValue === "animal"
            ? "Collision with Animal"
            : claimCauseOfLossCircumstanceTypeCode.description
          : claimCauseOfLossPerilTypeCode}
      </td>
      <td>{props.data.accidentResponsiblePercentage}%</td>
      <td>
        <ul>
          {props.data.claimant?.map((m, index) => {
            return (
              <li key={index}>
                {
                  coverageTypeCodes.find((f) => f.codeValue === m.coverageTypeCode?.toString())
                    ?.description ?? m.coverageTypeCode?.toString()
                }
                : {m.amount ? currencyFormat(m.amount) : m.amount === 0 ? "0" : "-"}
              </li>
            );
          })}
          {props.data.thirdPartyClaim?.map((tp, index) => {
            return (
              <li key={index}>
                {
                  allCoverageTypeCodes.find((f) => f.codeValue === "tpdcpd")?.description
                }
              </li>
            );
          })}
        </ul>
      </td>
      <td>{vehicle ? vehicle.modelYear + " " + vehicle.manufacturer + " " + vehicle.model : ""}</td>
      <td>{entity?.displayName}</td>
      <td>
        <div>
          <OverlayTrigger placement="top" overlay={<Tooltip id="EditEvent">Edit Event</Tooltip>}>
            <Button
              variant="secondary"
              size="sm"
              title="Edit"
              onClick={() => onEdit(props.data)}
              data-testid="EditEvent">
              <PencilSquare></PencilSquare>
            </Button>
          </OverlayTrigger>

          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="DeleteEvent">Delete Event</Tooltip>}>
            <Button
              variant="danger"
              data-testid="DeleteEvent"
              size="sm"
              className="ml-2"
              title="Delete"
              onClick={() => onDelete(props.data)}>
              <TrashFill></TrashFill>
            </Button>
          </OverlayTrigger>
        </div>
      </td>
    </tr>
  );
};

export default ListItemView;
