import { Badge } from "react-bootstrap";
import { selectPolicyHistoryLineOfBusinessTypes } from "../../../../appRootSlice";
import { Formik } from "formik";
import { ToggleButtonGroup, ToggleButton, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { PolicyHistoryLineOfBusinessTypeCode, PolicyHistoryModel } from "service/RequoteApiTypes";
import * as Yup from "yup";
import { savePolicyHistory, deletePolicyHistory } from "./policyHistorySlice";
import { selectQuoteRequest } from "../../quoteRequestSlice";

interface Props {
  entityId: string;
  policyHistoryNonAuto: PolicyHistoryModel[];
}

const MultiLineEditor: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const selectedQuoteRequest = useSelector(selectQuoteRequest);
  const lineOfBusinessTypes = useSelector(selectPolicyHistoryLineOfBusinessTypes).filter(
    (f) => f.codeValue === "farm" || f.codeValue === "habl"
  );

  const multilineRecords = props.policyHistoryNonAuto.filter(
    (e) =>
      (e.lineOfBusiness === "farm" ||
        (e.lineOfBusiness === "habl" && (e.detailedLineOfBusiness === "home" || 
                                         e.detailedLineOfBusiness === "" || 
                                         e.detailedLineOfBusiness === null))) &&
      e.insuranceCompanyCode === "sgi"
  );

  const policyTypeImplied = props.policyHistoryNonAuto.filter(
    (e) =>
        (e.lineOfBusiness === "habl" && 
        (e.detailedLineOfBusiness === "" || 
        e.detailedLineOfBusiness === null)) &&
      e.insuranceCompanyCode === "sgi"
  ).length > 0;
  
  const isMultiLine = () => {
    return multilineRecords.length > 0;
  };

  const onSubmit = (values: PolicyHistoryModel) => {
    const postData = {
      ...data,
      lineOfBusiness: values.lineOfBusiness as PolicyHistoryLineOfBusinessTypeCode,
      detailedLineOfBusiness: values.detailedLineOfBusiness,
    };

    dispatch(savePolicyHistory(selectedQuoteRequest?.quoteRequestWipId as string, postData));
  };

  const data: PolicyHistoryModel = {
    entityId: props.entityId,
    policyHistoryId: multilineRecords[0]?.policyHistoryId ?? "",
    cancellationReason: null,
    insuranceCompanyCode: "sgi",
    province: null,
    lineOfBusiness: multilineRecords[0]?.lineOfBusiness ?? "habl",
    detailedLineOfBusiness: multilineRecords[0]?.detailedLineOfBusiness ?? "home",
    policyHolderIndicator: null,
    driverIndicator: null,
    inceptionDate: null,
    terminationDate: null,
    policyStatus: "other",
    transferReason: "",
  };

  const validationSchema = Yup.object().shape({});

  return (
    <Formik initialValues={data} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, touched, errors, handleBlur, handleSubmit, handleChange, setFieldValue }) => (
        <Form inline className="mt-5" onSubmit={handleSubmit} autoComplete="off">
          <Form.Group>
            <Form.Label column xs="auto" htmlFor="isMultiLineToggle">
              Multi-Line Client
            </Form.Label>
            <Col xs="auto">
              <ToggleButtonGroup
                type="radio"
                id="isMultiLineToggle"
                name="isMultiLineToggle"
                value={isMultiLine() ? 1 : 0}
                onChange={(value) => {
                  if (value === 1) {
                    handleSubmit();
                  } else {
                    multilineRecords.forEach((m) => {
                      dispatch(
                        deletePolicyHistory(selectedQuoteRequest?.quoteRequestWipId as string, m)
                      );
                    });
                  }
                }}>
                <ToggleButton value={1}>Yes</ToggleButton>
                <ToggleButton value={0}>No</ToggleButton>
              </ToggleButtonGroup>
            </Col>
          </Form.Group>
          {isMultiLine() && (
            <>
              <Form.Group>
                <Form.Label htmlFor="lineOfBusiness">Policy Type</Form.Label>
                <Col xs={2}>
                  <Form.Control
                    id="lineOfBusiness"
                    name="lineOfBusiness"
                    value={values.lineOfBusiness ?? ""}
                    className="custom-select"
                    as="select"
                    onChange={(e) => {
                      setFieldValue("lineOfBusiness", e.target.value);
                      setFieldValue(
                        "detailedLineOfBusiness",
                        e.target.value === "habl" ? "home" : null
                      );
                      handleSubmit();
                    }}>
                    {lineOfBusinessTypes &&
                      lineOfBusinessTypes.map((t) => (
                        <option key={t.codeValue} value={t.codeValue ?? ""}>
                          {t.description}
                        </option>
                      ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              {policyTypeImplied ? 
                <Form.Group>
                  <Col xs={2}>
                    <Badge variant="warning" className="font-weight-normal">** Policy Type was not entered by broker **</Badge>
                  </Col>
                </Form.Group> : ""}
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default MultiLineEditor;
