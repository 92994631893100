import React, { useState } from "react";
import { Card, Accordion } from "react-bootstrap";
import { ChevronRight, ChevronDown } from "react-bootstrap-icons";
import { EntityModel } from "../../../../../service/RequoteApiTypes";
import InsuranceEventListView from "./../../insuranceEvent/ListView";
import InsuranceDetailsListView from "./../../policyHistory/ListView";
import ConvictionsListView from "./../../conviction/ListView";
import LicencesListView from "./../../license/ListView";
import SuspensionsListView from "./../../suspension/ListView";
import classNames from "classnames";
import { useSelector } from "react-redux";
import {
  selectLicenseInfoEnabled,
  selectSuspensionInfoEnabled,
  selectIsGridStepOverrideEnabled,
} from "./../../../../../appRootSlice";
import { selectSelectedTraining } from "./../../license/licenseSlice";
import { isValidationPending } from "../../../../../rootValidationSlice";
import GridView from "../../grid-override/GridStepOverrideView";
import CONSTANTS from "./../../../../../constants";
import "./ViewDriver.scss";

const ViewDriver: React.FC<{ entity: EntityModel }> = (props) => {
  const isLicenseInfoEnabled = useSelector(selectLicenseInfoEnabled);
  const isSuspensionInfoEnabled = useSelector(selectSuspensionInfoEnabled);
  const isGridStepOverrideEnabled = useSelector(selectIsGridStepOverrideEnabled);
  const selectedTraining = useSelector(selectSelectedTraining);
  const gridStepInvalid = useSelector(isValidationPending("GridStepEditor"));
 
  const [selectedEventKeys, setSelectedEventKeys] = useState([""]);

  const entity_id = props.entity.entityId ?? "";

  const isDriver: boolean = (props.entity.roles || []).indexOf(CONSTANTS.ENTITY_ROLES.DRIVER) > -1;

  const handleAccordianSelection = (eventKey: string) => {
    const existing = [...selectedEventKeys];
    if (existing.indexOf(eventKey) > -1) {
      existing.splice(existing.indexOf(eventKey), 1);
    } else {
      existing.push(eventKey);
    }

    setSelectedEventKeys(existing);
  };

  const renderConvictions = () => {
    if (!isDriver) {
      return null;
    }

    return (
      <Accordion>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="convictions"
            className="bg-white border-0"
            onClick={() => handleAccordianSelection("convictions")}>
            <span
              className={classNames("toggle-icon", {
                expanded: selectedEventKeys.indexOf("convictions") > -1,
              })}>
              <ChevronRight
                className={classNames({ "d-none": selectedEventKeys.indexOf("convictions") > -1 })}
              />
              <ChevronDown
                className={classNames({ "d-none": selectedEventKeys.indexOf("convictions") == -1 })}
              />
            </span>
            <h4>Convictions</h4>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="convictions">
            <Card.Body>
              <ConvictionsListView entityId={entity_id} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  const renderInsuranceInfo = () => {
    return (
      <Accordion>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="policyHistory"
            className="bg-white border-0"
            onClick={() => handleAccordianSelection("policyHistory")}>
            <span
              className={classNames("toggle-icon", {
                expanded: selectedEventKeys.indexOf("policyHistory") > -1,
              })}>
              <ChevronRight
                className={classNames({
                  "d-none": selectedEventKeys.indexOf("policyHistory") > -1,
                })}
              />
              <ChevronDown
                className={classNames({
                  "d-none": selectedEventKeys.indexOf("policyHistory") == -1,
                })}
              />
            </span>
            <h4>Insurance</h4>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="policyHistory">
            <Card.Body>
              <InsuranceDetailsListView entityId={entity_id} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  const renderLapsesAndCancellations = () => {
    return (
      <Accordion>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="insuranceEvents"
            className="bg-white border-0"
            onClick={() => handleAccordianSelection("insuranceEvents")}>
            <span
              className={classNames("toggle-icon", {
                expanded: selectedEventKeys.indexOf("insuranceEvents") > -1,
              })}>
              <ChevronRight
                className={classNames({
                  "d-none": selectedEventKeys.indexOf("insuranceEvents") > -1,
                })}
              />
              <ChevronDown
                className={classNames({
                  "d-none": selectedEventKeys.indexOf("insuranceEvents") == -1,
                })}
              />
            </span>
            <h4>Lapses and Cancellations</h4>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="insuranceEvents">
            <Card.Body>
              <InsuranceEventListView entityId={entity_id} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  const renderLicenses = () => {
    if (!isLicenseInfoEnabled) {
      return null;
    }

    if (!isDriver) {
      return null;
    }

    const invalid =
      selectedTraining?.trainingTypeCode == "yes" &&
      (selectedTraining?.date || selectedTraining?.originalDate || "")?.length == 0;

    return (
      <Accordion>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="licences"
            className="bg-white border-0"
            onClick={() => handleAccordianSelection("licences")}>
            <span
              className={classNames("toggle-icon", {
                expanded: selectedEventKeys.indexOf("licences") > -1,
              })}>
              <ChevronRight
                className={classNames({ "d-none": selectedEventKeys.indexOf("licences") > -1 })}
              />
              <ChevronDown
                className={classNames({ "d-none": selectedEventKeys.indexOf("licences") == -1 })}
              />
            </span>
            <h4>Licences</h4>
            {invalid ? <span className="invalid-warning">Requires attention</span> : null}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="licences">
            <Card.Body>
              <LicencesListView entityId={entity_id} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  const renderSuspensions = () => {
    if (!isSuspensionInfoEnabled) {
      return null;
    }

    if (!isDriver) {
      return null;
    }

    return (
      <Accordion>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="suspensions"
            className="bg-white border-0"
            onClick={() => handleAccordianSelection("suspensions")}>
            <span
              className={classNames("toggle-icon", {
                expanded: selectedEventKeys.indexOf("suspensions") > -1,
              })}>
              <ChevronRight
                className={classNames({ "d-none": selectedEventKeys.indexOf("suspensions") > -1 })}
              />
              <ChevronDown
                className={classNames({ "d-none": selectedEventKeys.indexOf("suspensions") == -1 })}
              />
            </span>
            <h4>Suspensions</h4>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="suspensions">
            <Card.Body>
              <SuspensionsListView entityId={entity_id} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  const renderGridInfo = () => {
    if (!isGridStepOverrideEnabled) {
      return null;
    }

    if (!isDriver) {
      return null;
    }

    return (
      <Accordion>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="grid"
            className="bg-white border-0"
            onClick={() => handleAccordianSelection("grid")}>
            <span
              className={classNames("toggle-icon", {
                expanded: selectedEventKeys.indexOf("grid") > -1,
              })}>
              <ChevronRight
                className={classNames({ "d-none": selectedEventKeys.indexOf("grid") > -1 })}
              />
              <ChevronDown
                className={classNames({ "d-none": selectedEventKeys.indexOf("grid") == -1 })}
              />
            </span>
            <h4>Grid</h4>
            {gridStepInvalid ? <span className="invalid-warning">Requires attention</span> : null}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="grid">
            <Card.Body>
              <GridView entityId={entity_id} />
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
  };

  return (
    <div className="driver-view">
      {renderLicenses()}

      {renderSuspensions()}

      {renderConvictions()}

      {renderInsuranceInfo()}

      {renderGridInfo()}

      {renderLapsesAndCancellations()}
    </div>
  );
};

export default ViewDriver;
