import {
  QuoteRequestWipModel,
  QuoteReferenceModel,
  QuoteRequestWipSummaryModel,
} from "./RequoteApiTypes";
import axios from "axios";
import CONSTANTS from "../constants";

export async function get(
  quoteRequestWipId: string
): Promise<QuoteRequestWipModel | null> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.QUOTE_REQUEST_WIP.GET(quoteRequestWipId);

  const response = await axios.get<QuoteRequestWipModel | null>(url);

  return response.data;
}

export async function create(quoteRefId: string): Promise<QuoteRequestWipSummaryModel> {
  const url = `${window.REACT_APP_API_BASEURL + CONSTANTS.API.QUOTE_REQUEST_WIP.CREATE}?quoteRefId=${encodeURIComponent(
    "" + quoteRefId
  )}`;

  const body = null;

  const response = await axios.post<QuoteRequestWipSummaryModel>(url, body);

  return response.data;
}

export async function submit(quoteRequestWipId: string): Promise<QuoteReferenceModel> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.QUOTE_REQUEST_WIP.SUBMIT(quoteRequestWipId);

  const body = {};

  const response = await axios.post<QuoteReferenceModel>(url, body);

  return response.data;
}

export async function deleteWip(quoteRequestWipId: string): Promise<void> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.QUOTE_REQUEST_WIP.DELETE(quoteRequestWipId);

  const response = await axios.delete(url);

  return response.data;
}
