import { Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LicenseInfoModel } from "../../../../service/RequoteApiTypes";
import { selectLicenses, addLicense, selectSelectedTraining } from "./licenseSlice";
import ListItemView from "./ListItemView";
import { PlusSquareFill } from "react-bootstrap-icons";
import TrainingEditor from "./components/TrainingEditor";
import "./ListView.scss";

interface Props {
  entityId: string;
}

const ListView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const licenses: LicenseInfoModel[] = useSelector(selectLicenses).filter(
    (e) => e.entityId === props.entityId
  );

  const selectedTraining = useSelector(selectSelectedTraining);

  const onAddNew = () => {
    const newLicense = {
      entityId: props.entityId,
    } as LicenseInfoModel;

    dispatch(addLicense(newLicense));
  };

  return (
    <div className="licenses list-view">
      <Button variant="primary" size="sm" className="add-new-item" onClick={onAddNew}>
        <PlusSquareFill className="mr-2"></PlusSquareFill> Add New
      </Button>
      {licenses.length > 0 ? (
        <>
          <Table striped>
            <thead>
              <tr>
                <th>Country</th>
                <th>Province</th>
                <th>Class</th>
                <th>Number</th>
                <th>Date Licensed</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody>
              {licenses.map((l) => (
                <ListItemView key={l.licenseId} data={l} deletable={licenses.length > 1} />
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <div className="no-data">No license information</div>
      )}
      {selectedTraining ? (
        <TrainingEditor entityId={props.entityId} className="training-editor mt-5" />
      ) : null}
    </div>
  );
};

export default ListView;
