import { useState } from "react";
import { Modal, Button } from "react-bootstrap";

export type ConfirmProps = {
  header?: string;
  body: string;
  onConfirm: () => void;
  onCancel?: () => void;
};

const Confirm: React.FC<ConfirmProps> = (props: ConfirmProps) => {
  const [show, setShow] = useState(true);

  const onConfirm = () => {
    setShow(false);
    props.onConfirm();
  };

  const onCancel = () => {
    setShow(false);
    props.onCancel && props.onCancel();
  };

  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{props.header ?? "Confirm"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{props.body}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          Close
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Confirm;
