import { useDispatch, useSelector } from "react-redux";
import { Toast, Badge } from "react-bootstrap";
import { selectNotifications, removeNotification } from "./notificationSlice";
import {
  ExclamationTriangleFill,
  XCircleFill,
  CheckCircleFill,
  InfoCircleFill,
} from "react-bootstrap-icons";
import "./ErrorNotification.css";
import { NotificationViewModel } from "./model";

const ErrorNotification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotifications);

  const onToastClose = (notificationId: number) => {
    dispatch(removeNotification(notificationId));
  };

  if (notifications.length === 0) {
    return <></>;
  }

  const SeverityIcon = (notification: NotificationViewModel) => {
    switch (notification.type) {
      case "error":
        return <XCircleFill />;
      case "success":
        return <CheckCircleFill />;
      case "warning":
        return <ExclamationTriangleFill />;
      default:
        return <InfoCircleFill />;
    }
  };

  const showDetailedError = () => process.env.NODE_ENV === "development";

  return (
    <div className="notification-wrapper">
      {notifications.map((notification) => (
        <Toast
          key={notification.id}
          autohide={notification.type === "info"}
          delay={notification.type === "info" ? 3000 : 0}
          onClose={() => onToastClose(notification.id)}
          className={notification.type}>
          <Toast.Header>
            {SeverityIcon(notification)}
            <div className="message">
              <span>{notification.message}</span>
              {notification.traceIdentifier ? (
                <small>
                  <code>TRACE ID: {notification.traceIdentifier}</code>
                </small>
              ) : null}
              {notification.requestUrl && showDetailedError() ? (
                <small>
                  <code>REQUEST: {notification.requestUrl}</code>
                </small>
              ) : null}
            </div>
            {notification.type === "error" && notification.code !== 200 ? (
              <small>
                <Badge variant="secondary">{notification.code}</Badge>
              </small>
            ) : null}
          </Toast.Header>
          {(notification.body && notification.type === "error" && showDetailedError()) ||
          (notification.body && notification.type !== "error") ? (
            <Toast.Body>{notification.body}</Toast.Body>
          ) : null}
        </Toast>
      ))}
    </div>
  );
};

export default ErrorNotification;
