import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { QuoteRequestWipModel } from "service/RequoteApiTypes";
import { resumeWorkInProgress } from "../wip-list/wipListSlice";
import CONSTANTS from "./../../constants";

export interface NavigationItem {
    title: string;
    url?: string;
    subItems: NavigationItem[];
}

interface NavigationState {
    collapsed: boolean,
    navItems: NavigationItem[]
}

export const navigationSlice = createSlice({
    name: "navigationSlice",
    initialState: {
        collapsed: false,
        navItems: [] as NavigationItem[]
    } as NavigationState,
    reducers: {
        expand: (state: NavigationState) => {
            state.collapsed = false;
        },
        collapse: (state: NavigationState) => {
            state.collapsed = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resumeWorkInProgress, (state: NavigationState, action: PayloadAction<QuoteRequestWipModel | null>) => {

            state.navItems = [];

            const resolve = (role: string): NavigationItem[] => {
                return (action.payload?.entities || [])
                    .filter(e => e.displayName && e.roles && e.roles.indexOf(role) > -1)
                    .map(e => ({
                        title: e.displayName as string,
                        url: `${e.entityId}`,
                        subItems: []
                    }));
            };

            const items = [];
            items.push({ title: "Applicants", subItems: resolve(CONSTANTS.ENTITY_ROLES.APPLICANT) });
            items.push({ title: "Drivers", subItems: resolve(CONSTANTS.ENTITY_ROLES.DRIVER) });

            state.navItems = items;
        });
    },
});

export const isNavigationCollapsed = (rootState: RootState): boolean => rootState.navigation.collapsed;

export const selectNavItems = (rootState: RootState) => rootState.navigation.navItems;

export const { collapse, expand } = navigationSlice.actions;

export default navigationSlice.reducer;