import { LicenseInfoModel, LicenseTrainingInfoModel } from "./RequoteApiTypes";
import axios, { AxiosResponse } from "axios";
import CONSTANTS from "../constants";

export async function saveLicense(
  quoteRequestWipId: string,
  request: LicenseInfoModel
): Promise<LicenseInfoModel> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.LICENSE.SAVE(quoteRequestWipId, request.entityId);

  const response = await axios.post<LicenseInfoModel, AxiosResponse<LicenseInfoModel>>(
    url,
    request
  );

  return response.data;
}

export async function deleteLicense(
  quoteRequestWipId: string,
  entityId: string,
  id: string
): Promise<void> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.LICENSE.DELETE(quoteRequestWipId, entityId, id);

  const response = await axios.delete(url);

  return response.data;
}


export async function saveLicenseTraining(
  quoteRequestWipId: string,
  request: LicenseTrainingInfoModel
): Promise<LicenseTrainingInfoModel> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.LICENSE.TRAINING.SAVE(quoteRequestWipId, request.entityId);

  const response = await axios.post<LicenseTrainingInfoModel, AxiosResponse<LicenseTrainingInfoModel>>(
    url,
    request
  );

  return response.data;
}