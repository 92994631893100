import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../app/store";
import { selectReferenceDataCategory, loadReferenceDataCategory } from "../appRootSlice";

interface Props {
  categoryName: string;
  value?: string | null;
}

const CodeValue: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const category =
    useSelector((state: RootState) => selectReferenceDataCategory(state, props.categoryName)) ?? [];

  useEffect(() => {
    dispatch(loadReferenceDataCategory(props.categoryName));
  }, [props.categoryName]);

  const codeValue = Array.isArray(category)
    ? category.find((x) => x.codeValue === props.value)
    : null;

  return <>{codeValue ? codeValue.description : "-"}</>;
};

export default CodeValue;
