import { Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { InsuranceEventModel } from "../../../../service/RequoteApiTypes";
import { selectInsuranceEvents, addInsuranceEvent } from "./insuranceEventSlice";
import ListItemView from "./ListItemView";
import { PlusSquareFill } from "react-bootstrap-icons";
import "./ListView.css";

interface Props {
  entityId: string;
}

const ListView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const insuranceEvents: InsuranceEventModel[] = useSelector(selectInsuranceEvents).filter(
    (e) => e.entityId == props.entityId
  );

  const onAddNew = () => {
    const newEvent = {
      entityId: props.entityId,
    } as InsuranceEventModel;

    dispatch(addInsuranceEvent(newEvent));
  };

  return (
    <div className="events list-view">
      <Button variant="primary" size="sm" className="add-new-item" onClick={onAddNew}>
        <PlusSquareFill className="mr-2"></PlusSquareFill> Add New
      </Button>
      {insuranceEvents.length > 0 ? (
        <>
          <Table striped>
            <thead>
              <tr>
                <th>Start Date</th>
                <th>Reinstatement Date</th>
                <th>Description</th>
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody>
              {insuranceEvents.map((e, index) => (
                <ListItemView key={e.insuranceEventId} data={e} />
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <div className="no-data">No lapses</div>
      )}
    </div>
  );
};

export default ListView;
