import {useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  PolicyHistoryModel,
  PolicyHistoryLineOfBusinessTypeCode
} from "../../../../service/RequoteApiTypes";
import { ReactDatePicker } from "../../../../components/ReactDatePicker";
import { selectInsuranceCompanyTypes } from "../../../../appRootSlice";
import { updateValidation } from "../../../../rootValidationSlice";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import {
  selectSelectedPolicyHistory,
  selectPolicyHistoryWithRenewal,
  cancelEditPolicyHistory,
  savePolicyHistory,
} from "./policyHistorySlice";
import "./Editor.css";
import {
  convertUtcDateToDisplayString,
  convertDateToString,
} from "../../../../utils/helpers/dateUtils";
import { selectEntity } from "../entity/entitySlice";
import { selectLicenses } from "../license/licenseSlice";
import CONSTANTS from "../../../../constants";
import { selectQuoteRenewalEnabled } from "../../../../appRootSlice";

const Editor: React.FC = () => {
  const dispatch = useDispatch();

  const selectedQuoteRequest = useSelector(selectQuoteRequest);
  const selectedPolicyHistory = useSelector(selectSelectedPolicyHistory);
  const insuranceCompanyTypes = useSelector(selectInsuranceCompanyTypes);
  const firstLicenseDate = useSelector(selectLicenses).filter((e) => e.entityId === selectedPolicyHistory?.entityId).sort().reverse()[0]?.dateLicensed;
  
  const policyHistories: PolicyHistoryModel[] = useSelector(selectPolicyHistoryWithRenewal).filter(
    (e) => e.entityId === selectedPolicyHistory?.entityId
  );
  const selectedEntity = useSelector(selectEntity);

  const isQuoteRenewalFeatureFlagEnabled = useSelector(selectQuoteRenewalEnabled);

  const isQuoteRenewalRendered =
    (selectedEntity?.roles?.includes(CONSTANTS.ENTITY_ROLES.APPLICANT) ?? false) &&
    isQuoteRenewalFeatureFlagEnabled;

  const getFirstInsuredDate = () => {
    return convertUtcDateToDisplayString(
      policyHistories.find((f) => f.transferReason === "")?.inceptionDate
    );
  };

  const getMinStartDate = () => {
    var filteredPolicyHistories = policyHistories.filter((f) => f.transferReason !== "");

    if (filteredPolicyHistories.length === 0) return null;

    var minPolicyHistory = filteredPolicyHistories.reduce((a, b) => {
      if (a.inceptionDate === "") return b;
      if (b.inceptionDate === "") return a;
      return new Date(a.inceptionDate ?? "") < new Date(b.inceptionDate ?? "") ? a : b;
    });

    return convertUtcDateToDisplayString(minPolicyHistory.inceptionDate);
  };

  const getMaxStartDate = () => {
    if (policyHistories.length === 0) return null;

    var maxPolicyHistory = policyHistories.reduce((a, b) => {
      if (a.inceptionDate === "") return b;
      if (b.inceptionDate === "") return a;
      return Date.parse(a.inceptionDate ?? "") > Date.parse(b.inceptionDate ?? "") ? a : b;
    });

    return convertUtcDateToDisplayString(maxPolicyHistory.inceptionDate);
  };

  const isMaxStartDate = (inceptionDate: string | null | undefined) => {
    var maxDate = getMaxStartDate();
    if (maxDate === "") return true;
    return Date.parse(inceptionDate ?? "") >= Date.parse(maxDate ?? "");
  };

  const validationSchema = Yup.object()
    .shape({
      inceptionDate: Yup.date().nullable().required("*Inception date is required"),
    })
    .test("validate-form", (value, context) => {
      if (isQuoteRenewalFeatureFlagEnabled) {
        var inceptionDate = Date.parse(convertUtcDateToDisplayString(value.inceptionDate));
        var firstInsuredDate = Date.parse(getFirstInsuredDate());
        var _firstLicenseDate = Date.parse(firstLicenseDate ?? "");
        var minDate = Date.parse(getMinStartDate() ?? "");
        if (value.transferReason && !value.insuranceCompanyCode) {
          return context.createError({
            path: "insuranceCompanyCode",
            message: "*Insurance company is required",
          });
        }
        if (value.transferReason && inceptionDate < firstInsuredDate) {
          return context.createError({
            path: "inceptionDate",
            message: "Start date cannot be prior to the 'First Insured' start date",
          });
        }
        if (!value.transferReason && inceptionDate > minDate) {
          return context.createError({
            path: "inceptionDate",
            message: "*The start date for 'First Insured' must be the minimum start date",
          });
        }
        if(_firstLicenseDate && inceptionDate < _firstLicenseDate) {
          return context.createError({
            path: "inceptionDate",
            message: "*The start date for 'First Insured' cannot be before 1st license date",
          });
        }
      }

      return true;
    });

  const data: PolicyHistoryModel = {
    entityId: "",
    policyHistoryId: "",
    ...selectedPolicyHistory,
    inceptionDate: convertUtcDateToDisplayString(selectedPolicyHistory?.inceptionDate),
    lineOfBusiness: selectedPolicyHistory?.lineOfBusiness
      ? selectedPolicyHistory.lineOfBusiness
      : null,
    insuranceCompanyCode: selectedPolicyHistory?.insuranceCompanyCode,
    transferReason: selectedPolicyHistory?.transferReason,
  };
  const rootValidRef = useRef(null) as any;
  const validate = () =>
  {
    dispatch(updateValidation({formName: "InsuranceInfoEditor", validationFail: Object.keys(rootValidRef.current.errors).length > 0 ? true : false}));
  };
  const onCancel = () => {
    dispatch(updateValidation({formName: "InsuranceInfoEditor", validationFail: false}));
    dispatch(cancelEditPolicyHistory())
  };

  const onSubmit = (values: PolicyHistoryModel, helpers: FormikHelpers<PolicyHistoryModel>) => {
    const postData = {
      ...data,
      insuranceCompanyCode: values.insuranceCompanyCode,
      lineOfBusiness:
        (values.lineOfBusiness ?? "").length > 0
          ? (values.lineOfBusiness as PolicyHistoryLineOfBusinessTypeCode)
          : null,
      inceptionDate: convertDateToString(values.inceptionDate),
      transferReason: values.transferReason,
    };
    dispatch(savePolicyHistory(selectedQuoteRequest?.quoteRequestWipId as string, postData));
  };

  return (
    <Modal show={selectedPolicyHistory !== undefined} onHide={onCancel} className="history-editor" keyboard={false} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {data.policyHistoryId ? "Edit Insurance Information" : "Add Insurance Information"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={data} validationSchema={validationSchema} innerRef={rootValidRef} validate={validate} onSubmit={onSubmit}>
          {({
            touched,
            errors,
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              {/* show all errors for debugging.
              <div style={{ border: "solid 1px;", color: "red" }}>
                <br />
                {Object.values(errors).map((msg) => (
                  <>
                    {msg}
                    <br />
                  </>
                ))}
              </div>
              */}

              {(values.transferReason !== "" || !isQuoteRenewalFeatureFlagEnabled) && (
                <Form.Group>
                  <Form.Label htmlFor="insuranceCompanyCode">Company</Form.Label>
                  <Form.Control
                    className="custom-select"
                    as="select"
                    id="insuranceCompanyCode"
                    onChange={(e) => {
                      setFieldValue("insuranceCompanyCode", e.target.value);
                      if (e.target.value !== "sgi" && isQuoteRenewalFeatureFlagEnabled) {
                        setFieldValue("transferReason", "7");
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.insuranceCompanyCode ?? ""}
                    isInvalid={
                      touched.insuranceCompanyCode && errors.insuranceCompanyCode ? true : false
                    }>
                    <option key="" value="" />
                    {insuranceCompanyTypes &&
                      insuranceCompanyTypes.map((t) => (
                        <option key={t.codeValue} value={t.codeValue ?? ""}>
                          {t.description}
                        </option>
                      ))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {errors.insuranceCompanyCode}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              <Form.Group>
                <Form.Label htmlFor="inceptionDate">Start Date</Form.Label>
                <br />
                <div className={touched.inceptionDate && errors.inceptionDate ? "is-invalid" : ""}>
                  <ReactDatePicker
                    name="inceptionDate"
                    className={
                      touched.inceptionDate && errors.inceptionDate
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onBlur={handleBlur}
                  />
                </div>
                <Form.Control.Feedback type="invalid">{errors.inceptionDate}</Form.Control.Feedback>
              </Form.Group>
              {values.insuranceCompanyCode === "sgi" &&
                isQuoteRenewalRendered &&
                isMaxStartDate(values.inceptionDate) && (
                  <Form.Group>
                    <Form.Check
                      type="checkbox"
                      label="Renewal Rating"
                      checked={values.transferReason === "2"}
                      onChange={(e) => {
                        setFieldValue("transferReason", e.target.checked ? "2" : "7");
                      }}></Form.Check>
                    <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                  </Form.Group>
                )}

              <Form.Row className="modal-footer">
                <Button variant="secondary" type="button" onClick={onCancel}>
                  Cancel
                </Button>

                <Button variant="primary" type="submit" disabled={isSubmitting} className="ml-2">
                  Submit
                </Button>
              </Form.Row>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default Editor;
