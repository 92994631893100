import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HTTP, HttpAction } from "./../../../../app/middlewares/http/types";
import { AppThunk, RootState } from "./../../../../app/store";
import { LicenseClassMetadata, LicenseInfoModel, LicenseTrainingInfoModel, QuoteRequestWipModel } from "service/RequoteApiTypes";
import { resumeWorkInProgress } from "../../../wip-list/wipListSlice";
import { v4 as uuidv4 } from "uuid";
import * as referenceDataService from "./../../../../service/referenceDataService";
import * as licenseService from "./../../../../service/licenseService";
import { setSelectedEntity } from "../entity/entitySlice";
import { listInsuranceEvents } from "../insuranceEvent/insuranceEventSlice";
import { listPolicyHistory } from "../policyHistory/policyHistorySlice";
interface LicenseState {
  licenseClasses: LicenseClassMetadata[];
  licenses: LicenseInfoModel[];
  selectedLicense?: LicenseInfoModel;

  // Editor
  selectedProvince?: { provinceCode: string, provinceName: string };

  trainings: LicenseTrainingInfoModel[];
  selectedTraining?: LicenseTrainingInfoModel;
}

const initialState: LicenseState = {
  licenseClasses: [],
  licenses: [],
  trainings: []
};

export const licenseSlice = createSlice({
  name: "licenseSlice",
  initialState: initialState,
  reducers: {
    setSelectedProvince: (state, action: PayloadAction<string | null | undefined>) => {
      const provinceCode = action.payload || "";
      const licenseClass = state.licenseClasses.find(c => c.provinceCode == provinceCode);

      state.selectedProvince = licenseClass != undefined
        ? { provinceCode: licenseClass.provinceCode || "", provinceName: licenseClass.provinceName || "" }
        : undefined;
    },
    setLicenseClasses: (state, action: PayloadAction<LicenseClassMetadata[]>) => {
      state.licenseClasses = action.payload;
    },
    addAction: (state, action: PayloadAction<LicenseInfoModel>) => {
      state.selectedLicense = action.payload;
    },
    editAction: (state, action: PayloadAction<LicenseInfoModel>) => {
      state.selectedLicense = action.payload;
    },
    cancelEditAction: (state) => {
      state.selectedLicense = undefined;
      state.selectedProvince = undefined;
    },
    saveAction: (state, action: PayloadAction<LicenseInfoModel>) => {
      const payload = action.payload;

      const licenseIdIndex = state.licenses.findIndex((l) => l.licenseId === payload.licenseId);

      if (licenseIdIndex > -1) {
        state.licenses[licenseIdIndex] = payload;
      } else {
        state.licenses.push(payload);
      }

      state.selectedLicense = undefined;
      state.selectedProvince = undefined;
    },
    deleteAction: (state, action: PayloadAction<LicenseInfoModel>) => {
      state.licenses.splice(
        state.licenses.findIndex((l) => l.licenseId === action.payload.licenseId),
        1
      );
    },

    setSelectedTraining: (state, action: PayloadAction<Partial<LicenseTrainingInfoModel> | undefined>) => {
      state.selectedTraining = { ...(state.selectedTraining ?? { entityId: "", trainingId: "" }), ...action.payload };
    },

    saveTrainingAction: (state, action: PayloadAction<LicenseTrainingInfoModel>) => {
      const payload = action.payload;

      const index = state.trainings.findIndex((l) => l.entityId == payload.entityId && l.trainingId === payload.trainingId);

      if (index > -1) {
        state.trainings[index] = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resumeWorkInProgress, (state: LicenseState, action: PayloadAction<QuoteRequestWipModel | null>) => {
      state.licenses = action.payload?.licenses || [];
      state.trainings = action.payload?.licenseTrainings || [];
      state.selectedTraining = undefined;
      state.selectedLicense = undefined;
    }).addCase(setSelectedEntity, (state, action) => {
      state.selectedTraining = state.trainings.find(t => t.entityId == action.payload.entityId);
    });
  },
});

export const getLicenseClasses = (): AppThunk<void> => async (dispatch) => {
  const action: HttpAction<LicenseClassMetadata[]> = {
    type: HTTP,
    meta: {
      promise: () => referenceDataService.getLicenseClasses(),
      resolve: (data: LicenseClassMetadata[]) => {
        dispatch(setLicenseClasses(data));
      },
      cacheKey: "license-classes-metadata-cache"
    },
  };

  return dispatch(action);
}

export const addLicense = (model: LicenseInfoModel): AppThunk<void> => async (dispatch) => { dispatch(addAction(model)); };

export const editLicense = (model: LicenseInfoModel): AppThunk<void> => async (dispatch) => {
  dispatch(setSelectedProvince(model.province));
  dispatch(editAction(model));
};

export const cancelEditing = (): AppThunk<void> => async (dispatch) => { dispatch(cancelEditAction()); };

export const saveLicense = (
  quoteRequestWipId: string,
  model: LicenseInfoModel
): AppThunk<void> => async (dispatch) => {
  model.licenseId = (model.licenseId || "").length == 0 ? uuidv4() : model.licenseId;

  const action: HttpAction<LicenseInfoModel> = {
    type: HTTP,
    meta: {
      promise: () =>
        licenseService.saveLicense(quoteRequestWipId, model),
      resolve: (response: LicenseInfoModel) => {
        dispatch(saveAction(response));
        dispatch(listPolicyHistory(quoteRequestWipId, response.entityId));
        dispatch(listInsuranceEvents(quoteRequestWipId, response.entityId));
      }
    },
  };

  return dispatch(action);
};

export const deleteLicense = (
  quoteRequestWipId: string,
  model: LicenseInfoModel
): AppThunk<void> => async (dispatch) => {

  const action: HttpAction<void> = {
    type: HTTP,
    meta: {
      promise: () =>
        licenseService.deleteLicense(
          quoteRequestWipId,
          model.entityId,
          model.licenseId
        ),
      resolve: () => {
        dispatch(deleteAction(model));
      }
    },
  };

  return dispatch(action);
};

export const saveTraining = (
  quoteRequestWipId: string,
  model: LicenseTrainingInfoModel
): AppThunk<void> => async (dispatch) => {
  const action: HttpAction<LicenseTrainingInfoModel> = {
    type: HTTP,
    meta: {
      promise: () =>
        licenseService.saveLicenseTraining(quoteRequestWipId, model),
      resolve: (response: LicenseTrainingInfoModel) => {
        dispatch(saveTrainingAction(response));
      }
    },
  };

  return dispatch(action);
};

export const selectLicenseClasses = (rootState: RootState): LicenseClassMetadata[] =>
  rootState.license.licenseClasses
    .slice()
    .sort((first, second) => (first.licenseClassTypeName || "").localeCompare(second.licenseClassTypeName || ""));

export const selectLicenseProvinces = (rootState: RootState) =>
  rootState.license.licenseClasses
    .filter((c, i, arr) => arr.findIndex(a => a.provinceCode === c.provinceCode) === i)
    .map(c => ({ provinceCode: c.provinceCode, provinceName: c.provinceName }))
    .slice()
    .sort((first, second) => (first.provinceName || "").localeCompare(second.provinceName || ""));

export const selectLicenses = (rootState: RootState) =>
  rootState.license.licenses
    .slice()
    .sort((first, second) => new Date(second.dateLicensed ?? "").valueOf() - new Date(first.dateLicensed ?? "").valueOf());

export const selectSelectedLicense = (rootState: RootState) => rootState.license.selectedLicense;

export const selectSelectedProvince = (rootState: RootState) => rootState.license.selectedProvince;

export const selectSelectedTraining = (rootState: RootState) => rootState.license.selectedTraining;

export const firstLicense = (rootState: RootState) => rootState.license.licenses.slice().sort((f, s) => new Date(f.dateLicensed ?? "").valueOf() - new Date(s.dateLicensed ?? "").valueOf())[0];

export const {
  setLicenseClasses,
  addAction,
  editAction,
  cancelEditAction,
  saveAction,
  deleteAction,
  setSelectedTraining,
  saveTrainingAction,
  setSelectedProvince,
} = licenseSlice.actions;

export default licenseSlice.reducer;
