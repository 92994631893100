import { InsuranceEventModel } from "./RequoteApiTypes";
import axios, { AxiosResponse } from "axios";
import CONSTANTS from "../constants";

export async function saveInsuranceEvent(
  quoteRequestWipId: string,
  request: InsuranceEventModel
): Promise<InsuranceEventModel> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.INSURANCE_EVENT.SAVE(quoteRequestWipId, request.entityId);

  const response = await axios.post<InsuranceEventModel, AxiosResponse<InsuranceEventModel>>(
    url,
    request
  );

  return response.data;
}

export async function deleteInsuranceEvent(
  quoteRequestWipId: string,
  entityId: string,
  id: string
): Promise<void> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.INSURANCE_EVENT.DELETE(quoteRequestWipId, entityId, id);

  const response = await axios.delete(url);

  return response.data;
}

export async function listInsuranceEvents(
  quoteRequestWipId: string,
  entityId: string
): Promise<InsuranceEventModel[]> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.INSURANCE_EVENT.LIST(quoteRequestWipId, entityId);

  const response = await axios.get<InsuranceEventModel[]>(url);

  return response.data;
}
