import * as React from "react";
import { AuthenticatedTemplate, useMsal, useAccount } from "@azure/msal-react";
import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { IndexLinkContainer, LinkContainer } from "react-router-bootstrap";
import "./NavMenu.css";
import { useState } from "react";
import { PersonFill } from "react-bootstrap-icons";
import { selectQouteCompare, setQuoteCompare } from "../features/wip-list/wipListSlice";
import QuoteInquiryCompareLinkComponent from "../features/quote-request-2/components/QuoteInquiryCompareLinkComponent"
import { useDispatch, useSelector } from "react-redux";
interface Props {
  isOpen?: boolean;
}

const NavMenu: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(props.isOpen ?? true);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const toggle = () => setIsOpen(!isOpen);
  const selectQuoteCompare = useSelector(selectQouteCompare);

  const navSettings = () => {
        dispatch(setQuoteCompare(
        {
          displayCompareLink: false, 
          reQuoteId: null, 
          orgQuoteId: null
        }));
    };
  return (
    <header className="fixed-top">
      <Navbar
        expand="lg"
        variant="light"
        bg="light"
        className="navbar-expand-sm navbar-toggleable-sm border-bottom">
        <img
          src={process.env.PUBLIC_URL + "/images/SGI-CANADA-black-logo-300dpi.png"}
          alt=""
          width="125"
          className="d-inline-block align-top"
        />
        <Container fluid>
          <Navbar.Brand href="#">Quote Wizard</Navbar.Brand>
          <Navbar.Toggle onClick={toggle} className="mr-2" />
          <Navbar.Collapse>
            <Nav className="ml-auto">
              {(selectQuoteCompare && selectQuoteCompare.displayCompareLink) ? 
                <QuoteInquiryCompareLinkComponent
                  reQuoteId={selectQuoteCompare.reQuoteId}
                  orgQuoteId={selectQuoteCompare.orgQuoteId}
                  displayMode={"text"}/> :
                ""
              }
              <LinkContainer to={"/wip/active"} onClick={()=>navSettings()}>
                <Nav.Link>Requote</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/insurance-score" onClick={()=>navSettings()}>
                <Nav.Link>Insurance Score</Nav.Link>
              </LinkContainer>
            </Nav>
            <AuthenticatedTemplate>
              <Nav>
                <NavDropdown
                  title={
                    <span className="bg-secondary text-light rounded-circle d-flex p-2">
                      <PersonFill />
                    </span>
                  }
                  id="dropdown-basic-button"
                  className="user-menu">
                  <NavDropdown.Item disabled>{account?.name}</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => instance.logout()}>Sign Out</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </AuthenticatedTemplate>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default NavMenu;
