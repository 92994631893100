import { Button, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { TrashFill, PencilSquare } from "react-bootstrap-icons";
import { LicenseInfoModel } from "../../../../service/RequoteApiTypes";
import DisplayDate from "./../../../../components/DateDisplay";
import { useDispatch, useSelector } from "react-redux";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import { editLicense, deleteLicense } from "./licenseSlice";
import LicenseProvince from "./components/LicenseProvince";
import LicenseClass from "./components/LicenseClass";
import helpers from "./../../../../utils/helpers";
import { selectCountryTypeCodes } from "../../../../appRootSlice";

interface Props {
  data: LicenseInfoModel;
  deletable: boolean;
}

const ListItemView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const selectedQuoteRequest = useSelector(selectQuoteRequest);
  const countryTypeCodes = useSelector(selectCountryTypeCodes);

  const country = countryTypeCodes.find(
    (f) => f.codeValue === props.data.country?.toString()
  )?.description;

  const onEdit = (data: LicenseInfoModel) => {
    dispatch(editLicense(data));
  };

  const onDelete = (data: LicenseInfoModel) => {
    helpers.confirm({
      header: "Confirm Delete",
      body: "Are you sure you want to delete it?",
      onConfirm: () => {
        dispatch(deleteLicense(selectedQuoteRequest.quoteRequestWipId as string, data));
      },
    });
  };

  return (
    <tr>
      <td>{country ?? "Other"}</td>
      <td>
        <LicenseProvince value={props.data.province} />
      </td>
      <td>
        <LicenseClass province={props.data.province} value={props.data.licenseClassTypeCode} />
      </td>
      <td>{props.data.number}</td>
      <td className="date-column-lg">
        <DisplayDate value={props.data.dateLicensed} />
      </td>
      <td>
        <OverlayTrigger placement="top" overlay={<Tooltip id="EditLicense">Edit Licence</Tooltip>}>
          <Button
            variant="secondary"
            data-testid="EditLicense"
            size="sm"
            title="Edit"
            onClick={() => onEdit(props.data)}>
            <PencilSquare></PencilSquare>
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="DeleteLicense">
              {props.deletable ? "Delete Licence" : "At least one licence is required"}
            </Tooltip>
          }>
          <span className="d-inline-block">
            <Button
              variant="danger"
              data-testid="DeleteLicense"
              size="sm"
              className="ml-2"
              disabled={!props.deletable}
              style={{ pointerEvents: props.deletable ? "inherit" : "none" }}
              onClick={() => onDelete(props.data)}>
              <TrashFill></TrashFill>
            </Button>
          </span>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default ListItemView;
