import React, { useState } from "react";
import { Button, InputGroup, FormControl, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { quoteSearchAsync } from "./../wipListSlice";
import { Search } from "react-bootstrap-icons";

interface Prop {
  data?: string
}
const QuoteSearch = (prop: Prop) => {
  const dispatch = useDispatch();
  const [quoteRefId, setQuoteRefId] = useState(prop.data ?? "");
  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    quoteRefId.length > 0 && dispatch(quoteSearchAsync(quoteRefId));
  };

  if(prop.data)
  {
    dispatch(quoteSearchAsync(prop.data));
  }
  return (
    <Form onSubmit={handleSearch} autoComplete="off" className="pl-0 mb-3 shadow">
      <InputGroup>
        <FormControl
          id="quoteRefId"
          data-testid="quoteRefId"
          placeholder="Type a quote reference id and press enter to search"
          value={quoteRefId}
          autoFocus={true}
          size="lg"
          onChange={(e) => setQuoteRefId(e.target.value.trim().toUpperCase())}
        />
        <InputGroup.Append>
          <Button variant="primary" type="submit" block>
            <Search className="ml-2 mr-2" size="25"></Search>
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  );
};

export default QuoteSearch;
