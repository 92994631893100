import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Form,
  Button,
  Modal,
  InputGroup,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { FieldArray, Formik, FormikHelpers, getIn } from "formik";
import * as Yup from "yup";
import { ReactDatePicker } from "../../../../components/ReactDatePicker";
import { EntityModel, VehicleModel, ThirdPartyClaimModel, Account } from "../../../../service/RequoteApiTypes";
import { selectSelectedClaim, cancelEditAction, saveClaim, selectThirdParty, setThirdParty } from "./claimSlice";
import { convertUtcDateToDisplayString } from "../../../../utils/helpers/dateUtils";
import {
  selectClaimCauseOfLossCircumstanceTypeCodesAndPerilCodes,
  selectPayoutAmountCoverageTypeCodes,
  thirdPartyClaimFeatureFlagEnabled
} from "../../../../appRootSlice";
import CONSTANTS from "../../../../constants";
import { selectEntities } from "../entity/entitySlice";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import { PlusSquareFill, TrashFill } from "react-bootstrap-icons";
import { selectVehicles } from "../vehicle/vehicleSlice";
import ClaimInputModel from "./models/claimInputModel";
import { updateValidation } from "../../../../rootValidationSlice";
import ThirdPartyEditor from "./ThirdPartyEditor";

const validationSchema = Yup.object().shape({
  lossDate: Yup.date().nullable().required("*Loss date is required"),
  claimCauseOfLossCircumstanceTypeCodesAndPerilCodes: Yup.string().required("*Description is required"),
  accidentResponsiblePercentage: Yup.number().required("*Responsibility is required"),
  claimant: Yup.array().of(
    Yup.object().shape({
      amount: Yup.string()
        .nullable()
        .required("*Amount is required")
        .matches(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/, "Amount must be a valid dollar value"),
      coverageTypeCode: Yup.string().nullable().required("*Coverage type is required"),
    })
  ),
  relationship: Yup.array().of(
    Yup.object().shape({
      asset_id: Yup.string().required("*Vehicle is required"),
      entity_id: Yup.string().required("*Driver is required"),
    })
  ),
});

const ClaimsEditor: React.FC = () => {
  const dispatch = useDispatch();
  const selectedQuoteRequest = useSelector(selectQuoteRequest);
  const selectedClaim = useSelector(selectSelectedClaim);
  const selectedThirdParty = useSelector(selectThirdParty);
  const claimCauseOfLossPerilTypeCodes = useSelector(
    selectClaimCauseOfLossCircumstanceTypeCodesAndPerilCodes
  );
  const coverageTypeCodes = useSelector(selectPayoutAmountCoverageTypeCodes);
  const isThirdPartyClaimEditorEnabled = useSelector(thirdPartyClaimFeatureFlagEnabled);
  const drivers: EntityModel[] = useSelector(selectEntities).filter(
    (f) => f.roles && f.roles.indexOf(CONSTANTS.ENTITY_ROLES.DRIVER) > -1
  );

  const vehicles: VehicleModel[] = useSelector(selectVehicles);

  const data: ClaimInputModel = {
    claimId: selectedClaim ? selectedClaim.claimId : "",
    claimNumber: selectedClaim ? selectedClaim.claimNumber : "",
    chargeableClaimIndicator: selectedClaim ? selectedClaim.chargeableClaimIndicator : "",
    claimSourceCode: selectedClaim ? selectedClaim.claimSourceCode : null,
    underwritingUseCode: selectedClaim ? selectedClaim.underwritingUseCode : null,
    gridUseCode: selectedClaim ? selectedClaim.gridUseCode : null,
    addedDate: selectedClaim ? selectedClaim.addedDate : "",
    accidentResponsiblePercentage: selectedClaim
      ? selectedClaim.accidentResponsiblePercentage
      : null,
    lossDate: convertUtcDateToDisplayString(selectedClaim?.lossDate),
    causeOfLossPerilCode: selectedClaim ? selectedClaim.causeOfLossPerilCode : null,
    causeOfLossCircumstanceCode: selectedClaim ? selectedClaim.causeOfLossCircumstanceCode : null,
    relationship: selectedClaim ? selectedClaim.relationship : [],
    claimant: selectedClaim ? selectedClaim.claimant : [],
    claimCauseOfLossCircumstanceTypeCodesAndPerilCodes: selectedClaim
      ? selectedClaim.causeOfLossCircumstanceCode ?? selectedClaim.causeOfLossPerilCode
      : null,
      thirdPartyClaim: selectedClaim ? selectedClaim.thirdPartyClaim : [],
  };

  const rootValidRef = useRef(null) as any;
  const validate = () => {
    if(rootValidRef.current) {
      dispatch(
        updateValidation({
          formName: "ClaimEditor",
          validationFail: Object.keys(rootValidRef.current.errors).length > 0 ? true : false,
        })
      );
    }
  };

  const addThirdParty = () => {
    let arr: ThirdPartyClaimModel[] = [];
    arr.push({
      thirdPartyClaimId: uuidv4(),
      coverageTypeCode: "tpdc",
      account: [{
        accountTypeCode: "indemnity",
        amount: null,
      } as Account],
    } as ThirdPartyClaimModel);
    data.thirdPartyClaim = arr;
    dispatch(setThirdParty(data.thirdPartyClaim));
  }

  const removeThirdParty = () => {
    data.thirdPartyClaim = [];
    dispatch(setThirdParty(data.thirdPartyClaim));
  }
  
  const onCancel = () => {
    dispatch(cancelEditAction());
  };

  const onSubmit = (values: ClaimInputModel, helpers: FormikHelpers<ClaimInputModel>) => {
    if(values.causeOfLossPerilCode == "wind-hail") {
      alert("Wind-Hail is obsolete, please select either Wind or Hail.");
    } else {
      const postData = {
        ...data,
        lossDate: values.lossDate,
        causeOfLossPerilCode: values.causeOfLossPerilCode,
        causeOfLossCircumstanceCode: values.causeOfLossCircumstanceCode,
        claimant: values.claimant,
        relationship: values.relationship,
        accidentResponsiblePercentage: values.accidentResponsiblePercentage,
        thirdPartyClaim: selectedThirdParty,
      };
      dispatch(saveClaim(selectedQuoteRequest.quoteRequestWipId as string, postData, data.claimId ? "edit" : "add"));
    }
  };

  return (
    <Modal
      show={selectedClaim !== undefined}
      size="lg"
      onHide={onCancel}
      className="claims-editor"
      keyboard={false}
      backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{data.claimId ? "Edit Claim" : "Add New Claim"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={data} validationSchema={validationSchema} innerRef={rootValidRef} validate={validate} onSubmit={onSubmit}>
          {({
            touched,
            errors,
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Form.Group>
                <Form.Label htmlFor="lossDate">Claim Loss Date</Form.Label>
                <div className={touched.lossDate && errors.lossDate ? "is-invalid" : ""}>
                  <ReactDatePicker
                    name="lossDate"
                    className={
                      touched.lossDate && errors.lossDate
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={handleChange}
                  />
                </div>
                <Form.Control.Feedback type="invalid">{errors.lossDate}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="claimCauseOfLossCircumstanceTypeCodesAndPerilCodes">
                  Description
                </Form.Label>
                <Form.Control
                  className="custom-select"
                  as="select"
                  name="claimCauseOfLossCircumstanceTypeCodesAndPerilCodes"
                  onChange={(c) => {
                    setFieldValue(
                      "claimCauseOfLossCircumstanceTypeCodesAndPerilCodes",
                      c.target.value
                    );
                    if (c.target.value === "animal" || c.target.value === "hit and run") {
                      setFieldValue("causeOfLossPerilCode", "collision");
                      setFieldValue("causeOfLossCircumstanceCode", c.target.value);
                    } else {
                      setFieldValue("causeOfLossPerilCode", c.target.value);
                      setFieldValue("causeOfLossCircumstanceCode", null);
                    }
                  }}
                  onBlur={handleBlur}
                  value={values.claimCauseOfLossCircumstanceTypeCodesAndPerilCodes ?? ""}
                  isInvalid={
                    touched.claimCauseOfLossCircumstanceTypeCodesAndPerilCodes &&
                    errors.claimCauseOfLossCircumstanceTypeCodesAndPerilCodes
                      ? true
                      : false
                  }>
                  <option key="" value="" />
                  {claimCauseOfLossPerilTypeCodes.map((m) => {
                    return (
                      <option key={m.codeValue} value={m.codeValue ?? ""}>
                        {m.description}
                      </option>
                    );
                  })}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.claimCauseOfLossCircumstanceTypeCodesAndPerilCodes}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <Form.Label htmlFor="accidentResponsiblePercentage">Responsibility</Form.Label>
                <Form.Control
                  className="custom-select"
                  as="select"
                  name="accidentResponsiblePercentage"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={
                    touched.accidentResponsiblePercentage && errors.accidentResponsiblePercentage
                      ? true
                      : false
                  }
                  value={values.accidentResponsiblePercentage ?? ""}>
                  <option key="" value=""></option>
                  <option key={0} value={0}>
                    0
                  </option>
                  <option key={25} value={25}>
                    25
                  </option>
                  <option key={50} value={50}>
                    50
                  </option>
                  <option key={75} value={75}>
                    75
                  </option>
                  <option key={100} value={100}>
                    100
                  </option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.accidentResponsiblePercentage}
                </Form.Control.Feedback>
              </Form.Group>
              <FieldArray
                name="relationship"
                render={(arrayHelpers) => (
                  <>
                    <Form.Group>
                      <Form.Label htmlFor="relationship.0.asset_id">Vehicle</Form.Label>
                      <Form.Control
                        className="custom-select"
                        as="select"
                        name="relationship.0.asset_id"
                        onChange={(e) => {
                          setFieldValue("relationship.0.asset_id", e.target.value);
                        }}
                        onBlur={handleBlur}
                        value={values.relationship ? values.relationship[0].asset_id ?? "" : ""}
                        isInvalid={
                          getIn(touched, `relationship.0.asset_id`) &&
                          getIn(errors, `relationship.0.asset_id`)
                            ? true
                            : false
                        }>
                        <option key="" value="" />
                        {vehicles.map((m) => {
                          return (
                            <option key={m.assetId} value={m.assetId ?? ""}>
                              {m.modelYear + " " + m.manufacturer + " " + m.model}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {getIn(errors, `relationship.0.asset_id`)}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label htmlFor="relationship.0.entity_id">Driver</Form.Label>
                      <Form.Control
                        className="custom-select"
                        as="select"
                        name="relationship.0.entity_id"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.relationship ? values.relationship[0].entity_id ?? "" : ""}
                        isInvalid={
                          getIn(touched, `relationship.0.entity_id`) &&
                          getIn(errors, `relationship.0.entity_id`)
                            ? true
                            : false
                        }>
                        <option key="" value="" />
                        {drivers.map((m) => {
                          return (
                            <option key={m.entityId} value={m.entityId ?? ""}>
                              {m.displayName}
                            </option>
                          );
                        })}
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {getIn(errors, `relationship.0.entity_id`)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </>
                )}></FieldArray>

              <FieldArray
                name="claimant"
                render={(arrayHelpers) => (
                  <>
                    <Row>
                      <Col>
                        <Form.Label>Payouts</Form.Label>
                      </Col>
                      <Col className="text-right mb-2">
                      {isThirdPartyClaimEditorEnabled && 
                       <Button
                          variant="primary"
                          size="sm"
                          className="add-new-item mr-2"
                          disabled={(selectedThirdParty && selectedThirdParty?.length) ? true : false}
                          onClick={() => {
                            addThirdParty();
                          }}>
                            <PlusSquareFill className="mr-2"></PlusSquareFill>TP DCPD
                        </Button>}
                        <Button
                          variant="primary"
                          size="sm"
                          className="add-new-item"
                          onClick={() =>
                            arrayHelpers.push({
                              claimCoverageId: "",
                              accountTypeCode: "indemnity",
                              coverageTypeCode: null,
                              amount: null,
                            })
                          }>
                          <PlusSquareFill className="mr-2"></PlusSquareFill> Add New
                        </Button>
                      </Col>
                    </Row>
                    {values.claimant?.map((s, index) => (
                      <div key={index}>
                        <Row className="mb-2">
                          <Col xs={8}>
                            <Form.Control
                              className="custom-select"
                              as="select"
                              name={`claimant.${index}.coverageTypeCode`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={
                                values.claimant ? values.claimant[index].coverageTypeCode ?? "" : ""
                              }
                              isInvalid={
                                getIn(touched, `claimant.${index}.coverageTypeCode`) &&
                                getIn(errors, `claimant.${index}.coverageTypeCode`)
                                  ? true
                                  : false
                              }>
                              <option key="" value="" />
                              {coverageTypeCodes.map((m) => {
                                return (
                                  <option key={m.codeValue} value={m.codeValue ?? ""}>
                                    {m.description}
                                  </option>
                                );
                              })}
                            </Form.Control>

                            <Form.Control.Feedback type="invalid">
                              {getIn(errors, `claimant.${index}.coverageTypeCode`)}
                            </Form.Control.Feedback>
                          </Col>
                          <Col xs={3}>
                            <InputGroup>
                              <InputGroup.Append>
                                <InputGroup.Text>$</InputGroup.Text>
                              </InputGroup.Append>
                              <Form.Control
                                as="input"
                                name={`claimant.${index}.amount`}
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.claimant ? values.claimant[index].amount ?? "" : ""}
                                isInvalid={
                                  getIn(touched, `claimant.${index}.amount`) &&
                                  getIn(errors, `claimant.${index}.amount`)
                                    ? true
                                    : false
                                }></Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {getIn(errors, `claimant.${index}.amount`)}
                              </Form.Control.Feedback>
                            </InputGroup>
                          </Col>
                          <Col className="mt-1">
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="DeleteClaim">Delete</Tooltip>}>
                              <Button
                                variant="danger"
                                data-testid="DeleteClaim"
                                size="sm"
                                title="Delete"
                                onClick={() => arrayHelpers.remove(index)}>
                                <TrashFill></TrashFill>
                              </Button>
                            </OverlayTrigger>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                )}></FieldArray>
                <ThirdPartyEditor data={selectedThirdParty ?? []} deleteTp={removeThirdParty}  />
              <Form.Row className="modal-footer">
                <Button variant="secondary" type="button" onClick={onCancel}>
                  Cancel
                </Button>

                <Button variant="primary" type="submit" className="ml-2">
                  Submit
                </Button>
              </Form.Row>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default ClaimsEditor;
