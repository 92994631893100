import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Prompt } from 'react-router-dom'

interface Props {
    inValid: boolean;
    formName: string;
  }

const LeavePageBlocker: React.FC<Props> = (props) => {
  const message = `${props.formName} has unsaved changes! Do you want to continue?`;

  useEffect(() => {
    if (!props.inValid) return () => {}

    const beforeUnloadCallback = (event:Event) => {
      event.preventDefault()
      event.returnValue = true;
      return message
    }

    window.addEventListener('beforeunload', beforeUnloadCallback)
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback)
    }
  }, [props.inValid, message])

  return <Prompt when={props.inValid} message={message} />
}

LeavePageBlocker.propTypes = {
  inValid: PropTypes.bool.isRequired,
}

export default LeavePageBlocker