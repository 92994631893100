import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
import { Formik, FormikHelpers, getIn } from "formik";
import * as Yup from "yup";
import { RootState } from "../../../../app/store";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import {
    selectSelectedCoverage,
    cancelEditing,
    saveCoverage,
    getCoverageLists,
} from "./vehicleSlice"
import { updateValidation } from "../../../../rootValidationSlice";
import { AssetCovergeModel } from "service/RequoteApiTypes";
import CONSTANTS from "./../../../../constants";
import { selectReferenceDataCategory } from "../../../../appRootSlice";


const VehicleCoverageEditor: React.FC = () => {
    const dispatch = useDispatch();
    const selectedCoverage = useSelector(selectSelectedCoverage);
    const coverageTypes = useSelector((state: RootState) => selectReferenceDataCategory(state, CONSTANTS.REFDATA.COVERAGE_TYPE_CODE)) ?? [];
    const coverageListType = useSelector((state: RootState) => getCoverageLists(state, selectedCoverage?.typeCode ?? ""));
    const selectedQuoteRequest = useSelector(selectQuoteRequest);
    let data: AssetCovergeModel = {
        ...selectedCoverage,
    };

    const validationSchema = Yup.object().shape({
        typeCode: Yup.string()
                .nullable()
                .required("*Coverage is required"),
        deductible: Yup.array().of(
            Yup.object().shape({
                value: Yup.string().required("*deductible is required"),
            })
        ),
      });

    const rootValidRef = useRef(null) as any;
    const validate = () => {
        dispatch(
        updateValidation({
            formName: "VehCoverageEditor",
            validationFail: Object.keys(rootValidRef.current.errors).length > 0 ? true : false,
        })
        );
    };
    const onCancel = () => {
        dispatch(updateValidation({ formName: "VehCoverageEditor", validationFail: false }));
        dispatch(cancelEditing());
      };
    
    const onSubmit = (values: AssetCovergeModel, helpers: FormikHelpers<AssetCovergeModel>) => {
        const postData = {
            ...data,
            deductible: values.deductible  
        };
        dispatch(saveCoverage(selectedQuoteRequest?.quoteRequestWipId as string, postData));
    };

    return (
        <Modal
            show={selectedCoverage !== undefined}
            onHide={onCancel}
            className="veh-coverage-editor"
            keyboard={false}
            backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    Edit Coverage Information
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Formik
                initialValues={data}
                validationSchema={validationSchema}
                innerRef={rootValidRef}
                validate={validate}
                onSubmit={onSubmit}
                >
                {({
                    touched,
                    errors,
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit} autoComplete="off">
                    <Form.Group>
                        <Form.Label htmlFor="typeCode">Coverage</Form.Label>
                        <Form.Control
                        className="custom-select"
                        as="select"
                        id="typeCode"
                        disabled
                        value={values.typeCode ?? ""}
                        >
                        {coverageTypes &&
                            coverageTypes.filter(f => CONSTANTS.VEHICLE_REQUIRED_COVERAGES.includes(f.codeValue ?? "")).map((p) => (
                            <option key={p.codeValue} value={p.codeValue ?? ""}>
                                {p.description}
                            </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{errors.typeCode}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label htmlFor="deductible.0.value">Deductible</Form.Label>
                        <Form.Control
                        className="custom-select"
                        as="select"
                        id="deductible.0.value"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.deductible ? values.deductible[0].value?.toString() ?? "" : ""}
                        isInvalid={
                            getIn(touched, `deductible.0.value`) &&
                            getIn(errors, `deductible.0.value`)
                              ? true
                              : false
                          }
                        >
                        {coverageListType.deductible &&
                            coverageListType.deductible.map((p) => (
                            <option key={p} value={p}>
                                {p}
                            </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">{getIn(errors, `deductible.0.value`)}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Row className="modal-footer">
                        <Button variant="secondary" type="button" onClick={onCancel}>
                            Cancel
                        </Button>

                        <Button variant="primary" type="submit" className="ml-2">
                            Submit
                        </Button>
                    </Form.Row>
                    </form>
                )}
                </Formik>
            </Modal.Body>
        </Modal>
    )
};

export default VehicleCoverageEditor;
