import { useDispatch, useSelector } from "react-redux";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { TrashFill, PencilSquare } from "react-bootstrap-icons";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import { editPolicyHistory, deletePolicyHistory } from "./policyHistorySlice";
import { PolicyHistoryModel } from "../../../../service/RequoteApiTypes";
import DisplayDate from "../../../../components/DateDisplay";
import helpers from "../../../../utils/helpers";
import CONSTANTS from "../../../../constants";
import CodeValue from "../../../../components/CodeValue";
import { selectQuoteRenewalEnabled } from "../../../../appRootSlice";

interface Props {
  data: PolicyHistoryModel;
  index: number;
  count: number;
  isQuoteRenewalRendered: boolean;
}

const ListItemView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const selectedQuoteRequest = useSelector(selectQuoteRequest);
  const isQuoteRenewalFeatureFlagEnabled = useSelector(selectQuoteRenewalEnabled);

  const onEdit = (data: PolicyHistoryModel) => {
    dispatch(editPolicyHistory(data));
  };

  const onDelete = (data: PolicyHistoryModel) => {
    helpers.confirm({
      header: "Confirm Delete",
      body: "Are you sure you want to delete it?",
      onConfirm: () => {
        dispatch(deletePolicyHistory(selectedQuoteRequest.quoteRequestWipId as string, data));
      },
    });
  };

  return (
    <tr>
      {isQuoteRenewalFeatureFlagEnabled ? (
        <td>
          {props.index === props.count - 1
            ? "Current Carrier"
            : props.data.transferReason
            ? "Company Insured"
            : "First Insured"}
        </td>
      ) : (
        <td>{props.data.transferReason ? "Company Insured" : "First Insured"}</td>
      )}
      <td>
        <CodeValue
          categoryName={CONSTANTS.REFDATA.INSURANCE_COMPANY_TYPE_CODE}
          value={props.data.insuranceCompanyCode?.toString()}
        />
      </td>
      <td>
        <DisplayDate value={props.data.inceptionDate} />
      </td>
      {props.isQuoteRenewalRendered && <td> {props.data.transferReason === "2" ? "Yes" : ""}</td>}
      <td>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="EditHistory">Edit Insurance Information</Tooltip>}>
          <Button
            variant="secondary"
            data-testid="EditHistory"
            size="sm"
            title="Edit"
            onClick={() => onEdit(props.data)}>
            <PencilSquare></PencilSquare>
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="DeleteHistory">Delete Insurance Information</Tooltip>}>
          <Button
            variant="danger"
            data-testid="DeleteHistory"
            size="sm"
            className="ml-2"
            title="Delete"
            onClick={() => onDelete(props.data)}>
            <TrashFill></TrashFill>
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default ListItemView;
