import QuoteInquiryLinkComponent from "../../quote-request-2/components/QuoteInquiryLinkComponent";
import QuoteInquiryCompareLinkComponent from "../../quote-request-2/components/QuoteInquiryCompareLinkComponent";
import DateDisplay from "../../../components/DateDisplay";
import { XCircleFill, CheckCircleFill } from "react-bootstrap-icons";
import { RequoteHistoryModel } from "service/RequoteApiTypes";

const RecentlySubmittedWipTableRow: React.FC<{ data: RequoteHistoryModel }> = ({ data }) => {
  return (
    <tr key={data.quote?.requestId}>
      <td>
        {data.quote?.success ? (
          <CheckCircleFill className="text-success" />
        ) : (
          <XCircleFill className="text-danger" />
        )}
      </td>
      <td>
        <DateDisplay value={data.requoteDateTime} />
      </td>
      <td>
        <QuoteInquiryLinkComponent
          quoteRefId={data.quote?.quoteRefId ?? ""}
          requestId={data.quote?.requestId ?? ""} />
      </td>
      <td>
        <QuoteInquiryLinkComponent
          quoteRefId={data.sourceQuoteRefId ?? ""}
          requestId={data.sourceRequestId ?? ""}/>
      </td>
      <td>
      <QuoteInquiryCompareLinkComponent
          reQuoteId={data.quote?.quoteRefId ?? ""}
          orgQuoteId={data.sourceQuoteRefId ?? ""}
          displayMode={"button"}/>
      </td>
    </tr>
  );
};

export default RecentlySubmittedWipTableRow;
