const constants = {
  EnableLicenseInfoFeatureFlag: "requote-ui-enable-license-amends",
  EnableSuspensionInfoFeatureFlag: "requote-ui-suspension-info-feature-flag",
  EnableGridStepOverrideFeatureFlag: "requote-ui-enable-grid-step-override",
  EnableQuoteRenewalFeatureFlag: "requote-ui-new-vs-renewal-feature-flag",
  EnableTermEffectiveDateFlag: "requote-ui-term-effective-date",
  EnableMultiLineFeatureFlag: "requote-ui-multi-line-discount",
  EnableClaimsFeatureFlag: "requote-ui-claims-feature-flag",
  EnableVehiclesFeatureFlag: "requote-ui-vehicles-feature-flag",
  EnableSubmitButtonDisableFeatureFlag: "requote-ui-disable-submit-feature-flag",
  EnableSplitMisrepresentationFraudFeatureFlag: "requote-split-misrepresentation-fraud-event-flag",
  EnableVehicleDeductibleEditorFeatureFlag: "requote-ui-edit-vehicles-deductible-feature-flag",
  EnableThirdPartyClaimFeatureFlag: "requote-third-party-claim-feature-flag",
  EnableCompareButtonFeatureFlag: "requote-ui-compare-button",

  ENTITY_ROLES: {
    APPLICANT: "applicant",
    DRIVER: "driver",
  },

  API: {
    GraphMe: "https://graph.microsoft.com/v1.0/me",
    CONFIGURATION: {
      GET_CONFIGURATIONS: "/config.json",
      GET_ACTIVE_FEATURE_FLAGS: "configuration/feature-flags",
    },
    QUOTE_REQUEST_DETAIL: {
      SAVE: (quoteRequestWipId: string) => `quote-request/${quoteRequestWipId}/quote-detail`,
    },
    QUOTE_REQUEST_SEARCH: {
      GET: "quote-request/QuoteRequestSearch",
    },
    QUOTE_REQUEST_WIP: {
      GET: (quoteRequestWipId: string) => `quote-request/QuoteRequestWip/${quoteRequestWipId}`,
      CREATE: `quote-request/QuoteRequestWip/Create`,
      SUBMIT: (quoteRequestWipId: string) =>
        `quote-request/QuoteRequestWip/Submit/${quoteRequestWipId}`,
      DELETE: (quoteRequestWipId: string) => `quote-request/QuoteRequestWip/${quoteRequestWipId}`,
    },
    QUOTE_REQUEST_WIP_LIST: {
      GET: "quote-request/QuoteRequestWipList",
    },
    REFERENCE_DATA: {
      GET: "ReferenceData",
      GET_BROKERS: "referencedata/brokers",
      GET_LICENSE_CLASSES: "referencedata/license-classes",
    },
    REQUOTE_HISTORY_LIST: {
      GET: "quote-request/RequoteHistoryList",
    },
    INSURANCE_SCORE: {
      SUBMIT_INSURANCE_SCORE_REQUEST: "InsuranceScore",
    },
    INSURANCE_EVENT: {
      SAVE: (quoteRequestWipId: string, entityId: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/insurance-event`,
      DELETE: (quoteRequestWipId: string, entityId: string, id: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/insurance-event/${id}`,
      LIST: (quoteRequestWipId: string, entityId: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/insurance-event`,
    },
    POLICY_HISTORY: {
      SAVE: (quoteRequestWipId: string, entityId: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/policy-history`,
      DELETE: (quoteRequestWipId: string, entityId: string, id: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/policy-history/${id}`,
      LIST: (quoteRequestWipId: string, entityId: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/policy-history`,
    },
    CONVICTION: {
      SAVE: (quoteRequestWipId: string, entityId: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/driver/conviction`,
      DELETE: (quoteRequestWipId: string, entityId: string, id: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/driver/conviction/${id}`,
    },
    CLAIM: {
      SAVE: (quoteRequestWipId: string) => `quote-request/${quoteRequestWipId}/claim`,
      DELETE: (quoteRequestWipId: string, id: string) =>
        `quote-request/${quoteRequestWipId}/claim/${id}`,
    },
    PERSONAL_INFORMATION: {
      SAVE: (quoteRequestWipId: string, entityId: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/personal-information`,
    },
    LICENSE: {
      SAVE: (quoteRequestWipId: string, entityId: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/driver/license`,
      DELETE: (quoteRequestWipId: string, entityId: string, id: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/driver/license/${id}`,
      TRAINING: {
        SAVE: (quoteRequestWipId: string, entityId: string) =>
          `quote-request/${quoteRequestWipId}/entity/${entityId}/driver/license/training`,
      },
    },
    SUSPENSION: {
      SAVE: (quoteRequestWipId: string, entityId: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/driver/suspension`,
      DELETE: (quoteRequestWipId: string, entityId: string, id: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/driver/suspension/${id}`,
    },
    GRID_STEP_OVERRIDE: {
      SAVE: (quoteRequestWipId: string, entityId: string) =>
        `quote-request/${quoteRequestWipId}/entity/${entityId}/driver/override/grid-step`,
    },
    ASSET: {
      COVERAGE: {
        SAVE: (quoteRequestWipId: string) =>
        `quote-request/${quoteRequestWipId}/asset`,
      }
    }
  },

  REFDATA: {
    CONVICTION_TYPE_CODE: "convictionTypeCode",
    CONVICTION_SUPPLEMENT_TYPE_CODE: "SupplementTypeCode",
    INSURANCE_COMPANY_TYPE_CODE: "insuranceCompanyTypeCode",
    INSURANCE_EVENT_TYPE_CODE: "InsuranceEventTypeCode",
    POLICY_HISTORY_CANCELLATION_REASON_TYPE_CODE: "policyHistoryCancellationReasonTypeCode",
    POLICY_HISTORY_LINE_OF_BUSINESS_TYPE_CODE: "policyHistoryLineOfBusinessTypeCode",
    TRANSACTION_TYPE: "TransactionType",
    MARITAL_STATUS_TYPE_CODE: "MaritalStatus",
    GENDER_TYPE_CODE: "Gender",
    OCCUPATION_TYPE_CODE: "OccupationTypeCode",
    SUSPENSION_TYPE_CODE: "SuspensionTypeCode",
    COUNTRY_TYPE_CODE: "CountryTypeCode",
    CLAIM_CAUSE_OF_LOSS_PERIL_TYPE_CODE: "ClaimCauseOfLossPerilTypeCode",
    CLAIM_CAUSE_OF_LOSS_CIRCUMSTANCE_TYPE_CODE: "ClaimCauseOfLossCircumstanceTypeCode",
    COVERAGE_TYPE_CODE: "CoverageTypeCode",
  },

  VEHICLE_REQUIRED_COVERAGES:[
    "col", //Collision
    "cmp", //Comprehensive
    "ap",  //all perils
    "sp",  //Specified Perils
  ],
  COVERAGE_LIST_TYPE: {
    "DEDUCTIBLE_D1": [
      250,
      500,
      1000,
      1500,
      2000,
      2500,
      3000,
      4000,
      5000,
      6000,
      7000,
      8000,
      9000,
      10000,
    ],
    "DEDUCTIBLE_D2": [
      100,
      250,
      500,
      1000,
      1500,
      2000,
      2500,
      3000,
      4000,
      5000,
      6000,
      7000,
      8000,
      9000,
      10000,
    ],
    "LIMIT_L1": [
      200000,
      300000,
      500000,
      1000000,
      2000000,
      3000000,
      4000000,
      5000000,
    ],
    "LIMIT_L2": [
      200000,
      300000,
      500000,
      1000000,
      2000000,
    ],
    "LIMIT_L3": [
      750,
      1000,
      1500,
      2000,
      2500,
      3000,
      3500,
      4000,
      4500,
      5000,
    ],
    "LIMIT_L4": [
      50000,
    ],
    "MONTH_M1": [
      30,
    ]
  },
  COVERAGE_LIST:[
    {
      COVERAGE: "col",
      DEDUCTIBLE: "DEDUCTIBLE_D1",
      LIMIT: "",
      MONTH: "",
    },
    {
      COVERAGE: "colod",
      DEDUCTIBLE: "DEDUCTIBLE_D1",
      LIMIT: "",
      MONTH: "",
    },
    {
      COVERAGE: "ap",
      DEDUCTIBLE: "DEDUCTIBLE_D1",
      LIMIT: "",
      MONTH: "",
    },
    {
      COVERAGE: "sp",
      DEDUCTIBLE: "DEDUCTIBLE_D2",
      LIMIT: "",
      MONTH: "",
    },
    {
      COVERAGE: "cmp",
      DEDUCTIBLE: "DEDUCTIBLE_D2",
      LIMIT: "",
      MONTH: "",
    },
    {
      COVERAGE: "tp",
      DEDUCTIBLE: "",
      LIMIT: "LIMIT_L1",
      MONTH: "",
    },
    {
      COVERAGE: "tpod",
      DEDUCTIBLE: "",
      LIMIT: "LIMIT_L1",
      MONTH: "",
    },
    {
      COVERAGE: "end-44",
      DEDUCTIBLE: "",
      LIMIT: "LIMIT_L2",
      MONTH: "",
    },
    {
      COVERAGE: "end-20",
      DEDUCTIBLE: "",
      LIMIT: "LIMIT_L3",
      MONTH: "",
    },
    {
      COVERAGE: "end-27",
      DEDUCTIBLE: "",
      LIMIT: "LIMIT_L4",
      MONTH: "",
    },
    {
      COVERAGE: "end-43r",
      DEDUCTIBLE: "",
      LIMIT: "",
      MONTH: "MONTH_M1",
    },
    {
      COVERAGE: "end-43rl",
      DEDUCTIBLE: "",
      LIMIT: "",
      MONTH: "MONTH_M1",
    },
  ]
};

export default constants;
