import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HTTP, HttpAction } from "../../app/middlewares/http/types";
import { HttpError } from "../../app/middlewares/http/types";
import { AppThunk, RootState } from "../../app/store";
import {
  QuoteReferenceModel,
  QuoteRequestSearchSummaryModel,
  QuoteRequestWipModel,
  QuoteRequestWipSummaryModel,
  RequoteHistoryModel,
  CompareQuoteModel,
} from "../../service/RequoteApiTypes";
import * as quoteRequestWipListService from "./../../service/quoteRequestWipListService";
import * as quoteRequestSearchService from "./../../service/quoteRequestSearchService";
import * as quoteRequestWipService from "./../../service/quoteRequestWipService";
import * as requoteHistoryListService from "./../../service/requoteHistoryListService";

interface WipListState {
  quoteRequestWipList: QuoteRequestWipSummaryModel[] | null;
  searchResults: QuoteRequestSearchSummaryModel[] | null;

  isLoading: boolean;
  error: string | null;

  requoteHistoryList: RequoteHistoryModel[];

  compareQuote: CompareQuoteModel | null;
}

const initialState: WipListState = {
  quoteRequestWipList: null,
  searchResults: null,
  isLoading: false,
  error: null,
  requoteHistoryList: [],
  compareQuote: null,
};

export const resumeWorkInProgress = createAction<QuoteRequestWipModel | null>(
  "resumeWorkInProgress"
);

export const wipSubmitted = createAction<{
  quoteRequestWipId: string;
  quoteReferenceModel: QuoteReferenceModel;
}>("wipSubmitted");

export const wipListSlice = createSlice({
  name: "wip-list",
  initialState,
  reducers: {
    loading: (state) => {
      state.isLoading = true;
    },

    loadingComplete: (state) => {
      state.isLoading = false;
    },

    loadWipListCompleted: (state, action: PayloadAction<QuoteRequestWipSummaryModel[]>) => {
      state.quoteRequestWipList = action.payload;
    },

    loadRequoteHistoryListCompleted: (state, action: PayloadAction<RequoteHistoryModel[]>) => {
      state.requoteHistoryList = action.payload;
    },

    searchCompleted: (state, action: PayloadAction<QuoteRequestSearchSummaryModel[]>) => {
      state.searchResults = action.payload;
    },

    clearSearchResults: (state) => {
      state.searchResults = null;
    },

    wipCreated: (state, action: PayloadAction<QuoteRequestWipSummaryModel>) => {
      state.quoteRequestWipList = [...(state.quoteRequestWipList ?? []), action.payload];
    },

    deleteWipComplete: (state, action: PayloadAction<{ quoteRequestWipId: string }>) => {
      state.quoteRequestWipList = (state.quoteRequestWipList ?? []).filter(
        (x) => x.quoteRequestWipId !== action.payload.quoteRequestWipId
      );
    },
    
    setQuoteCompare:(state, action: PayloadAction<CompareQuoteModel>) => {
      state.compareQuote = action.payload;
    }
  },
});

export const {
  loading,
  loadingComplete,
  loadRequoteHistoryListCompleted,
  loadWipListCompleted,
  searchCompleted,
  clearSearchResults,
  wipCreated,
  deleteWipComplete,
  setQuoteCompare,
} = wipListSlice.actions;

export const loadWipListAsync = (): AppThunk<void> => (dispatch) => {
  dispatch(loading());

  const action: HttpAction<QuoteRequestWipSummaryModel[]> = {
    type: HTTP,
    meta: {
      promise: () => quoteRequestWipListService.getWipList(),
      resolve: (data: QuoteRequestWipSummaryModel[]) => {
        dispatch(loadWipListCompleted(data));
        dispatch(loadingComplete());
      },
      reject: (error: HttpError) => {
        dispatch(loadingComplete());
      },
    },
  };

  dispatch(action);
};

export const loadRequoteHistoryListAsync = (): AppThunk<void> => (dispatch) => {
  dispatch(loading());

  const action: HttpAction<RequoteHistoryModel[]> = {
    type: HTTP,
    meta: {
      promise: () => requoteHistoryListService.getRequoteHistoryList(),
      resolve: (data: RequoteHistoryModel[]) => {
        dispatch(loadRequoteHistoryListCompleted(data));
        dispatch(loadingComplete());
      },
      reject: (error: HttpError) => {
        dispatch(loadingComplete());
      },
    },
  };

  dispatch(action);
};

export const quoteSearchAsync = (quoteRefId: string): AppThunk<void> => (dispatch) => {
  dispatch(loading());

  quoteRefId = quoteRefId.trim().toUpperCase();

  const action: HttpAction<QuoteRequestSearchSummaryModel[]> = {
    type: HTTP,
    meta: {
      promise: () => quoteRequestSearchService.search(quoteRefId),
      resolve: (data: QuoteRequestSearchSummaryModel[]) => {
        dispatch(searchCompleted(data));
        dispatch(loadingComplete());
      },
      reject: (error: HttpError) => {
        dispatch(loadingComplete());
      },
    },
  };

  dispatch(action);
};

export const startRequoteAsync = (
  quoteRefId: string,
  successCallback: (wip: QuoteRequestWipSummaryModel) => void
): AppThunk<void> => (dispatch) => {
  dispatch(loading());

  const action: HttpAction<QuoteRequestWipSummaryModel> = {
    type: HTTP,
    meta: {
      promise: () => quoteRequestWipService.create(quoteRefId ?? ""),
      resolve: (data: QuoteRequestWipSummaryModel) => {
        dispatch(wipCreated(data));
        dispatch(loadingComplete());

        successCallback(data);
      },
      reject: (error: HttpError) => {
        dispatch(loadingComplete());
      },
    },
  };

  dispatch(action);
};

export const resumeWipAsync = (
  quoteRequestWipId: string,
  callback?: (success: boolean) => void
): AppThunk<void> => (dispatch) => {
  dispatch(loading());

  const action: HttpAction<QuoteRequestWipModel | null> = {
    type: HTTP,
    meta: {
      promise: () => quoteRequestWipService.get(quoteRequestWipId),
      resolve: (data: QuoteRequestWipModel | null) => {
        if (data) {
          dispatch(resumeWorkInProgress(data));
          callback && callback(true);
        }
        dispatch(loadingComplete());
        callback && callback(false);
      },
      reject: (error: HttpError) => {
        dispatch(loadingComplete());
        callback && callback(false);
      },
    },
  };

  dispatch(action);
};

export const deleteWipAsync = (quoteRequestWipId: string): AppThunk<void> => (dispatch) => {

  dispatch(loading());

  const action: HttpAction<void> = {
    type: HTTP,
    meta: {
      promise: () => quoteRequestWipService.deleteWip(quoteRequestWipId),
      resolve: () => {
        dispatch(deleteWipComplete({ quoteRequestWipId }));
        dispatch(loadingComplete());
      },
      reject: (error: HttpError) => {
        dispatch(loadingComplete());
      }
    },
  };

  dispatch(action);
};

export const selectWipList = (state: RootState) => {
  return state.wipList.quoteRequestWipList;
};

export const selectSearchResults = (state: RootState) => {
  return state.wipList.searchResults;
};

export const selectRequoteHistoryList = (state: RootState) => {
  return state.wipList.requoteHistoryList;
};

export const selectQouteCompare = (state: RootState) => {
  return state.wipList.compareQuote;
};

export default wipListSlice.reducer;
