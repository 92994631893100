import * as React from "react";
import ReactDOM from "react-dom";
import Confirm, { ConfirmProps } from "../../components/Confirm";

export function confirm(options: {} & ConfirmProps): void {
  const container = document.createElement("div");
  document.body.appendChild(container);

  const confirmed = (): void => {
    options.onConfirm();
    cleanup();
  };

  const cancelled = (): void => {
    options.onCancel && options.onCancel();
    cleanup();
  };

  const cleanup = (): void => {
    ReactDOM.unmountComponentAtNode(container);
    container.parentNode && container.parentNode.removeChild(container);
  };

  const props: ConfirmProps = {
    header: options.header,
    body: options.body,
    onConfirm: confirmed,
    onCancel: cancelled,
  };

  const dialogInstance = React.createElement(Confirm, props);
  ReactDOM.render(dialogInstance, container);
}
