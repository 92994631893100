import { AssetCovergeModel, AssetCoverage } from "./RequoteApiTypes";
import axios, { AxiosResponse } from "axios";
import CONSTANTS from "../constants";

export async function saveCoverage(
    quoteRequestWipId: string,
    request: AssetCovergeModel
  ): Promise<AssetCovergeModel> {
    const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.ASSET.COVERAGE.SAVE(quoteRequestWipId);
    const response = await axios.post<AssetCovergeModel, AxiosResponse<AssetCoverage>>(
      url,
      request
    );
  
    return response.data;
  }