import { QuoteRequestWipSummaryModel } from "./RequoteApiTypes";
import axios from "axios";
import CONSTANTS from "../constants";

export async function getWipList(
): Promise<QuoteRequestWipSummaryModel[]> {
  const url = `${window.REACT_APP_API_BASEURL + CONSTANTS.API.QUOTE_REQUEST_WIP_LIST.GET}`;

  const response = await axios.get<QuoteRequestWipSummaryModel[]>(url);

  return response.data;
}
