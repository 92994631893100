import { useEffect } from "react";
import { Container, Form, Button, Row, Col, Alert, Spinner, Card } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { ReactDatePicker } from "../../components/ReactDatePicker";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLoadingState,
  selectBrokers,
  selectInsuranceScore,
  requestInsuranceScoreAsync,
  resetState,
  loadBrokers,
} from "./insuranceScoreSlice";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { InsuranceScoreViewModel } from "./model/insurance-score-view-model";
import { BrokerMetadata, InsuranceScoreModel } from "../../service/RequoteApiTypes";
import RoleGuard from "../../components/RoleGuard";
import { convertDateToString } from "./../../utils/helpers/dateUtils";
import "./InsuranceScore.scss";
const validationSchema = Yup.object().shape({
  policyNumber: Yup.string()
    .length(9, "*Policy number must be 9 characters")
    .required("*Policy number is required"),
  firstName: Yup.string()
    .max(100, "*Names can't be longer than 100 characters")
    .matches(
      /^([A-Za-z-`'.]+\\s)*[a-zA-Z-`'.]+$/,
      "Name cannot include special characters or spaces"
    )
    .required("*First name is required"),
  lastName: Yup.string()
    .max(100, "*Names can't be longer than 100 characters")
    .matches(
      /^([A-Za-z-`'.]+\\s)*[a-zA-Z-`'.]+$/,
      "Name cannot include special characters or spaces"
    )
    .required("*Last name is required"),
  birthDate: Yup.date().required("*Date of birth is required"),
  postalCode: Yup.string()
    .max(7, "Postal code can't be longer than 7 digits")
    .matches(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, "Invalid postal code")
    .required("*Postal code is required"),
  broker: Yup.object().required("*Broker is required"),
});

let brokerRef: Typeahead<BrokerMetadata> | null;
const InsuranceScore = () => {
  const dispatch = useDispatch();
  var brokersMetaData = useSelector(selectBrokers);
  const insuranceScore = useSelector(selectInsuranceScore);
  const loadingState = useSelector(selectLoadingState);

  useEffect(() => {
    dispatch(loadBrokers());
  }, []);
  
  const onSubmit = (
    values: InsuranceScoreViewModel,
    helpers: FormikHelpers<InsuranceScoreViewModel>
  ) => {
    helpers.setSubmitting(true);

    dispatch(
      requestInsuranceScoreAsync(
        {
          policyNumber: values.policyNumber,
          firstName: values.firstName.toLocaleUpperCase(),
          lastName: values.lastName.toLocaleUpperCase(),
          birthDate: convertDateToString(values.birthDate),
          postalCode: values.postalCode.replace(" ", ""),
          brokerNumber: values.broker?.number ?? "",
          officeNumber: values.broker?.officeNumber ?? "",
          creditScoreMessage: "",
        },
        (model: InsuranceScoreModel) => {
          if (model.isCreditScoreHit == true) {
            helpers.resetForm();
          }

          helpers.setSubmitting(false);
        }
      )
    );
  };

  const UnauthorizedTemplate = <div>User is not authorized for insurance score.</div>;

  return (
    <div>
      <div className="ins-score-header d-flex p-3">
        <div className="d-flex flex-column flex-grow-1 align-self-center">
          <h1 className="d-block">Request Insurance Score</h1>
        </div>
      </div>
      <div>
      <RoleGuard role="Quote.Create" UnauthorizedTemplate={UnauthorizedTemplate}>
        <Alert
          variant={(insuranceScore.isCreditScoreHit && !insuranceScore.creditScoreMessage) ? "success" : "danger"}
          show={loadingState.isLoaded}
          onClose={() => dispatch(resetState())}
          dismissible>
          {(insuranceScore.isCreditScoreHit && !insuranceScore.creditScoreMessage) ? (
            <span>
              Success – a match was found! Your quote reference ID is{" "}
              <b>{insuranceScore.uniqueId}</b>. Enter this in GIS and you are golden.
            </span>
          ) : (
            insuranceScore.creditScoreMessage ? 
            <span>
              {insuranceScore.creditScoreMessage}
            </span> :
            <span>
              Sorry, we could not find a match for this individual. Confirm the details are
              correct and give it another shot. I believe in you!
            </span>
          )}
        </Alert>
        <Formik
          initialValues={new InsuranceScoreViewModel()}
          validationSchema={validationSchema}
          onReset={(
            values: InsuranceScoreViewModel,
            helpers: FormikHelpers<InsuranceScoreViewModel>
          ): void => {
            // Hides the Alert message
            dispatch(resetState());
            brokerRef?.clear();
          }}
          onSubmit={onSubmit}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            resetForm,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div className="ins-score-main">
                <Form.Group className="wdp-20">
                  <Form.Label htmlFor="policyNumber">Policy Number</Form.Label>
                  <Form.Control
                    type="text"
                    id="policyNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.policyNumber}
                    isInvalid={touched.policyNumber && errors.policyNumber ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.policyNumber}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="wdp-20">
                  <Form.Label htmlFor="firstName">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.firstName}
                    isInvalid={touched.firstName && errors.firstName ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="wdp-20">
                  <Form.Label htmlFor="lastName">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    id="lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.lastName}
                    isInvalid={touched.lastName && errors.lastName ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label htmlFor="birthDate">Date of Birth</Form.Label>
                  <br />
                  <div className={touched.birthDate && errors.birthDate ? "is-invalid" : ""}>
                    <ReactDatePicker
                      name="birthDate"
                      value={values.birthDate}
                      className={
                        touched.birthDate && errors.birthDate
                          ? "form-control is-invalid"
                          : "form-control"
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {errors.birthDate}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="wdp-20">
                  <Form.Label htmlFor="postalCode">Postal Code</Form.Label>
                  <Form.Control
                    type="text"
                    id="postalCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.postalCode}
                    isInvalid={touched.postalCode && errors.postalCode ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.postalCode}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="wdp-30">
                  <Form.Label>Broker</Form.Label>
                  <div className={touched.broker && errors.broker ? "is-invalid" : ""}>
                    <Typeahead
                      id="brokers-list"
                      data-testid="brokers-list"
                      ref={(i) => {
                        brokerRef = i;
                      }}
                      clearButton
                      onChange={(selected) => {
                        var value = selected.length > 0 ? selected[0] : undefined;
                        setFieldValue("broker", value);
                      }}
                      onBlur={(e) => setFieldTouched("broker", true)}
                      options={brokersMetaData}
                      labelKey={(option) => `${option.name}`}
                      isInvalid={touched.broker && errors.broker ? true : false}
                    />
                  </div>
                  <Form.Text className="text-muted pb-5">
                    Display Format - [Broker #]-[Office #] [Broker Name 1] [[Broker Name 2]]
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.broker}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <Form.Row>
                <div className="ins-score-footer pt-2 pb-2 text-center">
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => resetForm()}>
                    Clear Form
                  </Button>
                  <Button
                    variant="primary"
                    type="submit"
                    className="ml-2"
                    disabled={isSubmitting}
                    data-testid="submit-button">
                    {loadingState?.isLoading && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    )}
                    Submit
                  </Button>
                </div>
              </Form.Row>
            </form>
          )}
        </Formik>
      </RoleGuard>
      </div>
    </div>
  );
};

export default InsuranceScore;
