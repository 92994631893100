import moment from "moment";

export const convertDateToDisplayString = (value?: moment.Moment | Date | string | null): string => {
  return value ? moment(value).format("DD-MMM-YYYY") : "";
};

export const convertUtcDateToDisplayString = (value?: moment.Moment | Date | string | null): string => {
  return value ? moment.utc(value).format("DD-MMM-YYYY") : "";
};

export const convertDateToString = (value?: moment.Moment | Date | string | null): string => {
  return value ? moment(value).format("YYYY-MM-DD") : "";
};
