import {useRef} from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { Formik, FieldArray, FieldArrayRenderProps } from "formik";
import { ConvictionTypeCode, SupplementViewModel } from "../../../../service/RequoteApiTypes";
import { ReactDatePicker } from "../../../../components/ReactDatePicker";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import { selectSelectedConviction, saveConviction, cancelEditing } from "./convictionSlice";
import { updateValidation } from "../../../../rootValidationSlice";
import { useDispatch, useSelector } from "react-redux";
import "./Editor.css";
import * as Yup from "yup";
import { convertUtcDateToDisplayString } from "../../../../utils/helpers/dateUtils";
import { selectConvictionTypeCodes } from "./../../../../appRootSlice";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import ConvictionInputModel from "./models/ConvictionInputModel";
import SupplementEditor from "./supplement/SingleSupplementEditor";
import { v4 as uuidv4 } from "uuid";

const validationSchema = Yup.object().shape({
  convictionType: Yup.object().nullable().required("*Description is required"),
  convictionDate: Yup.date()
    .nullable()
    .required("Conviction date is required")
    .max(new Date(), "Conviction date must be less than or equal to today")
    .min("1900-01-01", "Conviction date year must be on or after 1900"),
  supplements: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().nullable().matches(/^\d+$/, "Supplement value must be numeric"),
    })
  ),
});

const Editor: React.FC = () => {
  const dispatch = useDispatch();
  const selectedQuoteRequest = useSelector(selectQuoteRequest);

  const convictionTypeCodes = [...useSelector(selectConvictionTypeCodes)].sort((a, b) =>
    (a.description ?? "") > (b.description ?? "") ? 1 : -1
  );

  const selectedConviction = useSelector(selectSelectedConviction);

  const data: ConvictionInputModel = {
    entityId: "",
    convictionId: "",
    supplements: [],
    ...selectedConviction,
    convictionType: selectedConviction?.typeCode
      ? convictionTypeCodes.find((c) => c.codeValue == selectedConviction?.typeCode)
      : undefined,
    convictionDate: convertUtcDateToDisplayString(selectedConviction?.convictionDate),
  };

  const isConvictionTypeWithSupplements = (
    values: ConvictionInputModel,
    arrayHelpers: FieldArrayRenderProps
  ): boolean => {
    // not the right place for logic such as this.. also not good logic.
    const currentDesc =
      convictionTypeCodes.find((x) => x.codeValue === values.convictionType?.codeValue)
        ?.description ?? "";

    // Better to have a business rule that indicates whether excess speed is allowed or not
    const matches = currentDesc.toLowerCase().includes("speed");

    if (matches && (values.supplements ?? []).length == 0) {
      arrayHelpers.remove(0);
      AddSupplement(arrayHelpers);
    }

    return matches;
  };

  const AddSupplement = (arrayHelpers: FieldArrayRenderProps) => {
    const supplement = {
      supplementId: uuidv4(),
      typeCode: "excess speed",
      value: "",
    } as SupplementViewModel;

    arrayHelpers.push(supplement);
  };
  const rootValidRef = useRef(null) as any;
  const validate = () =>
  {
    dispatch(updateValidation({formName: "convictionEditor", validationFail: Object.keys(rootValidRef.current.errors).length > 0 ? true : false}));
  };

  const onCancel = () => {
    dispatch(updateValidation({formName: "convictionEditor", validationFail: false}));
    dispatch(cancelEditing());
  };

  const onSubmit = (values: ConvictionInputModel) => {
    const postData = {
      ...data,
      typeCode: values.convictionType?.codeValue as ConvictionTypeCode,
      supplements: values.supplements,
      convictionDate: values.convictionDate,
    };
    dispatch(saveConviction(selectedQuoteRequest.quoteRequestWipId as string, postData));
  };

  return (
    <Modal show={selectedConviction != undefined} className="conviction-editor" onHide={onCancel} keyboard={false} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{data.convictionId ? "Edit Conviction" : "Add New Conviction"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={data} validationSchema={validationSchema} innerRef={rootValidRef} validate={validate} onSubmit={onSubmit}>
          {({
            touched,
            errors,
            values,
            handleChange,
            handleSubmit,
            setFieldValue,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Form.Group>
                <Form.Label>Date</Form.Label>
                <br />
                <div
                  className={touched.convictionDate && errors.convictionDate ? "is-invalid" : ""}>
                  <ReactDatePicker
                    name="convictionDate"
                    value={values.convictionDate}
                    className={
                      touched.convictionDate && errors.convictionDate
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={handleChange}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.convictionDate}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Description</Form.Label>
                <div
                  className={touched.convictionType && errors.convictionType ? "is-invalid" : ""}>
                  <Typeahead
                    id="descriptions-list"
                    data-testid="descriptions-list"
                    clearButton
                    defaultSelected={values.convictionType ? [values.convictionType] : undefined}
                    onChange={(selected) => {
                      setFieldValue("supplements", []);
                      var value = selected.length > 0 ? selected[0] : undefined;
                      setFieldValue("convictionType", value);
                    }}
                    onBlur={(e) => setFieldTouched("convictionType", true)}
                    options={convictionTypeCodes}
                    labelKey={(option) => `${option.description}`}
                    isInvalid={touched.convictionType && errors.convictionType ? true : false}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.convictionType}
                </Form.Control.Feedback>
              </Form.Group>

              <FieldArray
                name="supplements"
                render={(arrayHelpers) => (
                  <>
                    {isConvictionTypeWithSupplements(values, arrayHelpers)
                      ? values.supplements
                          ?.filter((s) => s.typeCode == "excess speed")
                          .map((s, index) => (
                            <SupplementEditor
                              key={index}
                              data={s}
                              name={`supplements.${index}`}
                              listObject={`supplements[${index}]`}
                            />
                          ))
                      : null}
                  </>
                )}></FieldArray>

              <Form.Row className="modal-footer">
                <Button variant="secondary" type="button" onClick={onCancel}>
                  Cancel
                </Button>

                <Button variant="primary" type="submit" className="ml-2">
                  Submit
                </Button>
              </Form.Row>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default Editor;
