import React, {useRef, useEffect} from "react";
import { Button, Form } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { ReactDatePicker } from "../../../../components/ReactDatePicker";
import { RequestHeaderModel } from "service/RequoteApiTypes";
import { debounce } from "lodash";
import { saveQuoteDetails, selectQuoteRequest } from "../../quoteRequestSlice";
import { updateValidation } from "../../../../rootValidationSlice";
import { useSelector, useDispatch } from "react-redux";
import { convertUtcDateToDisplayString } from "../../../../utils/helpers/dateUtils";

interface Props {
  requestHeaderModel: RequestHeaderModel;
}

const QuoteDetailsEditor: React.FC<Props> = (props: Props) => {
  const selectedQuoteRequest = useSelector(selectQuoteRequest);
  const dispatch = useDispatch();
  const TwoYearsFromCurrentDate = new Date(new Date().setFullYear(new Date().getFullYear() - 2));
  const validationSchema = Yup.object().shape({
    termEffectiveDate: Yup.date()
      .nullable()
      .required("Term effective date is required")
      .min(TwoYearsFromCurrentDate, "Term effective date cannot be more than two years from today"),
  });

  const data: RequestHeaderModel = {
    ...props.requestHeaderModel,
    termEffectiveDate: convertUtcDateToDisplayString(props.requestHeaderModel.termEffectiveDate),
  };
  const rootValidRef = useRef(null) as any;
  const validate = () =>
  {
    dispatch(updateValidation({formName: "QuoteDetailsEditor", validationFail: Object.keys(rootValidRef.current?.errors).length > 0 ? true : false}));
  };
  useEffect(() => {
    return () => {
      dispatch(updateValidation({formName: "QuoteDetailsEditor", validationFail: false}));
    }
  }, []);
  const onSubmit = (values: RequestHeaderModel, helpers: FormikHelpers<RequestHeaderModel>) => {
    const postData = {
      ...data,
      termEffectiveDate: values.termEffectiveDate,
    };
    dispatch(saveQuoteDetails(selectedQuoteRequest?.quoteRequestWipId as string, postData));
  };
  return (
    <Formik initialValues={data} validationSchema={validationSchema} onSubmit={onSubmit} innerRef={rootValidRef} validate={validate}>
      {({ touched, errors, handleBlur, handleSubmit, handleChange }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Form.Group>
            <div
              className={touched.termEffectiveDate && errors.termEffectiveDate ? "is-invalid" : ""}>
              <ReactDatePicker
                name="termEffectiveDate"
                className={
                  touched.termEffectiveDate && errors.termEffectiveDate
                    ? "form-control is-invalid"
                    : "form-control"
                }
                onBlur={handleBlur}
                onCalendarClose={debounce(() => handleSubmit(), 250)}
                onChange={handleChange}
              />
            </div>
            <Form.Control.Feedback type="invalid">{errors.termEffectiveDate}</Form.Control.Feedback>
          </Form.Group>
        </form>
      )}
    </Formik>
  );
};

export default QuoteDetailsEditor;
