import { Jumbotron, Container, Button } from "react-bootstrap";
import { Switch, Route, useParams, useRouteMatch, useHistory, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import RoleGuard from "../../components/RoleGuard";
import SideBar from "./components/SideBar";
import QuoteDetails from "./features/quote-details/QuoteDetails";
import QuoteHeader from "./features/quote-header/QuoteHeader";
import ViewEntity from "./features/entity/ViewEntity";
import { resumeWipAsync, resumeWorkInProgress } from "./../wip-list/wipListSlice";
import { isNavigationCollapsed } from "./../quote-request-2/navigationSlice";
import { selectQuoteRequest, submitWipAsync } from "./quoteRequestSlice";
import { isRootValid } from "../../rootValidationSlice";
import { selectDisableSubmitButtonEnabled } from "../../appRootSlice";
import "./QuoteRequest.scss";
import { useEffect } from "react";
import SplashScreen from "./../../components/SplashScreen";
import { RootState } from "app/store";
import Claims from "./features/claim/Claims";
import Vehicles from "./features/vehicle/Vehicles"
import { selectClaimsEnabled, selectVehiclesEnabled } from "../../appRootSlice";

const QuoteRequest = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { url, path } = useRouteMatch();
  const { quoteRequestWipId } = useParams<{ quoteRequestWipId: string }>();

  const collapsed = useSelector(isNavigationCollapsed);
  const isLoading = useSelector((rootState: RootState) => rootState.wipList.isLoading);
  const isDisableSubmitButtonFeatureFlagEnabled = useSelector(selectDisableSubmitButtonEnabled);
  let isRootValidated = useSelector(isRootValid);
  if (!isDisableSubmitButtonFeatureFlagEnabled) isRootValidated = true;
  const quoteRequest = useSelector(selectQuoteRequest);
  const isClaimsEnabled = useSelector(selectClaimsEnabled);
  const isVehiclesEnabled = useSelector(selectVehiclesEnabled);
  
  // Loads Quote Request
  useEffect(() => {
    dispatch(resumeWipAsync(quoteRequestWipId));
  }, [quoteRequestWipId]);

  // Dispose loaded Quote Request
  useEffect(() => {
    return () => {
      // Clean up / Dispose
      dispatch(resumeWorkInProgress(null));
    };
  }, []);

  if (isLoading) {
    return <SplashScreen text="Loading" />;
  }

  if (!quoteRequest.sourceQuoteRequest) {
    return (
      <Jumbotron className="bg-white">
        <Container>
          <h1>Nothing to edit</h1>
          <p>The quote request does not exist or it has been processed already.</p>
        </Container>
      </Jumbotron>
    );
  }

  const handleRequote = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(
      submitWipAsync(quoteRequestWipId, (success) => {
        if (success) {
          history.push("/wip/recent");
        }
      })
    );
  };

  const UnauthorizedTemplate = (
    <Jumbotron className="bg-white">
      <Container>
        <h1>Access Denied</h1>
        <p>User is not authorized for requote.</p>
      </Container>
    </Jumbotron>
  );

  return (
    <RoleGuard role="Quote.Create" UnauthorizedTemplate={UnauthorizedTemplate}>
      <div className="requote-request d-flex position-relative h-100">
        <SideBar />

        <div
          className={classNames("requote-content", {
            "sidebar-collapsed": collapsed,
          })}>
          <QuoteHeader />

          <Switch>
            <Route path={`${path}/quote-details`} component={QuoteDetails} />
            <Route path={`${path}/applicant/:entityId`} component={ViewEntity} />
            <Route path={`${path}/driver/:entityId`} component={ViewEntity} />
            <Route path={`${path}`} exact>
              <Redirect to={`${url}/quote-details`} />
            </Route>
            {isClaimsEnabled && <Route path={`${path}/claims`} component={Claims} />}
            {isVehiclesEnabled && <Route path={`${path}/vehicles`} component={Vehicles} />}
          </Switch>
        </div>

        <div
          className={classNames("requote-actions position-fixed pt-2 pb-2 text-center", {
            "sidebar-collapsed": collapsed,
          })}>
          <Button
            variant="secondary"
            className="mr-2 text-uppercase"
            data-testid="BackToList"
            onClick={() => history.push("/wip/active")}>
            Back to list
          </Button>
          <Button
            variant="primary"
            disabled={!isRootValidated}
            className="text-uppercase"
            data-testid="SubmitRequote"
            onClick={(e) => handleRequote(e)}>
            Submit Requote
          </Button>
        </div>
      </div>
    </RoleGuard>
  );
};

export default QuoteRequest;
