import React, { useState } from "react";
import { useEffect } from "react"
import { useSelector } from "react-redux";
import classNames from "classnames";
import { Card, Accordion } from "react-bootstrap";
import { ChevronRight, ChevronDown } from "react-bootstrap-icons";
import ListView from "./ListView"
import { VehicleModel } from "service/RequoteApiTypes";
import { selectVehicles } from "../vehicle/vehicleSlice";
import "./Vehicles.scss";

const Vehicles: React.FC = () => {
    const vehicles: VehicleModel[] = useSelector(selectVehicles);
    const [selectedEventKeys, setSelectedEventKeys] = useState([""]);
    const handleAccordianSelection = (eventKey: string) => {
        const existing = [...selectedEventKeys];
        if (existing.indexOf(eventKey) > -1) {
          existing.splice(existing.indexOf(eventKey), 1);
        } else {
          existing.push(eventKey);
        }
        setSelectedEventKeys(existing);
    };

    useEffect(() => {
        setSelectedEventKeys(vehicles?.map(p => p.assetId ?? ""));
    },[]);
    
    return (
        <div className="vehicle-view">
            {vehicles.map((m) => {
                return (
                <Accordion defaultActiveKey={m.assetId ?? "Vehicle"} key={m.assetId ?? "Vehicle"}>
                    <Card>
                        <Accordion.Toggle
                            as={Card.Header}
                            eventKey={m.assetId ?? "Vehicle"}
                            className="bg-white border-0"
                            onClick={() => handleAccordianSelection(m.assetId ?? "Vehicle")}>
                            <span
                            className={classNames("toggle-icon", {
                                expanded: selectedEventKeys.indexOf(m.assetId ?? "Vehicle") > -1,
                            })}>
                            <ChevronRight
                                className={classNames({ "d-none": selectedEventKeys.indexOf(m.assetId ?? "Vehicle") > -1 })}
                            />
                            <ChevronDown
                                className={classNames({ "d-none": selectedEventKeys.indexOf(m.assetId ?? "Vehicle") == -1 })}
                            />
                            </span>
                            <h4>{m.modelYear + " " + m.manufacturer + " " + m.model}</h4>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={m.assetId ?? "Vehicle"}>
                            <Card.Body>
                                <ListView key={m.assetId} data={m} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                );
            })}
        </div>
    )
}

export default Vehicles;