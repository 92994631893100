import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ClaimModel } from "../../../../service/RequoteApiTypes";
import { addClaim, selectClaims } from "./claimSlice";
import ListItemView from "./ListItemView";
import ClaimsEditor from "./ClaimsEditor";
import { PlusSquareFill } from "react-bootstrap-icons";
import { convertDateToString } from "../../../../utils/helpers/dateUtils";

const Claims: React.FC = () => {
  const dispatch = useDispatch();
  const claims: ClaimModel[] = useSelector(selectClaims);

  const onAddNew = () => {
    const newClaim = {
      claimId: "",
      claimNumber: "",
      chargeableClaimIndicator: "true",
      claimSourceCode: "other",
      underwritingUseCode: null,
      gridUseCode: null,
      addedDate: convertDateToString(new Date()),
      relationship: [{ asset_id: "", entity_id: "", type_code: "involved in claim" }],
      claimant: [
        { claimCoverageId: "", accountTypeCode: "indemnity", coverageTypeCode: null, amount: null },
      ],
    } as ClaimModel;

    dispatch(addClaim(newClaim));
  };

  return (
    <div>
      <Row className="mb-2">
        <Col className="text-right">
          <Button variant="primary" size="sm" className="add-new-item" onClick={onAddNew}>
            <PlusSquareFill className="mr-2"></PlusSquareFill> Add New
          </Button>
        </Col>
      </Row>

      <ClaimsEditor></ClaimsEditor>
      {claims.length > 0 ? (
        <Table striped className="mb-5">
          <thead>
            <tr>
              <th>Claim Loss Date</th>
              <th>Description</th>
              <th>Responsibility</th>
              <th>Payouts</th>
              <th>Vehicle</th>
              <th>Driver</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {claims.map((e, index) => (
              <ListItemView data={e} key={index}></ListItemView>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="no-data">No claims information</div>
      )}
    </div>
  );
};

export default Claims;
