import { createAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../app/store";
import { EntityModel } from "../../../../service/RequoteApiTypes";
import { resumeWorkInProgress } from "../../../wip-list/wipListSlice";
import CONSTANTS from "../../../../constants";

interface EntityState {
  entities: EntityModel[];
  selectedEntity?: EntityModel;

  isLoading: boolean;
  error: string | null;
}

const initialState: EntityState = {
  entities: [],
  isLoading: false,
  error: null,
};

export const setSelectedEntity =
  createAction<{ entityId: string | undefined }>("setSelectedEntity");

export const quoteRequestEntitySlice = createSlice({
  name: "entity",
  initialState,
  reducers: {
    loading: (state) => {
      state.isLoading = true;
    },

    loadingComplete: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resumeWorkInProgress, (state, action) => {
        state.entities = action.payload?.entities ?? [];
        state.selectedEntity = undefined;
      })
      .addCase(setSelectedEntity, (state, action) => {
        state.selectedEntity = state.entities.find((e) => e.entityId == action.payload.entityId);
      });
  },
});

export const { loading, loadingComplete } = quoteRequestEntitySlice.actions;

export const selectEntities = (state: RootState): EntityModel[] =>
  state.quoteRequestEntity.entities ?? [];

export const selectEntity = (state: RootState): EntityModel | undefined =>
  state.quoteRequestEntity.selectedEntity;

export const selectIsApplicant = (state: RootState): boolean =>
  state.quoteRequestEntity.selectedEntity?.roles?.includes(CONSTANTS.ENTITY_ROLES.APPLICANT) ??
  false;

export default quoteRequestEntitySlice.reducer;
