import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { PolicyHistoryModel } from "../../../../service/RequoteApiTypes";
import * as policyHistoryService from "../../../../service/policyHistoryService";
import { resumeWorkInProgress } from "../../../wip-list/wipListSlice";
import { v4 as uuidv4 } from "uuid";
import { HTTP, HttpAction } from "./../../../../app/middlewares/http/types";
import { listInsuranceEvents } from "../insuranceEvent/insuranceEventSlice";

interface PolicyHistoryState {
  error: string | null;
  policyHistories: PolicyHistoryModel[];
  selectedPolicyHistory?: PolicyHistoryModel;
}

const initialState: PolicyHistoryState = {
  error: null,
  policyHistories: [],
};

export const PolicyHistorySlice = createSlice({
  name: "policyHistory",
  initialState: initialState,
  reducers: {
    loadAction: (state, action: PayloadAction<PolicyHistoryModel[]>) => {
      const payload = action.payload;

      payload.forEach((item) => {
        const policyHistoryIndex = state.policyHistories.findIndex(
          (e) => e.policyHistoryId === item.policyHistoryId
        );
        if (policyHistoryIndex > -1) {
          state.policyHistories[policyHistoryIndex] = item;
        } else {
          state.policyHistories.push(item);
        }
      });

      state.selectedPolicyHistory = undefined;
    },
    addAction: (state, action: PayloadAction<PolicyHistoryModel>) => {
      state.selectedPolicyHistory = action.payload;
    },
    editAction: (state, action: PayloadAction<PolicyHistoryModel>) => {
      state.selectedPolicyHistory = action.payload;
    },
    cancelEditAction: (state) => {
      state.selectedPolicyHistory = undefined;
    },
    saveAction: (state, action: PayloadAction<PolicyHistoryModel>) => {
      const payload = action.payload;

      const policyHistoryIndex = state.policyHistories.findIndex(
        (e) => e.policyHistoryId === payload.policyHistoryId
      );
      if (policyHistoryIndex > -1) {
        state.policyHistories[policyHistoryIndex] = payload;
      } else {
        state.policyHistories.push(payload);
      }

      state.selectedPolicyHistory = undefined;
    },
    deleteAction: (state, action: PayloadAction<PolicyHistoryModel>) => {
      state.policyHistories.splice(
        state.policyHistories.findIndex(
          (e) => e.policyHistoryId === action.payload.policyHistoryId
        ),
        1
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resumeWorkInProgress, (state, action) => {
      state.policyHistories = action.payload?.policyHistories ?? [];
    });
  },
});

export const addPolicyHistory =
  (policyHistory: PolicyHistoryModel): AppThunk<void> =>
  async (dispatch) => {
    dispatch(addAction(policyHistory));
  };

export const editPolicyHistory =
  (policyHistory: PolicyHistoryModel): AppThunk<void> =>
  async (dispatch) => {
    dispatch(editAction(policyHistory));
  };

export const cancelEditPolicyHistory = (): AppThunk<void> => async (dispatch) => {
  dispatch(cancelEditAction());
};

export const listPolicyHistory =
  (quoteRequestWipId: string, entityId: string): AppThunk<void> =>
  async (dispatch) => {
    const action: HttpAction<PolicyHistoryModel[]> = {
      type: HTTP,
      meta: {
        promise: () => policyHistoryService.listPolicyHistory(quoteRequestWipId, entityId),
        resolve: (response: PolicyHistoryModel[]) => {
          dispatch(loadAction(response));
        },
      },
    };

    return dispatch(action);
  };

export const savePolicyHistory =
  (quoteRequestWipId: string, policyHistory: PolicyHistoryModel): AppThunk<void> =>
  async (dispatch) => {
    policyHistory.policyHistoryId =
      (policyHistory.policyHistoryId ?? "").length == 0 ? uuidv4() : policyHistory.policyHistoryId;

    const action: HttpAction<PolicyHistoryModel> = {
      type: HTTP,
      meta: {
        promise: () => policyHistoryService.savePolicyHistory(quoteRequestWipId, policyHistory),
        resolve: (response: PolicyHistoryModel) => {
          dispatch(listPolicyHistory(quoteRequestWipId, policyHistory.entityId));
          dispatch(listInsuranceEvents(quoteRequestWipId, policyHistory.entityId));
        },
      },
    };

    return dispatch(action);
  };

export const deletePolicyHistory =
  (quoteRequestWipId: string, policyHistory: PolicyHistoryModel): AppThunk<void> =>
  async (dispatch) => {
    const action: HttpAction<void> = {
      type: HTTP,
      meta: {
        promise: () =>
          policyHistoryService.deletePolicyHistory(
            quoteRequestWipId,
            policyHistory.entityId,
            policyHistory.policyHistoryId
          ),
        resolve: () => {
          dispatch(deleteAction(policyHistory));
        },
      },
    };

    return dispatch(action);
  };

export const selectNonAutoPolicyHistory = (rootState: RootState) => {
  return rootState.policyHistory.policyHistories.filter(
    (f) => f.policyStatus === "other" && f.lineOfBusiness !== "auto"
  );
};

export const selectPolicyHistory = (rootState: RootState) => {
  return rootState.policyHistory.policyHistories
    .filter(
      (f) =>
        f.policyStatus === "prior" &&
        f.lineOfBusiness === "auto" &&
        (f.transferReason === null ||
          f.transferReason === "7" ||
          f.transferReason === "" ||
          f.transferReason === "2")
    )
    .sort((a, b) => {
      if (a.inceptionDate === b.inceptionDate) {
        return (
          parseInt(b.transferReason ? b.transferReason : "999") -
          parseInt(a.transferReason ? a.transferReason : "999")
        );
      }

      return (
        (a.inceptionDate ? 0 : 1) - (b.inceptionDate ? 0 : 1) ||
        new Date(a.inceptionDate ?? "").valueOf() - new Date(b.inceptionDate ?? "").valueOf()
      );
    });
};

export const selectPolicyHistoryWithRenewal = (rootState: RootState) => {
  return rootState.policyHistory.policyHistories
    .filter(
      (f) =>
        f.policyStatus === "prior" &&
        f.lineOfBusiness === "auto" &&
        (f.transferReason === null ||
          f.transferReason === "7" ||
          f.transferReason === "" ||
          f.transferReason === "2")
    )
    .sort(
      (a, b) =>
        (a.inceptionDate ? 0 : 1) - (b.inceptionDate ? 0 : 1) ||
        new Date(a.inceptionDate ?? "").valueOf() - new Date(b.inceptionDate ?? "").valueOf()
    );
};

export const selectSelectedPolicyHistory = (rootState: RootState) =>
  rootState.policyHistory.selectedPolicyHistory;

const { deleteAction, addAction, editAction, cancelEditAction, saveAction, loadAction } =
  PolicyHistorySlice.actions;

export default PolicyHistorySlice.reducer;
