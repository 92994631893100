import { QuoteRequestSearchSummaryModel } from "./RequoteApiTypes";
import axios from "axios";
import CONSTANTS from "../constants";

export async function search(quoteRefId: string): Promise<QuoteRequestSearchSummaryModel[]> {
  const url = `${window.REACT_APP_API_BASEURL + CONSTANTS.API.QUOTE_REQUEST_SEARCH.GET
    }?quoteRefId=${encodeURIComponent("" + quoteRefId)}`;

  const response = await axios.get<QuoteRequestSearchSummaryModel[]>(url);
  return response.data;
}
