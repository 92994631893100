import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";
import { InteractionType } from "@azure/msal-browser";
import { MsalProvider, MsalAuthenticationTemplate } from "@azure/msal-react";
import Layout from "./components/Layout";
import Home from "./components/Home";
import EnvironmentWatermark from "./components/EnvironmentWatermark";
import SplashScreen from "./components/SplashScreen";
import InsuranceScore from "./features/insurance-score/InsuranceScore";
import WipList from "./features/wip-list/WipList";
import { getReferenceData, selectIsAppReady, getActiveFeatureFlags } from "./appRootSlice";
import { getLicenseClasses } from "./features/quote-request-2/features/license/licenseSlice";
import QuoteRequest from "./features/quote-request-2/QuoteRequest";
import { msalInstance } from "./utils/helpers/initializeMsal";
import { CustomNavigationClient } from "./utils/helpers/NavigationClient";
import "./App.css";

const RootStateInit = (props: { initialized: boolean }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReferenceData());
    dispatch(getActiveFeatureFlags());
    dispatch(getLicenseClasses());
  }, []);

  return <SplashScreen text="Loading Data" show={!props.initialized} />;
};

const App = () => {
  const history = useHistory();
  const navigationClient = new CustomNavigationClient(history);
  msalInstance.setNavigationClient(navigationClient);

  const isAppReady = useSelector(selectIsAppReady);

  return window.SPA_CONFIG_LOADED ? (
    <MsalProvider instance={msalInstance}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        loadingComponent={() => <SplashScreen text="Initializing" />}
        errorComponent={({ error }) => <SplashScreen text={error?.message} error={true} />}
        authenticationRequest={{
          scopes: window.REACT_APP_AD_AUTH_SCOPES,
        }}>
        <EnvironmentWatermark />
        <RootStateInit initialized={isAppReady} />
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/wip/:view/:page?" render={() => {
              const quoteId = new URLSearchParams(window.location.search).get('quoteId');
              return <WipList data={quoteId ?? ""} />;
            }} />
            <Route path="/quote-request/:quoteRequestWipId" component={QuoteRequest} />
            <Route path="/insurance-score" component={InsuranceScore} />
          </Switch>
        </Layout>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  ) : (
    <SplashScreen text="Initializing" />
  );
};

export default App;
