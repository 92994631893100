import React from "react";
import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectWipList } from "./../wipListSlice";
import WipListTableRow from "./WipListTableRow";

const WipListTable: React.FC = () => {
  const wipList = useSelector(selectWipList);

  return (
    <div className="pt-2 border border-top-0">
      {wipList != null && wipList?.length !== 0 ? (
        <Table striped hover className="mb-0">
          <thead>
            <tr>
              <th className="border-top-0">Quote Ref ID</th>
              <th className="border-top-0">Vendor</th>
              <th className="border-top-0">Broker Name</th>
              <th className="border-top-0">Broker Number</th>
              <th className="border-top-0"></th>
            </tr>
          </thead>
          <tbody>
            {wipList.map((item) => (
              <WipListTableRow key={item.quoteRequestWipId} data={item} />
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="pl-3 lead">No work in progress.</p>
      )}
    </div>
  );
};

export default WipListTable;
