import {useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { SuspensionInfoModel } from "../../../../service/RequoteApiTypes";
import { ReactDatePicker } from "../../../../components/ReactDatePicker";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import { selectSelectedSuspension, cancelEditing, saveSuspension } from "./suspensionSlice";
import { selectSuspensionTypeCodes } from "../../../../appRootSlice";
import { updateValidation } from "../../../../rootValidationSlice";
import "./Editor.css";
import { convertUtcDateToDisplayString } from "../../../../utils/helpers/dateUtils";

const validationSchema = Yup.object().shape({
  startDate: Yup.date().nullable().required("*Start Date is required"),
  dateReinstated: Yup.date().nullable().required("*Reinstatement Date is required"),
  suspensionTypeCode: Yup.string().required("*Description is required"),
});

const Editor: React.FC = () => {
  const dispatch = useDispatch();
  const selectedQuoteRequest = useSelector(selectQuoteRequest);
  const selectedSuspension = useSelector(selectSelectedSuspension);
  const suspensionTypeCodes = useSelector(selectSuspensionTypeCodes);

  const data: SuspensionInfoModel = {
    entityId: "",
    suspensionId: "",
    ...selectedSuspension,
    startDate: convertUtcDateToDisplayString(selectedSuspension?.startDate),
    dateReinstated: convertUtcDateToDisplayString(selectedSuspension?.dateReinstated),
  };
  const rootValidRef = useRef(null) as any;
  const validate = () =>
  {
    dispatch(updateValidation({formName: "suspensionEditor", validationFail: Object.keys(rootValidRef.current.errors).length > 0 ? true : false}));
  };
  const onCancel = () => {
    dispatch(updateValidation({formName: "suspensionEditor", validationFail: false}));
    dispatch(cancelEditing())
  };

  const onSubmit = (values: SuspensionInfoModel, helpers: FormikHelpers<SuspensionInfoModel>) => {
    const postData = {
      ...data,
      startDate: values.startDate,
      dateReinstated: values.dateReinstated,
      suspensionTypeCode: values.suspensionTypeCode,
    };
    dispatch(saveSuspension(selectedQuoteRequest?.quoteRequestWipId as string, postData));
  };
  return (
    <Modal show={selectedSuspension !== undefined} onHide={onCancel} className="suspension-editor" keyboard={false} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {data.suspensionId ? "Edit Suspension Information" : "Add Suspension Information"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik initialValues={data} validationSchema={validationSchema} innerRef={rootValidRef} validate={validate} onSubmit={onSubmit}>
          {({ touched, errors, values, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <Form.Group>
                <Form.Label htmlFor="suspensionTypeCode">Description</Form.Label>
                <Form.Control
                  className="custom-select"
                  as="select"
                  id="suspensionTypeCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.suspensionTypeCode ?? ""}
                  isInvalid={errors.suspensionTypeCode ? true : false}>
                  <option key="" value="" />
                  {suspensionTypeCodes &&
                    suspensionTypeCodes.map((t) => (
                      <option key={t.codeValue} value={t.codeValue as string}>
                        {t.description}
                      </option>
                    ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.suspensionTypeCode}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="startDate">Start Date</Form.Label>
                <br />
                <div className={touched.startDate && errors.startDate ? "is-invalid" : ""}>
                  <ReactDatePicker
                    name="startDate"
                    className={
                      touched.startDate && errors.startDate
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={handleChange}
                  />
                </div>
                <Form.Control.Feedback type="invalid">{errors.startDate}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label htmlFor="dateReinstated">Reinstatement Date</Form.Label>
                <br />
                <div
                  className={touched.dateReinstated && errors.dateReinstated ? "is-invalid" : ""}>
                  <ReactDatePicker
                    name="dateReinstated"
                    className={
                      touched.dateReinstated && errors.dateReinstated
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    onChange={handleChange}
                  />
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.dateReinstated}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Row className="modal-footer">
                <Button variant="secondary" type="button" onClick={onCancel}>
                  Cancel
                </Button>

                <Button variant="primary" type="submit" className="ml-2">
                  Submit
                </Button>
              </Form.Row>
            </form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default Editor;
