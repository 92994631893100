import { PersonalInformationViewModel } from "service/RequoteApiTypes";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resumeWorkInProgress } from "../../../wip-list/wipListSlice";
import { AppThunk, RootState } from "app/store";
import { HTTP, HttpAction } from "./../../../../app/middlewares/http/types";
import * as personalInformationService from "../../../../service/personalInformationService";

interface PersonalInformationState {
  error: string | null;
  personalInformation: PersonalInformationViewModel[];
  selectedPersonalInformation?: PersonalInformationViewModel;
}

const InitialState: PersonalInformationState = {
  error: null,
  personalInformation: [],
};

export const PersonalInformationSlice = createSlice({
  name: "personalInformation",
  initialState: InitialState,
  reducers: {
    editAction: (state, action: PayloadAction<PersonalInformationViewModel>) => {
      state.selectedPersonalInformation = action.payload;
    },
    cancelEditAction: (state) => {
      state.selectedPersonalInformation = undefined;
    },
    saveAction: (state, action: PayloadAction<PersonalInformationViewModel>) => {
      const payload = action.payload;

      const personalInformationIndex = state.personalInformation.findIndex(
        (e) => e.entityId === payload.entityId
      );

      state.personalInformation[personalInformationIndex] = payload;

      state.selectedPersonalInformation = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resumeWorkInProgress, (state, action) => {
      state.personalInformation = action.payload?.personalInformation ?? [];
    });
  },
});

export const editPersonalInformation =
  (personalInformation: PersonalInformationViewModel): AppThunk<void> =>
  async (dispatch) => {
    dispatch(editAction(personalInformation));
  };

export const savePersonalInformation =
  (quoteRequestWipId: string, personalInformation: PersonalInformationViewModel): AppThunk<void> =>
  async (dispatch) => {
    const action: HttpAction<PersonalInformationViewModel> = {
      type: HTTP,
      meta: {
        promise: () =>
          personalInformationService.savePersonalInformation(
            quoteRequestWipId,
            personalInformation
          ),
        resolve: (response: PersonalInformationViewModel) => {
          dispatch(saveAction(response));
        },
      },
    };

    return dispatch(action);
  };

export const cancelEditInsuranceInformation = (): AppThunk<void> => async (dispatch) => {
  dispatch(cancelEditAction());
};

export const selectPersonalInformation = (rootState: RootState) =>
  rootState.personalInformation.personalInformation;

export const selectSelectedPersonalInformation = (rootState: RootState) =>
  rootState.personalInformation.selectedPersonalInformation;

export const { editAction, cancelEditAction, saveAction } = PersonalInformationSlice.actions;

export default PersonalInformationSlice.reducer;
