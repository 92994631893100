import React, { useEffect, useState } from "react";
import { Formik, useFormikContext, FormikErrors } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col } from "react-bootstrap";
import { GridStepOverrideModel } from "service/RequoteApiTypes";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import { updateValidation } from "../../../../rootValidationSlice";
import {
  selectSelectedGridStepOverride,
  saveGridStepOverride,
} from "./gridStepOverrideSlice";
import LeavePageBlocker from "../../../../LeavePageBlocker";

const GridOverrideAutoSubmitListener = () => {
  const { submitForm, values } = useFormikContext<GridStepOverrideModel>() ?? {};
  const value = (values?.overrideLevel) as number | null;
  useEffect(() => {
    submitForm();
  }, [value, submitForm]);
  return null;
}

const GridView: React.FC<{ entityId: string }> = ({ entityId }) => {
  const dispatch = useDispatch();
  const selectedQuoteRequest = useSelector(selectQuoteRequest);
  const selectedGridStepOverride = useSelector(selectSelectedGridStepOverride);
  const [pendingValidation, setPendingValidation] = useState(false);
  const data: GridStepOverrideModel = {
    entityId: selectedGridStepOverride?.entityId ?? "",
    gridId: selectedGridStepOverride?.gridId ?? "",
    overrideLevel:
      (selectedGridStepOverride && selectedGridStepOverride.overrideLevel) ?? undefined,
  };
  
  const validationRegex = /^([1-9]?[0-9]|-([1-9]|[1][0-5]?)|^)$/; //allowed: [-15, 99] and blank
  const validationSchema = yup.object().shape({
    overrideLevel: yup.string().matches(validationRegex, "Value must be a whole number between -15 and 99"),
  });
  const rootValidation = (e: FormikErrors<GridStepOverrideModel>) => {
    if(Object.keys(e?.overrideLevel ?? {}).length > 0) {
      dispatch(updateValidation({formName: "GridStepEditor", validationFail: true}));
      setPendingValidation(true);
    } else {
      dispatch(updateValidation({formName: "GridStepEditor", validationFail: false}));
      setPendingValidation(false);
    } 
  }

  const submitGridOverrideForm = (data: GridStepOverrideModel) => {
    if(data && data.entityId === entityId)
      dispatch(
        saveGridStepOverride(
          selectedQuoteRequest?.quoteRequestWipId as string,
          data
        ));
  }

  return (
    <Formik 
        initialValues={data}
        onSubmit={submitGridOverrideForm}
        validationSchema={validationSchema}
      >
      {({ 
        values,
        errors,
        handleChange
       }) => (
         <>
          <LeavePageBlocker inValid={pendingValidation} formName="Grid Step Override" />
          <Form autoComplete="off">
            <Form.Group as={Row} controlId="formOverrideLevel">
              <Col sm={3} lg={2}>
                <Form.Label>Grid Step Override</Form.Label>
              </Col>
              <Col xs={8} sm={6} md={4}>
                <Form.Control
                  as="input"
                  name="overrideLevel"
                  placeholder="Input valid Grid Step or leave blank"
                  type="text"
                  onChange={handleChange}
                  value={values.overrideLevel ?? ""}
                  isInvalid={errors.overrideLevel ? true : false}
                />
                {errors.overrideLevel && (
                  <Form.Control.Feedback type="invalid">{errors.overrideLevel}</Form.Control.Feedback>
                )}
                {rootValidation(errors)}
                <GridOverrideAutoSubmitListener />
              </Col>
            </Form.Group>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default GridView;
