import classNames from "classnames";
import "./SplashScreen.css";

const SplashScreen = (props: { text?: string; show?: boolean; error?: boolean }) => {
  return props.show ?? true ? (
    <div className="splash-screen-wrapper">
      <div className="container splash-screen">
        <img src={process.env.PUBLIC_URL + "/images/SGI-CANADA-black-logo-300dpi.png"} alt="" />
        <h2 className={classNames("loading-text", { error: props.error || false })}>
          {props.text || "Loading"}
        </h2>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SplashScreen;
