import { Button, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  PolicyHistoryModel,
  PolicyHistoryTransferReasonTypeCode,
} from "../../../../service/RequoteApiTypes";
import {
  addPolicyHistory,
  selectNonAutoPolicyHistory,
  selectPolicyHistory,
  selectPolicyHistoryWithRenewal,
} from "./policyHistorySlice";
import ListItemView from "./ListItemView";
import React from "react";
import { PlusSquareFill } from "react-bootstrap-icons";
import "./ListView.css";
import { selectIsApplicant } from "../entity/entitySlice";
import { selectQuoteRenewalEnabled, selectMultiLineEnabled } from "../../../../appRootSlice";
import MultiLineEditor from "./MultiLineEditor";

interface Props {
  entityId: string;
}

const ListView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  const isApplicant = useSelector(selectIsApplicant);

  const policyHistoriesNonAuto: PolicyHistoryModel[] = useSelector(
    selectNonAutoPolicyHistory
  ).filter((e) => e.entityId === props.entityId);

  const policyHistories: PolicyHistoryModel[] = useSelector(selectPolicyHistory).filter(
    (e) => e.entityId === props.entityId
  );

  const policyHistoriesWithRenewals: PolicyHistoryModel[] = useSelector(
    selectPolicyHistoryWithRenewal
  ).filter((e) => e.entityId === props.entityId);

  const isQuoteRenewalFeatureFlagEnabled = useSelector(selectQuoteRenewalEnabled);
  const isMultiLineFeatureFlagEnabled = useSelector(selectMultiLineEnabled);

  const isQuoteRenewalRendered = isApplicant && isQuoteRenewalFeatureFlagEnabled;
  const isMultiLineRendered = isApplicant && isMultiLineFeatureFlagEnabled;

  const onAddNew = () => {
    const newHistory = {
      entityId: props.entityId,
      policyStatus: "prior",
      lineOfBusiness: "auto",
      transferReason: policyHistories.length ? ("7" as PolicyHistoryTransferReasonTypeCode) : null,
    } as PolicyHistoryModel;

    dispatch(addPolicyHistory(newHistory));
  };

  const activePolicyHistories = isQuoteRenewalFeatureFlagEnabled
    ? policyHistories
    : policyHistoriesWithRenewals;

  return (
    <div className="histories list-view">
      <Button variant="primary" size="sm" className="add-new-item" onClick={onAddNew}>
        <PlusSquareFill className="mr-2"></PlusSquareFill> Add New
      </Button>
      {policyHistories.length > 0 ? (
        <>
          <Table className="sgi-insurance-info-tbl" striped>
            <thead>
              <tr>
                <th className="sgi-column-first-insured"></th>
                <th>Auto Carrier</th>
                <th className="sgi-column-start-date">Start Date</th>
                {isQuoteRenewalRendered && <th>Renewal Rating</th>}
                <th className="actions"></th>
              </tr>
            </thead>
            <tbody>
              {activePolicyHistories.map((e, index) => (
                <ListItemView
                  key={e.policyHistoryId}
                  data={e}
                  index={index}
                  count={policyHistories.length}
                  isQuoteRenewalRendered={isQuoteRenewalRendered}
                />
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <div className="no-data">No insurance information</div>
      )}
      {isMultiLineRendered && (
        <MultiLineEditor
          entityId={props.entityId}
          policyHistoryNonAuto={policyHistoriesNonAuto}></MultiLineEditor>
      )}
    </div>
  );
};

export default ListView;
