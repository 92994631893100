import { ClaimModel } from "./RequoteApiTypes";
import axios, { AxiosResponse } from "axios";
import CONSTANTS from "../constants";

export async function saveClaim(
  quoteRequestWipId: string,
  request: ClaimModel
): Promise<ClaimModel> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.CLAIM.SAVE(quoteRequestWipId);

  const response = await axios.post<ClaimModel, AxiosResponse<ClaimModel>>(url, request);

  return response.data;
}

export async function deleteClaim(quoteRequestWipId: string, id: string): Promise<void> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.CLAIM.DELETE(quoteRequestWipId, id);

  const response = await axios.delete(url);

  return response.data;
}
