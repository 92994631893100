import {
  FileEarmarkText,
  FileEarmarkPerson,
  PersonFill,
  ArrowRightCircle,
  ArrowLeftCircle,
  ConeStriped,
  Speedometer
} from "react-bootstrap-icons";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isNavigationCollapsed, expand, collapse, selectNavItems } from "../navigationSlice";
import "./SideBar.scss";
import classNames from "classnames";
import React from "react";
import { selectClaimsEnabled, selectVehiclesEnabled } from "../../../appRootSlice";

const SideBar: React.FC = () => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  let location = useLocation();

  const collapsed = useSelector(isNavigationCollapsed);
  const navItems = useSelector(selectNavItems);
  const isClaimsEnabled = useSelector(selectClaimsEnabled);
  const isVehiclesEnabled = useSelector(selectVehiclesEnabled);
  

  return (
    <>
      <div
        className={classNames("requote-sidebar-toggle-wrapper position-fixed", {
          collapsed: collapsed,
        })}>
        {collapsed ? (
          <ArrowRightCircle
            size={25}
            onClick={() => dispatch(expand())}
            className="requote-sidebar-toggle"
          />
        ) : (
          <ArrowLeftCircle
            size={25}
            onClick={() => dispatch(collapse())}
            className="requote-sidebar-toggle"
          />
        )}
      </div>
      <ProSidebar collapsed={collapsed} className="requote-sidebar position-fixed">
        <Menu>
          <MenuItem
            icon={<FileEarmarkText />}
            active={location.pathname === `${url}/quote-details`}>
            <NavLink to={`${url}/quote-details`}>Quote Details</NavLink>
          </MenuItem>
          {navItems
            .filter((item) => item.subItems.length > 0)
            .map((item, index) => (
              <SubMenu
                key={index}
                title={item.title}
                className={
                  item.subItems.findIndex(
                    (subItem) =>
                      location.pathname ===
                      `${url}/${item.title === "Applicants" ? "applicant" : "driver"}/${
                        subItem.url
                      }`
                  ) > -1
                    ? "active-child"
                    : ""
                }
                defaultOpen={true}
                icon={item.title === "Applicants" ? <FileEarmarkPerson /> : <PersonFill />}>
                {item.subItems.map((subItem, subitemIndex) => (
                  <MenuItem
                    key={`${index}-${subitemIndex}`}
                    className={classNames("border-bottom")}
                    active={
                      location.pathname ===
                      `${url}/${item.title === "Applicants" ? "applicant" : "driver"}/${
                        subItem.url
                      }`
                    }>
                    <NavLink
                      to={`${url}/${item.title === "Applicants" ? "applicant" : "driver"}/${
                        subItem.url
                      }`}>
                      {subItem.title}
                    </NavLink>
                  </MenuItem>
                ))}
              </SubMenu>
            ))}
          {isClaimsEnabled && (
            <MenuItem icon={<ConeStriped />} active={location.pathname === `${url}/claims`}>
              <NavLink to={`${url}/claims`}>Claims</NavLink>
            </MenuItem>
          )}
          {isVehiclesEnabled && (
            <MenuItem icon={<Speedometer />} active={location.pathname === `${url}/vehicles`}>
              <NavLink to={`${url}/vehicles`}>Vehicles</NavLink>
            </MenuItem>
          )}
        </Menu>
      </ProSidebar>
    </>
  );
};

export default SideBar;
