import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HTTP, HttpAction } from "./../../../../app/middlewares/http/types";
import { AppThunk, RootState } from "./../../../../app/store";
import { SuspensionInfoModel, QuoteRequestWipModel } from "service/RequoteApiTypes";
import { resumeWorkInProgress } from "../../../wip-list/wipListSlice";
import { v4 as uuidv4 } from "uuid";
import * as suspensionService from "./../../../../service/suspensionService";

interface SuspensionState {
  suspensions: SuspensionInfoModel[];
  selectedSuspension?: SuspensionInfoModel;
}

const initialState: SuspensionState = {
  suspensions: [],
};

export const suspensionSlice = createSlice({
  name: "suspensionSlice",
  initialState: initialState,
  reducers: {
    addAction: (state, action: PayloadAction<SuspensionInfoModel>) => {
      state.selectedSuspension = action.payload;
    },
    editAction: (state, action: PayloadAction<SuspensionInfoModel>) => {
      state.selectedSuspension = action.payload;
    },
    cancelEditAction: (state) => {
      state.selectedSuspension = undefined;
    },
    saveAction: (state, action: PayloadAction<SuspensionInfoModel>) => {
      const payload = action.payload;

      const suspensionIdIndex = state.suspensions.findIndex(
        (l) => l.suspensionId === payload.suspensionId
      );

      if (suspensionIdIndex > -1) {
        state.suspensions[suspensionIdIndex] = payload;
      } else {
        state.suspensions.push(payload);
      }

      state.selectedSuspension = undefined;
    },
    deleteAction: (state, action: PayloadAction<SuspensionInfoModel>) => {
      state.suspensions.splice(
        state.suspensions.findIndex((l) => l.suspensionId === action.payload.suspensionId),
        1
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      resumeWorkInProgress,
      (state: SuspensionState, action: PayloadAction<QuoteRequestWipModel | null>) => {
        state.suspensions = action.payload?.suspensions || [];
      }
    );
  },
});

export const addSuspension =
  (model: SuspensionInfoModel): AppThunk<void> =>
    async (dispatch) => {
      dispatch(addAction(model));
    };

export const editSuspension =
  (model: SuspensionInfoModel): AppThunk<void> =>
    async (dispatch) => {
      dispatch(editAction(model));
    };

export const cancelEditing = (): AppThunk<void> => async (dispatch) => {
  dispatch(cancelEditAction());
};

export const saveSuspension =
  (quoteRequestWipId: string, model: SuspensionInfoModel): AppThunk<void> =>
    async (dispatch) => {
      model.suspensionId = (model.suspensionId || "").length === 0 ? uuidv4() : model.suspensionId;

      const action: HttpAction<SuspensionInfoModel> = {
        type: HTTP,
        meta: {
          promise: () => suspensionService.saveSuspension(quoteRequestWipId, model),
          resolve: (response: SuspensionInfoModel) => {
            dispatch(saveAction(response));
          },
        },
      };

      return dispatch(action);
    };

export const deleteSuspension =
  (quoteRequestWipId: string, model: SuspensionInfoModel): AppThunk<void> =>
    async (dispatch) => {
      const action: HttpAction<void> = {
        type: HTTP,
        meta: {
          promise: () =>
            suspensionService.deleteSuspension(quoteRequestWipId, model.entityId, model.suspensionId),
          resolve: () => {
            dispatch(deleteAction(model));
          },
        },
      };

      return dispatch(action);
    };

export const selectSuspensions = (rootState: RootState) =>
  rootState.suspension.suspensions
    .slice()
    .sort(
      (first, second) =>
        new Date(second.startDate ?? "").valueOf() - new Date(first.startDate ?? "").valueOf()
    );

export const selectSelectedSuspension = (rootState: RootState) =>
  rootState.suspension.selectedSuspension;

const { addAction, editAction, cancelEditAction, saveAction, deleteAction } =
  suspensionSlice.actions;

export default suspensionSlice.reducer;
