import React from "react";
import moment from "moment";
import { convertUtcDateToDisplayString } from "../utils/helpers/dateUtils";

interface Props {
  value?: string | moment.Moment | null;
}

const DateDisplay: React.FC<Props> = (props: Props) => {
  const stringValue = convertUtcDateToDisplayString(props.value);

  return <>{stringValue}</>;
};

export default DateDisplay;
