import { ReferenceData, BrokerMetadata, LicenseClassMetadata } from "./RequoteApiTypes";
import axios from "axios";
import CONSTANTS from "../constants";

export async function getReferenceDataCategory(categoryName: string): Promise<ReferenceData[]> {
  const url = `${window.REACT_APP_API_BASEURL + CONSTANTS.API.REFERENCE_DATA.GET}?categoryName=${encodeURIComponent(
    categoryName
  )}`;

  var response = await axios.get<ReferenceData[]>(url);

  return response.data.filter((d) => d.isActive === true);
}

export async function getBrokers(): Promise<BrokerMetadata[]> {

  const response = await axios.get<BrokerMetadata[]>(window.REACT_APP_API_BASEURL + CONSTANTS.API.REFERENCE_DATA.GET_BROKERS);

  return response.data;
}

export async function getLicenseClasses(): Promise<LicenseClassMetadata[]> {

  const response = await axios.get<LicenseClassMetadata[]>(window.REACT_APP_API_BASEURL + CONSTANTS.API.REFERENCE_DATA.GET_LICENSE_CLASSES);

  return response.data;
}
