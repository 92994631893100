import { useEffect } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { loadRequoteHistoryListAsync, loadWipListAsync, clearSearchResults } from "./wipListSlice";
import QuoteSearch from "./components/QuoteSearch";
import SearchResults from "./components/SearchResults";
import WipListTable from "./components/WipListTable";
import RecentlySubmittedWipTable from "./components/RecentlySubmittedWipTable";
import "./WipList.css";
import { useHistory, useParams } from "react-router-dom";

interface Prop {
  data?: string
}
export const WipList = (prop: Prop) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { view } = useParams<{ view: string }>();

  useEffect(() => {
    dispatch(clearSearchResults());
    dispatch(loadWipListAsync());
    dispatch(loadRequoteHistoryListAsync());
  }, []);

  const handleSelection = (eventKey: string) => {
    history.push(`/wip/${eventKey}`);
  };

  return (
    <Container className="wip-list-wrapper">
      <QuoteSearch data={prop.data ?? ""} />
      <SearchResults />

      <Tabs
        activeKey={view || "active"}
        onSelect={(eventKey) => handleSelection(eventKey || "active")}>
        <Tab eventKey="active" title="Work in Progress" className="shadow">
          <WipListTable />
        </Tab>
        <Tab eventKey="recent" title="Recent Requotes" className="shadow">
          <RecentlySubmittedWipTable />
        </Tab>
      </Tabs>
    </Container>
  );
};

export default WipList;
