import { Table } from "react-bootstrap";
import { VehicleModel } from "service/RequoteApiTypes";
import ListItemView from "./ListItemView";
import CONSTANTS from "./../../../../constants";
import "./ListView.scss";
import VehicleCoverageEditor from "./VehicleCoverageEditor";

interface Props {
    data: VehicleModel;
  }

const ListView: React.FC<Props> = (props) => {
    return (
      <div className="vehicles list-view">
        {(props && props.data.coverage && props.data.coverage?.filter(p => CONSTANTS.VEHICLE_REQUIRED_COVERAGES.includes(p.typeCode ?? "")).length > 0) ? (
          <>
            <VehicleCoverageEditor></VehicleCoverageEditor>
            <Table striped className="veh-cov-table">
              <thead>
                <tr>
                  <th>Coverage</th>
                  <th>Deductible</th>
                  <th className="actions"></th>
                </tr>
              </thead>
              <tbody>
                {props.data.coverage?.filter((e) => CONSTANTS.VEHICLE_REQUIRED_COVERAGES.includes(e.typeCode?.toString() ?? "")).map(c => (
                  <ListItemView key={c.coverageId} data={c} assetId={props.data.assetId ?? ""} />
                ))}
              </tbody>
            </Table>
          </>
        ) : (
          <div className="no-data">No Coverage information</div>
        )}
      </div>
    );
  };
  
export default ListView;
  