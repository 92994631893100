import { BrokerMetadata } from "../../../service/RequoteApiTypes";

export class InsuranceScoreViewModel {
  policyNumber: string;
  firstName: string;
  lastName: string;
  birthDate: string;
  postalCode: string;
  brokerNumber: string;
  officeNumber: string;
  isCreditScoreHit?: boolean;
  creditScoreMessage: string;
  uniqueId?: string;
  broker?: BrokerMetadata;

  constructor() {
    this.policyNumber = "";
    this.firstName = "";
    this.lastName = "";
    this.birthDate = "";
    this.postalCode = "";
    this.brokerNumber = "";
    this.officeNumber = "";
    this.isCreditScoreHit = false;
    this.creditScoreMessage = "";
    this.uniqueId = "";
    this.broker = undefined;
  }
}
