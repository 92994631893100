import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { PencilSquare } from "react-bootstrap-icons";
import { AssetCoverage, AssetCovergeModel } from "../../../../service/RequoteApiTypes";
import { useDispatch, useSelector } from "react-redux";
import { editCoverage } from "./vehicleSlice";
import CodeValue from "./../../../../components/CodeValue";
import CONSTANTS from "./../../../../constants";
import { vehicleDeductibleEditorFeatureFlagEnabled } from "../../../../appRootSlice";
import "./ListView.scss";
interface Props {
    data: AssetCoverage;
    assetId?: string,
}

const currencyValue=(x: any)=> {
    return x ? "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : (x ?? "");
}

const ListItemView: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const isEditDeductibleEnabled = useSelector(vehicleDeductibleEditorFeatureFlagEnabled);
    const onEdit = (data: AssetCoverage, assetId?: string) => {
        let _acModel = {} as AssetCovergeModel;
        _acModel = Object.assign({}, data);
        _acModel.assetId = assetId;
        dispatch(editCoverage(_acModel));
      };
    
    return (
        <tr key={props.data.typeCode?.toString()}>
            <td className="veh-cov-mw-50">
                <CodeValue
                categoryName={CONSTANTS.REFDATA.COVERAGE_TYPE_CODE}
                value={props.data.typeCode?.toString()}
                />
            </td>
            <td>
                {props.data.deductible?.map(d => (<span key={d.typeCode}>{currencyValue(d.value)}</span>))}
            </td>
            <td>
                {isEditDeductibleEnabled &&
                    <OverlayTrigger placement="top" overlay={<Tooltip id="EditCoverage">Edit Coverage</Tooltip>}>
                    <Button
                        variant="secondary"
                        data-testid="EditCoverage"
                        size="sm"
                        title="Edit"
                        onClick={() => onEdit(props.data, props.assetId)}>
                        <PencilSquare></PencilSquare>
                    </Button>
                    </OverlayTrigger>
                }
            </td>
        </tr>
    )
  }

export default ListItemView;