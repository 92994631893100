import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HTTP, HttpAction } from "../../../../app/middlewares/http/types";
import { AppThunk, RootState } from "../../../../app/store";
import { GridStepOverrideModel } from "service/RequoteApiTypes";
import { resumeWorkInProgress } from "../../../wip-list/wipListSlice";
import * as gridStepOverrideService from "../../../../service/gridStepOverrideService";
import { setSelectedEntity } from "../entity/entitySlice";

interface GridStepOverrideState {
  gridStepOverrides: GridStepOverrideModel[];
  selectedGridStepOverride?: GridStepOverrideModel;
}

const initialState: GridStepOverrideState = {
  gridStepOverrides: [],
  selectedGridStepOverride: undefined,
};

export const gridStepOverrideSlice = createSlice({
  name: "gridStepOverrideSlice",
  initialState: initialState,
  reducers: {
    saveGridStepOverrideAction: (state, action: PayloadAction<GridStepOverrideModel>) => {
      const payload = action.payload;

      const index = state.gridStepOverrides.findIndex((l) => l.entityId === payload.entityId);

      if (index > -1) {
        state.gridStepOverrides[index] = payload;
      }
    },

    setSelectedGridStepOverride: (
      state,
      action: PayloadAction<Partial<GridStepOverrideModel> | undefined>
    ) => {
      state.selectedGridStepOverride = {
        ...(state.selectedGridStepOverride ?? { entityId: "", gridId: "" }),
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resumeWorkInProgress, (state, action) => {
        state.gridStepOverrides = action.payload?.gridStepOverrides || [];
        state.selectedGridStepOverride = undefined;
      })
      .addCase(setSelectedEntity, (state, action) => {
        state.selectedGridStepOverride = state.gridStepOverrides.find(
          (o) => o.entityId === action.payload.entityId
        );
      });
  },
});

export const saveGridStepOverride =
  (quoteRequestWipId: string, model: GridStepOverrideModel): AppThunk<void> =>
  async (dispatch) => {
    const action: HttpAction<GridStepOverrideModel> = {
      type: HTTP,
      meta: {
        promise: () => gridStepOverrideService.saveGridStepOverride(quoteRequestWipId, model),
        resolve: (response: GridStepOverrideModel) => {
          dispatch(saveGridStepOverrideAction(response));
          dispatch(setSelectedGridStepOverride(response));
        },
      },
    };

    return dispatch(action);
  };

export const selectSelectedGridStepOverride = (rootState: RootState) =>
  rootState.gridStepOverride.selectedGridStepOverride;

export const { saveGridStepOverrideAction, setSelectedGridStepOverride } =
  gridStepOverrideSlice.actions;

export default gridStepOverrideSlice.reducer;
