import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import appRootReducer from "../appRootSlice";
import navigationReducer from "../features/quote-request-2/navigationSlice";
import insuranceScoreReducer from "../features/insurance-score/insuranceScoreSlice";
import notificationReducer from "./notification/notificationSlice";
import wipListReducer from "../features/wip-list/wipListSlice";
import insuranceEventReducer from "../features/quote-request-2/features/insuranceEvent/insuranceEventSlice";
import policyHistoryReducer from "../features/quote-request-2/features/policyHistory/policyHistorySlice";
import quoteRequestEntityReducer from "../features/quote-request-2/features/entity/entitySlice";
import quoteRequestSliceReducer from "../features/quote-request-2/quoteRequestSlice";
import convictionReducer from "../features/quote-request-2/features/conviction/convictionSlice";
import personalInformationReducer from "../features/quote-request-2/features/personalInformation/personalInformationSlice";
import licenseReducer from "../features/quote-request-2/features/license/licenseSlice";
import suspensionReducer from "../features/quote-request-2/features/suspension/suspensionSlice";
import gridStepOverrideReducer from "../features/quote-request-2/features/grid-override/gridStepOverrideSlice";
import claimReducer from "../features/quote-request-2/features/claim/claimSlice";
import vehicleReducer from "../features/quote-request-2/features/vehicle/vehicleSlice";
import rootValidationReducer from "../rootValidationSlice";

import { http } from "./middlewares/http";
import { HTTP } from "./middlewares/http/types";

export const createStore = (initialState?: any) => {
  return configureStore({
    reducer: {
      appRoot: appRootReducer,
      rootValidation: rootValidationReducer,
      navigation: navigationReducer,
      notification: notificationReducer,
      insuranceScore: insuranceScoreReducer,
      wipList: wipListReducer,
      insuranceEvent: insuranceEventReducer,
      policyHistory: policyHistoryReducer,
      quoteRequestEntity: quoteRequestEntityReducer,
      quoteRequest: quoteRequestSliceReducer,
      conviction: convictionReducer,
      personalInformation: personalInformationReducer,
      license: licenseReducer,
      suspension: suspensionReducer,
      gridStepOverride: gridStepOverrideReducer,
      claim: claimReducer,
      vehicle: vehicleReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [HTTP],
        },
      }).concat(http),
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== "production",
  });
};
export const store = createStore();
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
