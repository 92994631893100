import { useSelector } from "react-redux";
import { Badge, Row, Col, OverlayTrigger, Button, Tooltip, Jumbotron } from "react-bootstrap";
import { setSelectedEntity, selectEntity } from "./entitySlice";
import ViewDriver from "./components/ViewDriver";
import ConvictionEditor from "../conviction/Editor";
import PolicyHistoryEditor from "../policyHistory/Editor";
import InsuranceEventEditor from "../insuranceEvent/Editor";
import PersonalInformationEditor from "../personalInformation/Editor";
import LicenseInformationEditor from "../license/Editor";
import SuspensionInformationEditor from "../suspension/Editor";
import { useParams } from "react-router";
import CONSTANTS from "../../../../constants";
import { selectOccupationTypeCodes } from "../../../../appRootSlice";
import { PersonalInformationViewModel } from "service/RequoteApiTypes";
import {
  editPersonalInformation,
  selectPersonalInformation,
} from "../personalInformation/personalInformationSlice";
import DisplayDate from "../../../../components/DateDisplay";
import { useDispatch } from "react-redux";
import { Pencil } from "react-bootstrap-icons";
import { useEffect } from "react";
import classNames from "classnames";

type RouteParams = {
  quoteRequestWipId: string;
  entityId?: string;
};

const ViewEntity = () => {
  const dispatch = useDispatch();
  const params = useParams<RouteParams>();

  useEffect(() => {
    dispatch(setSelectedEntity({ entityId: params.entityId }));
  }, [dispatch, params.entityId]);

  const occupationTypeCodes = useSelector(selectOccupationTypeCodes);
  const selectedEntity = useSelector(selectEntity);
  const personalinformation: PersonalInformationViewModel = useSelector(
    selectPersonalInformation
  ).filter((e) => e.entityId === params.entityId)[0];

  const onEdit = (data: PersonalInformationViewModel) => {
    dispatch(editPersonalInformation(data));
  };

  const getAge = (birthdate: string | null | undefined) => {
    if (!birthdate) return "";
    const timeDiff = Math.abs(Date.now() - new Date(birthdate).getTime());
    const age = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);

    return age;
  };

  var occupations = occupationTypeCodes.filter(
    (f) => f.codeValue === personalinformation?.occupation?.toString()
  );

  var occupationDescription = occupations.length > 0 ? occupations[0].description : null;

  if (!selectedEntity) {
    return (
      <Jumbotron className="bg-white p-2">
        <h1>Entity Not Found: {params.entityId}</h1>
        <p>The quote request does not have a matching Applicant or Driver.</p>
      </Jumbotron>
    );
  }

  return (
    <div className="entities-list">
      <div key={`${selectedEntity.entityId}-wrapper`}>
        <div className="mb-4 bg-light p-3 border-bottom entity-header position-relative">
          <Row>
            <Col xs="auto">
              <span className="font-weight-normal mr-3 entity-title">
                {selectedEntity.displayName}
              </span>
            </Col>
            <Col xs="auto">
              <Row>
                <Col xs="auto">
                  <span className="sgi-personal-information">
                    DOB: <DisplayDate value={personalinformation?.birthdate} /> (
                    {getAge(personalinformation?.birthdate)} years old)
                    <br />
                    MARITAL STATUS:
                    {personalinformation?.maritalStatus
                      ? ` ${personalinformation?.maritalStatus}`
                      : ""}
                    <br />
                    GENDER:{` ${personalinformation?.gender ?? "unknown"} `}
                    <br />
                    OCCUPATION:
                    {occupationDescription ? ` ${occupationDescription} ` : ""}
                  </span>
                </Col>
                <Col xs="auto">
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="EditPersonalInfo">Edit Personal Informaton</Tooltip>}>
                    <Button
                      variant="light"
                      size="sm"
                      onClick={() => onEdit(personalinformation)}
                      className="btn-personal-info">
                      <Pencil></Pencil>
                    </Button>
                  </OverlayTrigger>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="roles position-absolute">
            {(selectedEntity.roles || [])
              .filter((role) =>
                [CONSTANTS.ENTITY_ROLES.DRIVER, CONSTANTS.ENTITY_ROLES.APPLICANT].includes(
                  role.toLowerCase()
                )
              )
              .sort()
              .map((role, roleIndex) => (
                <Badge
                  key={`${selectedEntity.entityId}-role-${roleIndex}`}
                  variant={role === CONSTANTS.ENTITY_ROLES.APPLICANT ? "light" : "secondary"}
                  className={classNames("font-weight-normal text-uppercase p-1 mr-1", {
                    "badge-applicant": role === CONSTANTS.ENTITY_ROLES.APPLICANT,
                  })}>
                  {role.toLowerCase()}
                </Badge>
              ))}
          </div>
        </div>
        <ViewDriver key={selectedEntity.entityId} entity={selectedEntity} />
      </div>

      <ConvictionEditor />
      <InsuranceEventEditor />
      <PolicyHistoryEditor />
      <PersonalInformationEditor />
      <LicenseInformationEditor />
      <SuspensionInformationEditor />
    </div>
  );
};

export default ViewEntity;
