import React from "react";
import { Jumbotron, Container } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";

interface Props {
  role: string;
  UnauthorizedTemplate?: React.ReactNode;
}

interface IdTokenClaims {
  roles?: string[] | null;
}

const RoleGuard: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const { instance } = useMsal();

  const idTokenClaims: IdTokenClaims =
    (instance.getActiveAccount() ?? instance.getAllAccounts()[0])?.idTokenClaims || {};

  const roles = idTokenClaims.roles;

  if (!roles) {
    return (
      <>
        {props.UnauthorizedTemplate ?? (
          <Jumbotron className="bg-white">
            <Container>
              <h1>Access Denied</h1>
              <p>
                Token does not have roles claim. Please ensure that your account is assigned to an
                app role and then sign-out and sign-in again.
              </p>
            </Container>
          </Jumbotron>
        )}
      </>
    );
  } else if (!roles.includes(props.role)) {
    return (
      <>
        {props.UnauthorizedTemplate ?? (
          <Jumbotron className="bg-white">
            <Container>
              <h1>Access Denied</h1>
              <p>
                You do not have access as expected role is missing. Please ensure that your account
                is assigned to an app role and then sign-out and sign-in again.
              </p>
            </Container>
          </Jumbotron>
        )}
      </>
    );
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default RoleGuard;
