import React from "react";
import { useSelector } from "react-redux";
import { selectLicenseProvinces } from "./../licenseSlice";

interface Props {
  value?: string | null;
}

const LicenseProvince: React.FC<Props> = (props: Props) => {
  const provinces = useSelector(selectLicenseProvinces);

  const province = provinces.find((p) => p.provinceCode === props.value);

  return <>{province ? province.provinceName : "-"}</>;
};

export default LicenseProvince;
