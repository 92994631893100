import axios from "axios";
import CONSTANTS from "../constants";

export async function getActiveFeatureFlagsAsync(): Promise<string[]> {
  var response = await axios.get<string[]>(
    window.REACT_APP_API_BASEURL + CONSTANTS.API.CONFIGURATION.GET_ACTIVE_FEATURE_FLAGS
  );

  return response.data;
}
