import { PersonalInformationViewModel } from "./RequoteApiTypes";
import axios, { AxiosResponse } from "axios";
import CONSTANTS from "../constants";

export async function savePersonalInformation(
  quoteRequestWipId: string,
  request: PersonalInformationViewModel
): Promise<PersonalInformationViewModel> {
  const url = window.REACT_APP_API_BASEURL + CONSTANTS.API.PERSONAL_INFORMATION.SAVE(quoteRequestWipId, request.entityId);

  const response = await axios.post<
    PersonalInformationViewModel,
    AxiosResponse<PersonalInformationViewModel>
  >(url, request);

  return response.data;
}
