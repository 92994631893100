import { RequoteHistoryModel } from "./RequoteApiTypes";
import axios from "axios";
import CONSTANTS from "../constants";

export async function getRequoteHistoryList(): Promise<RequoteHistoryModel[]> {
  const url = `${window.REACT_APP_API_BASEURL + CONSTANTS.API.REQUOTE_HISTORY_LIST.GET}`;

  const response = await axios.get<RequoteHistoryModel[]>(url);

  return response.data;
}
