import { RequestHeaderModel } from "./RequoteApiTypes";
import axios, { AxiosResponse } from "axios";
import CONSTANTS from "../constants";

export async function saveRequestDetails(
  quoteRequestWipId: string,
  request: RequestHeaderModel
): Promise<RequestHeaderModel> {
  const url =
    window.REACT_APP_API_BASEURL + CONSTANTS.API.QUOTE_REQUEST_DETAIL.SAVE(quoteRequestWipId);

  const response = await axios.post<RequestHeaderModel, AxiosResponse<RequestHeaderModel>>(
    url,
    request
  );

  return response.data;
}
