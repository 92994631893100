import { Button, OverlayTrigger, Tooltip, Badge } from "react-bootstrap";
import { TrashFill, PencilSquare } from "react-bootstrap-icons";
import { SuspensionInfoModel } from "../../../../service/RequoteApiTypes";
import DisplayDate from "./../../../../components/DateDisplay";
import CONSTANTS from "./../../../../constants";
import CodeValue from "./../../../../components/CodeValue";
import { useDispatch, useSelector } from "react-redux";
import { selectQuoteRequest } from "../../quoteRequestSlice";
import { editSuspension, deleteSuspension } from "./suspensionSlice";
import helpers from "./../../../../utils/helpers";

interface Props {
  data: SuspensionInfoModel;
}

const ListItemView: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const selectedQuoteRequest = useSelector(selectQuoteRequest);

  const onDelete = (data: SuspensionInfoModel) => {
    helpers.confirm({
      header: "Confirm Delete",
      body: "Are you sure you want to delete it?",
      onConfirm: () => {
        dispatch(deleteSuspension(selectedQuoteRequest.quoteRequestWipId as string, data));
      },
    });
  };

  return (
    <tr>
      <td className="date-column-lg">
        <DisplayDate value={props.data.startDate} />
      </td>
      <td className="date-column-lg">
        <DisplayDate value={props.data.dateReinstated} />
      </td>
      <td className="description-column">
        <CodeValue
          categoryName={CONSTANTS.REFDATA.SUSPENSION_TYPE_CODE}
          value={props.data.suspensionTypeCode}
        />
      </td>
      <td>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="EditSuspension">Edit Suspension</Tooltip>}>
          <Button
            variant="secondary"
            data-testid="EditSuspension"
            size="sm"
            title="Edit"
            onClick={() => dispatch(editSuspension(props.data))}>
            <PencilSquare></PencilSquare>
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="DeleteSuspension">Delete Suspension</Tooltip>}>
          <Button
            variant="danger"
            data-testid="DeleteSuspension"
            size="sm"
            className="ml-2"
            title="Delete"
            onClick={() => onDelete(props.data)}>
            <TrashFill></TrashFill>
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

export default ListItemView;
