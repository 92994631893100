import { Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { selectRequoteHistoryList } from "./../wipListSlice";
import RecentlySubmittedWipTableRow from "./RecentlySubmittedWipTableRow";
// import { useHistory, useParams } from "react-router-dom";
// import { useState } from "react";

const RecentlySubmittedWipTable = () => {
  const requoteHistoryList = useSelector(selectRequoteHistoryList);
  // const history = useHistory();
  // const params = useParams<{ page: string }>();

  // const [currentPage, setCurrentPage] = useState(
  //   Number.isNaN(params.page) ? 1 : parseInt(params.page)
  // );
  // const defaultPageSize = 10;
  // const totalPages = Math.ceil(requoteHistoryList.length / defaultPageSize);

  // const pagedHistoryList = requoteHistoryList.slice(
  //   (currentPage - 1) * defaultPageSize,
  //   currentPage * defaultPageSize
  // );

  // const onPageChange = (value: number) => {
  //   setCurrentPage(value);
  //   history.push(`/wip/recent-quotes/${value}`);
  // };

  return (
    <div className="pt-2 border border-top-0">
      {requoteHistoryList.length > 0 ? (
        <Table striped hover className="mb-0">
          <thead>
            <tr>
              <th className="border-top-0"></th>
              <th className="border-top-0">Requested Date</th>
              <th className="border-top-0">Requote Quote Ref ID</th>
              <th className="border-top-0">Prior Quote Ref ID</th>
              <th className="border-top-0"></th>
            </tr>
          </thead>
          <tbody>
            {requoteHistoryList.map((item) => (
              <RecentlySubmittedWipTableRow key={item.quote?.requestId} data={item} />
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="lead pl-3">No recent requotes</p>
      )}

      {/* <Pagination>
        {Array.from(Array(totalPages).keys()).map((number) => (
          <Pagination.Item
            key={number + 1}
            active={currentPage == number + 1}
            onClick={() => onPageChange(number + 1)}>
            {number + 1}
          </Pagination.Item>
        ))}
      </Pagination> */}
    </div>
  );
};

export default RecentlySubmittedWipTable;
