import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "./../../../../app/store";
import { ConvictionViewModel } from "../../../../service/RequoteApiTypes";
import * as convictionService from "./../../../../service/convictionService";
import { resumeWorkInProgress } from "../../../wip-list/wipListSlice";
import { v4 as uuidv4 } from "uuid";
import { HTTP, HttpAction } from "./../../../../app/middlewares/http/types";

interface ConvictionState {
  error: string | null;
  convictions: ConvictionViewModel[];
  selectedConviction?: ConvictionViewModel;
}

const initialState: ConvictionState = {
  error: null,
  convictions: [],
};

export const convictionSlice = createSlice({
  name: "conviction",
  initialState: initialState,
  reducers: {
    addAction: (state, action: PayloadAction<ConvictionViewModel>) => {
      state.selectedConviction = action.payload;
    },
    editAction: (state, action: PayloadAction<ConvictionViewModel>) => {
      state.selectedConviction = action.payload;
    },
    cancelEditAction: (state) => {
      state.selectedConviction = undefined;
    },
    saveAction: (state, action: PayloadAction<ConvictionViewModel>) => {
      const payload = action.payload;

      const convictionIndex = state.convictions.findIndex(
        (e) => e.convictionId === payload.convictionId
      );
      if (convictionIndex > -1) {
        state.convictions[convictionIndex] = payload;
      } else {
        state.convictions.push(payload);
      }

      state.selectedConviction = undefined;
    },
    deleteAction: (state, action: PayloadAction<ConvictionViewModel>) => {
      state.convictions.splice(
        state.convictions.findIndex(
          (e) => e.convictionId === action.payload.convictionId
        ),
        1
      );
    },
    // addSupplementAction: (state, action: PayloadAction<SupplementViewModel>) => {
    //   if (!state.selectedConviction) {
    //     return;
    //   }

    //   if (!state.selectedConviction?.supplements) {
    //     state.selectedConviction.supplements = [action.payload];
    //   } else {
    //     state.selectedConviction?.supplements?.push(action.payload);
    //   }
    // },
    // deleteSupplementAction: (state, action: PayloadAction<SupplementViewModel>) => {
    //   state.selectedConviction?.supplements?.splice(
    //     state.selectedConviction?.supplements?.findIndex(
    //       (e) => e.supplementId === action.payload.supplementId
    //     ),
    //     1
    //   );
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(resumeWorkInProgress, (state, action) => {
      state.convictions = action.payload?.convictions ?? [];
    });
  },
});

const {
  addAction,
  editAction,
  cancelEditAction,
  saveAction,
  deleteAction,
} = convictionSlice.actions;

export const addConviction = (conviction: ConvictionViewModel): AppThunk<void> => async (
  dispatch
) => {
  dispatch(addAction(conviction));
};

export const editConviction = (conviction: ConvictionViewModel): AppThunk<void> => async (
  dispatch
) => {
  dispatch(editAction(conviction));
};

export const cancelEditing = (): AppThunk<void> => async (dispatch) => {
  dispatch(cancelEditAction());
};

export const saveConviction = (
  quoteRequestWipId: string,
  conviction: ConvictionViewModel
): AppThunk<void> => async (dispatch) => {
  conviction.convictionId =
    (conviction.convictionId ?? "").length == 0 ? uuidv4() : conviction.convictionId;

  const action: HttpAction<ConvictionViewModel> = {
    type: HTTP,
    meta: {
      promise: () =>
        convictionService.saveConviction(quoteRequestWipId, conviction),
      resolve: (response: ConvictionViewModel) => {
        dispatch(saveAction(response));
      }
    },
  };

  return dispatch(action);
};

export const deleteConviction = (
  quoteRequestWipId: string,
  conviction: ConvictionViewModel
): AppThunk<void> => async (dispatch) => {

  const action: HttpAction<void> = {
    type: HTTP,
    meta: {
      promise: () =>
        convictionService.deleteConviction(
          quoteRequestWipId,
          conviction.entityId,
          conviction.convictionId
        ),
      resolve: () => {
        dispatch(deleteAction(conviction));
      }
    },
  };

  return dispatch(action);
};

export const selectConvictions = (rootState: RootState) =>
  rootState.conviction.convictions
    .slice()
    .sort((a, b) => new Date(b.convictionDate ?? "").valueOf() - new Date(a.convictionDate ?? "").valueOf());

export const selectSelectedConviction = (rootState: RootState) =>
  rootState.conviction.selectedConviction;

export default convictionSlice.reducer;
