import React, { useState, useEffect, useRef } from "react";
import { ToggleButtonGroup, ToggleButton, Form, Col } from "react-bootstrap";
import { LicenseTrainingInfoModel, TrainingTypeCode } from "service/RequoteApiTypes";
import DatePicker from "react-datepicker";
import MaskedInput from 'react-maskedinput';
import { selectQuoteRequest } from "../../../quoteRequestSlice";
import { selectSelectedTraining, setSelectedTraining, saveTraining } from "./../licenseSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  convertDateToString,
  convertUtcDateToDisplayString,
} from "./../../../../../utils/helpers/dateUtils";
import classNames from "classnames";
import { updateValidation } from "../../../../../rootValidationSlice";
import LeavePageBlocker from "../../../../../LeavePageBlocker";
const TrainingEditor: React.FC<{ entityId: string; className?: string }> = ({
  entityId,
  className,
}) => {
  const dispatch = useDispatch();
  const firstRender = useRef(true);
  const selectedQuoteRequest = useSelector(selectQuoteRequest);
  const selectedTraining = useSelector(selectSelectedTraining);
  const [pendingValidation, setPendingValidation] = useState(false);
  const invalid =
    selectedTraining?.trainingTypeCode === "yes" &&
    (selectedTraining?.date || selectedTraining?.originalDate || "")?.length === 0;

  useEffect(() => {
    if (!selectedTraining || selectedTraining.entityId !== entityId) {
      return;
    }

    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    // Update Training Info
    if (!invalid) {
      dispatch(updateValidation({formName: "DriverTrainingEditor", validationFail: false }));
      setPendingValidation(false);
      dispatch(
        saveTraining(
          selectedQuoteRequest?.quoteRequestWipId as string,
          selectedTraining as LicenseTrainingInfoModel
        )
      );
    } else {
      dispatch(updateValidation({formName: "DriverTrainingEditor", validationFail: true }));
      setPendingValidation(true);
    }
  }, [entityId, selectedTraining]);

  return (
    <>
    <LeavePageBlocker inValid={pendingValidation} formName="Driver Training Info" />
    <Form inline className={className ?? ""} autoComplete="off">
      <Form.Group>
        <Form.Label htmlFor="trainingToggle">Driver Training</Form.Label>
        <Col xs="auto">
          <ToggleButtonGroup
            type="radio"
            name="trainingToggle"
            value={selectedTraining?.trainingTypeCode === "yes" ? 1 : 0}
            onChange={(value) => {
              var changes = {
                trainingTypeCode: (value == 1 ? "yes" : "no") as TrainingTypeCode,
                date: value == 1 ? convertDateToString(selectedTraining?.originalDate) : null,
              };

              dispatch(setSelectedTraining(changes));
            }}>
            <ToggleButton value={1}>Yes</ToggleButton>
            <ToggleButton value={0}>No</ToggleButton>
          </ToggleButtonGroup>
        </Col>
      </Form.Group>
      {selectedTraining?.trainingTypeCode === "yes" ? (
        <>
          <Form.Group>
            <Form.Label htmlFor="trainingDate">Date</Form.Label>
            <Col>
              <DatePicker
                dateFormat="dd-MMM-yyyy"
                name="trainingDate"
                autoComplete="off"
                wrapperClassName={classNames({
                  "is-invalid": invalid,
                })}
                className={classNames("form-contro", {
                  "is-invalid": invalid,
                })}
                selected={
                  selectedTraining && (selectedTraining.date || selectedTraining.originalDate)
                    ? new Date(
                        convertUtcDateToDisplayString(
                          selectedTraining.date || selectedTraining.originalDate
                        )
                      )
                    : null
                }
                onSelect={(value: Date) =>
                  dispatch(
                    setSelectedTraining({
                      date: convertDateToString(value),
                      originalDate: convertDateToString(value),
                    })
                  )
                }
                onChange={() => {}}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                  dispatch(
                    setSelectedTraining({
                      date: convertDateToString(event.target.value),
                      originalDate: convertDateToString(event.target.value),
                    })
                  )
                }
                customInput={
                  <MaskedInput mask="11-Aaa-1111" placeholder="dd-MMM-yyyy" />
                }
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
              {invalid ? (
                <Form.Control.Feedback type="invalid" className="ml-2 w-auto d-inline-block">
                  Date is required
                </Form.Control.Feedback>
              ) : null}
            </Col>
          </Form.Group>
        </>
      ) : null}
    </Form>
    </>
  );
};

export default TrainingEditor;
